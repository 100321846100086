<template>
  <div class="modal-popup-action">
    <modal-component
      class="modal-first-login"
      ref="modalPopupActionFirstLogin"
      :iconClose="false"
      :max-width="1100"
      :header="false"
      :footer="false"
    >
      <template #body>
        <div class="logo-modal pt-13 pb-5">
          <img class="w-30" :src="require(`@/assets/img/visamane_logo.svg`)" />
        </div>

        <div class="text-bold text-dark-light fs-24">
          登録ありがとうございます！
        </div>

        <div class="fs-16 text-white-smoke">
          より簡単・より安心な外国籍従業員の労務管理をビザマネでぜひご体験してください
        </div>

        <div class="fs-32 text-bold text-red mt-6">ビザマネでできること</div>

        <div class="box v-row mx-3">
          <div class="v-col-4">
            <div class="box-item border-gainsboro">
              <div class="font-weight-medium fs-20 text-dark-light mt-6 mb-2">
                ① 在留カードの偽造チェック
              </div>
              <div class="text-left text-white-smoke fs-16">
                スマートフォンで在留カードに埋め込まれているICチップ情報を読み取り、その場でICチップ情報を結果として表示します。<br />
                これにより、今まで判別が難しかった高度な技術で作られた偽造在留カードでも簡単に判別することができます。
              </div>
            </div>
          </div>

          <div class="v-col-4">
            <div class="box-item border-gainsboro">
              <div class="font-weight-medium fs-20 text-dark-light mt-6 mb-2">
                ② 就労可能かどうかの判定
              </div>
              <div class="text-left text-white-smoke fs-16">
                在留資格の種別・有効期限・資格外活動許可・指定書の内容をもとに、応募者が自社で就労が可能かどうかをその場で判別し表示します。<br />
                これにより、不適切な在留資格の外国籍の方を誤って雇用するリスクを低減させることが可能になります。
              </div>
            </div>
          </div>

          <div class="v-col-4">
            <div class="box-item border-gainsboro">
              <div class="font-weight-medium fs-20 text-dark-light mt-6 mb-2">
                ③ 従業員の期限管理
              </div>
              <div class="text-left text-white-smoke fs-16">
                登録された有効期限を元に、期限切れの3ヶ月前から自動で定期的に担当者・従業員本人へアラートメールが自動送信されます。<br />
                これにより、期限管理漏れによる不法就労のリスクを防止しつつ、現場と本部との面倒な連絡作業も無くすことができます。
              </div>
            </div>
          </div>
        </div>

        <div class="fs-16 text-white-smoke pt-2">
          他にも雇用状況届出書の作成や、在留カード以外の書類(学生証)などの回収・管理も行えます
        </div>

        <div class="mt-8 pb-8 try-now-wrapper">
          <button
            class="
              btn btn-danger
              border-10
              font-weight-medium
              fs-20
              w-34
              h-56px
              try-now-button
            "
            @click="$refs.modalPopupActionFirstLogin.close()"
          >
            さっそく使ってみる
          </button>
        </div>
      </template>
    </modal-component>

    <modal-component
      ref="firstLoginUnlimitedModal"
      class="first-login-unlimited"
      :max-width="640"
      :header="false"
      :footer="false"
    >
      <template #body>
        <div class="pa-4">
          <div class="logo pt-8 pb-2">
            <Logo />
          </div>
          <p class="font-weight-bold text-white-smoke fs-24 py-3">
            従業員数制限が解除されました
          </p>
          <div class="text-left text-white-smoke">
            <p class="pb-4">
              ビザマネをご利用いただき、誠にありがとうございます。
            </p>
            <p class="pb-4">
              ご契約者様の従業員数制限が解除されましたので、従業員を30名以上登録し
              <br />ていただくことができます。ベーシックプランでは、登録従業員31名から月額
              <br />料金が課金されます。
            </p>
            <p class="pb-16">
              プランの変更をご検討の場合は、
              <span
                class="text-danger text-decoration-underline cursor-pointer"
                @click="
                  $refs.firstLoginUnlimitedModal.close(), $emit('planModal')
                "
                >こちら</span
              >
              からお問い合わせください。
            </p>
          </div>

          <p
            class="button-bg-danger mx-auto h-56px w-30 fs-20 my-3"
            @click="$refs.firstLoginUnlimitedModal.close()"
          >
            ダッシュボードへ
          </p>
        </div>
      </template>
    </modal-component>

    <modal-component
      ref="endTrialFiveDayModal"
      class="end-trial-five-day-modal"
      :max-width="640"
      :header="false"
      :footer="false"
    >
      <template #body>
        <div class="pa-4">
          <div class="logo pt-8 pb-2">
            <Logo />
          </div>
          <p class="font-weight-bold text-white-smoke fs-24 py-3">
            トライアル期間終了が間近です
          </p>
          <div class="text-left text-white-smoke">
            <p class="pb-4">
              ビザマネをお試しいただき、誠にありがとうございます。
            </p>
            <p class="pb-4">
              トライアル期間終了後、自動的にベーシックプランに移行し、従業員数制限
              <br />(30名まで)の状態のまま
              <span class="text-danger">無料でご利用いただくことが可能</span>
              です。
            </p>
            <p class="pb-4">
              30名以上従業員の登録を予定されている契約者様に関しましては、制限の解
              <br />除を
              <span class="text-danger">ベーシックプランを利用する </span>
              ボタンをクリックし、お問い合わせくださ
              <br />い。従業員数制限(30名まで)の解除後、登録従業員31名から月額料金が課金さ
              <br />れます。
            </p>
            <p class="pb-16">
              解約、または今回のお知らせに関する詳細は、
              <span
                class="text-danger text-decoration-underline cursor-pointer"
                @click="$router.push({ name: 'contact' })"
                >こちら
              </span>
              からお問い合わせください。
            </p>
          </div>

          <p
            class="button-bg-danger mx-auto h-56px w-30 fs-20 mt-4 mb-7"
            @click="$refs.endTrialFiveDayModal.close(), $emit('planModal')"
          >
            プランを変更する
          </p>
          <p
            class="text-danger cursor-pointer mb-2"
            @click="$refs.endTrialFiveDayModal.close()"
          >
            このまま利用する
          </p>
        </div>
      </template>
    </modal-component>

    <modal-component
      ref="firstLoginEndTrialModal"
      class="first-login-end-trial"
      :max-width="640"
      :header="false"
      :footer="false"
    >
      <template #body>
        <div class="pa-4">
          <div class="logo pt-8 pb-2">
            <Logo />
          </div>
          <p class="font-weight-bold text-white-smoke fs-24 py-3">
            トライアル期間が終了しました
          </p>
          <div class="text-left text-white-smoke">
            <p class="pb-4">
              ビザマネをお試しいただき、誠にありがとうございます。
            </p>
            <p class="pb-4">
              トライアル期間終了後も30名以下の制限の状態のまま<span
                class="text-danger"
                >無料でご利用いただく <br />ことが可能 </span
              >です。
            </p>
            <p class="pb-4">
              30名以上の従業員の登録を予定されている契約者様に関しましては、制限の
              <br />解除を<span class="text-danger">プランを変更する</span
              >ボタンをクリックし、ご希望のプランをご選択くだ
              <br />さい。従業員数制限(30名まで)の解除後、登録従業員数31名から月額料金が
              <br />課金されます。
            </p>
            <p class="pb-16">
              解約、または今回のお知らせに関する詳細は、
              <span
                class="text-danger text-decoration-underline cursor-pointer"
                @click="$router.push({ name: 'contact' })"
                >こちら
              </span>
              からお問い合わせください。
            </p>
          </div>

          <p
            class="button-bg-danger mx-auto h-56px w-30 fs-20 mt-4 mb-7"
            @click="$refs.firstLoginEndTrialModal.close(), $emit('planModal')"
          >
            プランを変更する
          </p>
          <p
            class="text-danger cursor-pointer mb-2"
            @click="$refs.firstLoginEndTrialModal.close()"
          >
            このまま利用する
          </p>
        </div>
      </template>
    </modal-component>
  </div>
</template>

<script>
import { POPUP_ACTION } from "@/common/constants.js";
import SessionStorage from "@/common/sessionStorage";

import Logo from "@/components/Logo.vue";

export default {
  name: "PopupActionModal",

  components: {
    Logo,
  },

  props: {
    popupAction: String,
  },

  mounted() {
    setTimeout(() => {
      switch (this.popupAction) {
        case POPUP_ACTION.FIRST_LOGIN:
          if (!SessionStorage.getData("firstLogin")) {
            SessionStorage.saveData("firstLogin", "displayed");
            this.$refs.modalPopupActionFirstLogin.open();
          }
          break;
        case POPUP_ACTION.FIRST_LOGIN_UNLIMITED:
          if (!SessionStorage.getData("firstLoginUnlimited")) {
            SessionStorage.saveData("firstLoginUnlimited", "displayed");
            this.$refs.firstLoginUnlimitedModal.open();
          }
          break;
        case POPUP_ACTION.END_TRIAL_FIVE_DAY:
          if (!SessionStorage.getData("endTrialFiveDayNotify")) {
            SessionStorage.saveData("endTrialFiveDayNotify", "displayed");
            this.$refs.endTrialFiveDayModal.open();
          }
          break;
        case POPUP_ACTION.FIRST_LOGIN_END_TRIAL:
          if (!SessionStorage.getData("firstLoginEndTrial")) {
            SessionStorage.saveData("firstLoginEndTrial", "displayed");
            this.$refs.firstLoginEndTrialModal.open();
          }
          break;
        default:
          break;
      }
    });
  },
};
</script>

<style lang="scss">
.modal-popup-action {
  #logo {
    width: 30rem;
  }
  .modal-first-login {
    .box {
      .v-col-3 {
        padding: 0.8rem !important;
      }

      .box-item {
        border-radius: 1rem;
        padding: 1rem 2rem 2rem 2rem;
        opacity: 1;
        height: 100%;
      }
    }
  }
}
</style>
