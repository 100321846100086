import { defineAsyncComponent } from "vue";

export function registerGlobalComponent(app) {
  app.component(
    "auth-layout",
    defineAsyncComponent(() => import("@/layouts/auth"))
  );

  app.component(
    "default-layout",
    defineAsyncComponent(() => import("@/layouts/default"))
  );

  app.component(
    "no-side-menu-layout",
    defineAsyncComponent(() => import("@/layouts/no-side-menu"))
  );

  app.component(
    "mobile-layout",
    defineAsyncComponent(() => import("@/layouts/mobile"))
  );

  app.component(
    "empty-layout",
    defineAsyncComponent(() => import("@/layouts/empty"))
  );

  app.component(
    "modal-component",
    defineAsyncComponent(() => import("@/components/ModalComponent"))
  );

  app.component(
    "default-card-component",
    defineAsyncComponent(() => import("@/components/DefaultCardComponent"))
  );
}
