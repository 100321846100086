import ApiService from "@/common/api.service";
import { APIS } from "@/common/api.constants";

export default {
  getNoticeList(params) {
    return ApiService.get(APIS.NOTICE_LIST, { params: params })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  getNoticeNewCount() {
    return ApiService.get(APIS.NOTICE_NEWCOUNT)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  postNoticeRead(formData) {
    return ApiService.post(APIS.NOTICE_READ, formData)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },
};
