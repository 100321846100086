const state = () => ({
    paramsContact: {
      title: null,
      content: null,
    },
  });
  
  const mutations = {
    setStateParamsContact(state, data) {
      state.paramsContact = {
        ...state.paramsContact,
        ...data,
      };
    },

    resetStateParamsContact(state) {
      state.paramsContact = {
      title: null,
      content: null,
      }
    }
  };
  
  export default {
    namespaced: true,
    state,
    mutations,
  };
  