<template>
  <div class="visa-status mt-3">
    <div class="text-danger fs-12">
      <a class="btn-back" @click="$emit('changeComponent', 'ConfirmCard')">
        <v-icon class="mb-1 mr-n2 mt-1">mdi-menu-left</v-icon>
        <span class="ml-n2" v-html="$t('previous screen')" />
      </a>
    </div>

    <div class="text-center border-8">
      <div class="residence-card-front">
        <img :src="require(`@/assets/img/residence_card_front.svg`)" />
      </div>
    </div>

    <!-- 在留カードに書いてある、在留資格を教えてください -->
    <div
      class="bg-grey-light-3 fs-16 text-white-smoke text-center pa-4 border-8"
    >
      <p v-html="$t('tell me your visa status')" />
    </div>

    <div class="mt-5">
      <pulldown-component
        :items="visaTypeList"
        item-text="name"
        item-value="value"
        :required="true"
        :squareLabeled="true"
        :label="$t('visa status')"
        :value="visaTypeRegister"
        :key="$i18n.locale"
        :multiLanguage="true"
        @change="visaTypeRegister = $event"
      />
    </div>

    <div class="py-7 text-center">
      <button
        class="btn w-12 fs-16 border-8 h-4 box-shadow"
        :class="visaTypeRegister ? ' btn-danger' : 'button-disabled'"
        :disabled="!visaTypeRegister"
        @click="next()"
      >
        <p v-html="$t('next')" />
      </button>
    </div>
  </div>
</template>

<script>
import { VISA_TYPE_LIST } from "@/common/constants.js";
import PulldownComponent from "@/components/forms/PulldownComponent.vue";

export default {
  name: "VisaStatus",

  components: {
    PulldownComponent,
  },

  props: ["residenceCard"],

  data() {
    return {
      visaTypeRegister: this.residenceCard.visaTypeRegister || null,
      visaTypeList: VISA_TYPE_LIST,
    };
  },

  methods: {
    next() {
      if (this.visaTypeRegister) {
        this.$emit("changeResidenceCard", {
          visaTypeRegister: this.visaTypeRegister,
        });
        this.$emit("changeComponent", "UploadPhoto");
      }
    },
  },
};
</script>
