<template>
  <div :key="resetData" class="h-100">
    <keep-alive>
      <component
        v-bind:is="currentComponent"
        :residenceCard="residenceCard"
        :requiredItemShow="requiredItemShow"
        :imageUrlLocal="imageUrlLocal"
        :shopStaffAddInfo="shopStaffAddInfo"
        :idAdmin="adminInfoState ? adminInfoState.idAdmin : null"
        @changeComponent="currentComponent = $event"
        @changeShopStaffAddInfo="shopStaffAddInfo = $event"
        @changeResidenceCard="onChangeResidenceCard"
        @changeRequiredItemShow="requiredItemShow = $event"
        @changeCustomizedResidenceCard="onChangeCustomizedResidenceCard"
        @saveImageLocal="saveImageLocal"
        @resetDataResidenceCard="resetDataResidenceCard"
      ></component>
    </keep-alive>
  </div>
</template>

<script>
import BasicInfo from "./basic-info.vue";
import VisaStatus from "./visa-status.vue";
import GradDate from "./grad-date.vue";
import TakePhoto from "./take-photo.vue";
import Signature from "./signature.vue";
import ConfirmInfo from "./confirm-info.vue";
import SessionStorage from "@/common/sessionStorage";

export default {
  components: {
    BasicInfo,
    VisaStatus,
    GradDate,
    TakePhoto,
    Signature,
    ConfirmInfo,
  },

  data() {
    return {
      currentComponent: "BasicInfo",
      residenceCard: null,
      imageUrlLocal: null,
      shopStaffAddInfo: null,
      resetData: 0,
      adminInfoState: JSON.parse(SessionStorage.getData("adminInfo")),
      requiredItemShow: null,
    };
  },

  methods: {
    // change data residence card
    onChangeResidenceCard(data) {
      this.residenceCard = {
        ...this.residenceCard,
        ...data,
      };
    },

    onChangeCustomizedResidenceCard(data) {
      this.residenceCard.customizedItems.find(
        (o) => o.nameItem == data.nameItem
      ).valueItem = data.valueItem;
    },

    // save imageURL to show final screen
    saveImageLocal(data) {
      this.imageUrlLocal = {
        ...this.imageUrlLocal,
        ...data,
      };
    },

    // reset data residence card null
    resetDataResidenceCard() {
      this.resetData++;
      this.residenceCard = null;
      this.imageUrlLocal = null;
    },
  },
};
</script>
