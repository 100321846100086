<template>
  <div class="confirm-info mt-5" v-if="residenceCard">
    <div class="text-danger fs-12">
      <a class="btn-back" @click="$emit('changeComponent', 'Signature')">
        <v-icon class="mb-1 mr-n2 mt-1">mdi-menu-left</v-icon>
        <span class="ml-n2" v-html="$t('previous screen')" />
      </a>
    </div>
    <div
      class="bg-grey-light-3 fs-16 text-white-smoke text-center pa-4 border-8"
    >
      <!-- 入力した内容と写真の文字がちゃんと読めるか確認して送信してください -->
      <p v-html="$t('confirm')" />
    </div>

    <div
      class="mt-5"
      v-if="residenceCard.nameStaffRegisterRequirement !== 'hidden'"
    >
      <input-component
        :value="residenceCard.nameStaffRegister"
        :placeholder="$t('katakana name')"
        :readonly="true"
        :hidden="!isShowStaffName"
      />
    </div>

    <div class="mt-5">
      <input-component
        :value="residenceCard.emailStaff"
        :placeholder="$t('email address')"
        :readonly="true"
        :hidden="!isShowStaffEmailAddress"
      />
    </div>

    <div class="mt-5">
      <input-component
        :value="residenceCard.phoneNumber"
        :placeholder="$t('phone number')"
        :readonly="true"
        :hidden="!isShowStaffPhoneNumber"
      />
    </div>

    <div class="mt-5">
      <pulldown-component
        :items="visaTypeList"
        item-text="name"
        item-value="value"
        :label="$t('visa status')"
        :value="residenceCard.visaTypeRegister"
        :key="$i18n.locale"
        :multiLanguage="true"
        :readonly="true"
      />
    </div>

    <div class="mt-5">
      <p
        v-html="$t('residence card frontside')"
        class="pb-4 text-white-smoke"
      />
      <image-preview-component
        :url="imageUrlLocal.residenceCardFrontURL"
        :height="213"
        :isMobile="true"
      />
    </div>

    <div class="mt-5">
      <p v-html="$t('residence card backside')" class="pb-4 text-white-smoke" />
      <image-preview-component
        :url="imageUrlLocal.residenceCardBackURL"
        :height="213"
        :isMobile="true"
      />
    </div>

    <div class="mt-5" v-if="imageUrlLocal.workDesignationURL">
      <p v-html="$t('designation')" class="pb-4 text-white-smoke" />
      <image-preview-component
        :url="imageUrlLocal.workDesignationURL"
        :height="213"
        :isMobile="true"
      />
    </div>

    <div class="mt-4 text-center">
      <p
        v-html="$t('signature')"
        class="pb-4 text-white-smoke"
        style="text-align: left"
      />
      <img :src="imageUrlLocal.signatureURL" alt="" />
    </div>

    <div class="py-7 d-flex justify-center">
      <button
        class="btn btn-danger w-25 fs-16 border-8 h-4 box-shadow"
        @click="submitResidenceCard"
      >
        <p v-html="$t('submit')" />
      </button>
    </div>

    <modal-component
      :modalData="modalDefault"
      ref="modalDefault"
      @close="
        isSubmitSuccess &&
          ($emit('changeComponent', 'Inquiry'), $emit('resetDataResidenceCard'))
      "
    />

    <modal-component
      :modalData="modalDefault"
      ref="resultModalDeepLink"
      :iconClose="false"
      :isClickOutside="false"
      :isDeepLink="true"
    />

    <ModalLoadingComponent ref="loadingModal" />
  </div>
</template>

<script>
import utilFunc from "@/common/utilFunc.js";
import {
  BUTTON_COLOR,
  VISA_TYPE_LIST,
  FILE_BASE_URL,
  REQUIRED_ITEM,
} from "@/common/constants.js";
import StaffService from "@/services/staff.service";

import InputComponent from "@/components/InputComponent.vue";
import ImagePreviewComponent from "@/components/ImagePreviewComponent.vue";
import ModalComponent from "@/components/ModalComponent.vue";
import PulldownComponent from "@/components/forms/PulldownComponent.vue";
import ModalLoadingComponent from "@/components/ModalLoadingComponent.vue";

export default {
  name: "ConfirmInfo",

  components: {
    InputComponent,
    ImagePreviewComponent,
    ModalComponent,
    PulldownComponent,
    ModalLoadingComponent,
  },

  props: ["residenceCard", "imageUrlLocal"],

  data() {
    return {
      utilFunc: utilFunc,
      colorConst: BUTTON_COLOR,
      visaTypeList: VISA_TYPE_LIST,
      fileBaseUrl: FILE_BASE_URL,

      modalDefault: null,
      isSubmitSuccess: false,

      isShowStaffName: true,
      isShowStaffEmailAddress: true,
      isShowStaffPhoneNumber: true,

      cardId: this.$route.query.cardId,
    };
  },

  created() {
    this.isShowStaffName =
      this.residenceCard.nameStaffRegisterRequirement !== REQUIRED_ITEM.HIDDEN;
    this.isShowStaffEmailAddress =
      this.residenceCard.emailStaffRequirement !== REQUIRED_ITEM.HIDDEN;
    this.isShowStaffPhoneNumber =
      this.residenceCard.phoneNumberRequirement !== REQUIRED_ITEM.HIDDEN;
  },

  methods: {
    // call api 更新在留カード提出
    submitResidenceCard() {
      this.$refs.loadingModal.open();
      const idStaff = this.residenceCard.idStaff;
      let payload = {
        idCompany: this.residenceCard.idCompany,
        nameStaffRegister: this.residenceCard.nameStaffRegister,
        emailStaff: this.residenceCard.emailStaff,
        phoneNumber: this.residenceCard.phoneNumber,
        visaTypeRegister: this.residenceCard.visaTypeRegister,
        residenceCardFrontURL: this.residenceCard.residenceCardFrontURL,
        residenceCardBackURL: this.residenceCard.residenceCardBackURL,
        signatureURL: this.residenceCard.signatureURL,
      };

      if (this.residenceCard.workDesignationURL) {
        payload = {
          ...payload,
          workDesignationURL: this.residenceCard.workDesignationURL,
        };
      }

      StaffService.postResidenceCard(idStaff, payload)
        .then((res) => {
          this.isSubmitSuccess = true;
          this.modalDefault = {
            result: res.result,
            message: {
              header: this.$t(res.message.header),
              title: this.$t(res.message.title),
              content: this.$t(res.message.content),
            },
          };
        })
        .catch((err) => {
          this.isSubmitSuccess = false;
          this.modalDefault = {
            result: err.result,
            message: {
              header: this.$t(err.message.header),
              title: this.$t(err.message.title),
              content: this.$t(err.message.content),
            },
          };
        })
        .finally(() => {
          this.$refs.loadingModal.close();
          if (this.cardId && this.isSubmitSuccess) {
            this.$refs.resultModalDeepLink.open();
          } else {
            this.$refs.modalDefault.open();
          }
        });
    },
  },
};
</script>
