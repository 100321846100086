<template>
  <div class="upload-residence-card mt-3">
    <div class="text-danger fs-12">
      <a class="btn-back" @click="$emit('changeComponent', 'VisaStatus')">
        <v-icon class="mb-1 mr-n2 mt-1">mdi-menu-left</v-icon>
        <span class="ml-n2" v-html="$t('previous screen')" />
      </a>
    </div>
    <div
      class="bg-grey-light-3 fs-16 text-white-smoke text-center py-5 border-8"
    >
      <p v-html="$t('additional info about you')" />
    </div>

    <div v-if="requiredItemShow.graduationDateRequirement">
      <div class="mt-5">
        <DatePickerComponent
          type="month"
          :value="gradMonth"
          :placeholder="$t('grad month')"
          :squareLabeled="true"
          :required="requiredItems.graduationDateRequirement"
          @changeDate="(gradMonth = $event), checkValidDay($event, 'gradDate')"
        />
      </div>

      <div class="mt-4">
        <pulldown-component
          :items="utilFunc.getDateListOfMonth(gradMonth)"
          item-text="name"
          item-value="value"
          :label="$t('grad date')"
          :value="gradDate"
          :key="gradMonth"
          :forcedSquareLabeled="true"
          :disabled="gradMonth === null || gradMonth === ''"
          @change="gradDate = $event"
        />
      </div>
    </div>

    <div v-if="requiredItemShow.studentCardExpiredDateRequirement">
      <div class="mt-4">
        <DatePickerComponent
          type="month"
          :value="expirationMonth"
          :placeholder="$t('expiration month of student ID')"
          :squareLabeled="true"
          :required="requiredItems.studentCardExpiredDateRequirement"
          @changeDate="
            (expirationMonth = $event), checkValidDay($event, 'expirationDate')
          "
        />
      </div>
      <div class="mt-4">
        <pulldown-component
          :items="utilFunc.getDateListOfMonth(expirationMonth)"
          item-text="name"
          item-value="value"
          :label="$t('expiration date of student ID')"
          :value="expirationDate"
          :key="expirationMonth"
          :forcedSquareLabeled="true"
          :disabled="expirationMonth === null || expirationMonth === ''"
          @change="expirationDate = $event"
        />
      </div>
    </div>

    <div class="mt-8 text-center">
      <button
        :class="[
          'btn w-12 fs-16',
          activeBtnNext ? ' btn-danger' : 'button-disabled',
          { 'h-4': $i18n.locale == 'ja' },
        ]"
        :disabled="!activeBtnNext"
        @click="next"
      >
        <p v-html="$t('next')"></p>
      </button>
    </div>
  </div>
</template>

<script>
import { REQUIRED_ITEM } from "@/common/constants.js";
import { mapState } from "vuex";
import utilFunc from "@/common/utilFunc.js";

import DatePickerComponent from "@/components/DatePickerComponent.vue";
import PulldownComponent from "@/components/forms/PulldownComponent.vue";

export default {
  name: "GradDate",

  props: ["shopStaffAddInfo", "requiredItemShow"],

  components: {
    DatePickerComponent,
    PulldownComponent,
  },

  data() {
    return {
      gradMonth: null,
      gradDate: null,
      expirationMonth: null,
      expirationDate: null,

      utilFunc: utilFunc,

      requiredItems: {
        graduationDateRequirement: false,
        studentCardExpiredDateRequirement: false,
      },
    };
  },

  created() {
    this.requiredItems.graduationDateRequirement =
      this.shopStaffAddInfo.graduationDateRequirement ===
      REQUIRED_ITEM.REQUIRED;
    this.requiredItems.studentCardExpiredDateRequirement =
      this.shopStaffAddInfo.studentCardExpiredDateRequirement ===
      REQUIRED_ITEM.REQUIRED;
  },

  computed: {
    ...mapState({
      companyInfoState: (state) => JSON.parse(state.companyInfo).data,
    }),

    activeBtnNext() {
      if (
        this.requiredItems.graduationDateRequirement &&
        (this.gradMonth === "" || this.gradMonth === null)
      ) {
        return false;
      }
      if (
        this.requiredItems.studentCardExpiredDateRequirement &&
        (this.expirationMonth === "" || this.expirationMonth === null)
      ) {
        return false;
      }

      return true;
    },
  },

  methods: {
    // check day, if daySelected > dayMaxMonth
    checkValidDay(date, field) {
      if (!date) {
        return;
      }
      const [year, month] = new Date(date)
        .toISOString()
        .substr(0, 10)
        .split("-");
      const daysInMonth = new Date(year, month, 0).getDate();

      if (daysInMonth < this[field]) {
        this[field] = daysInMonth;
      }
    },

    next() {
      this.$emit("changeComponent", "TakePhoto");

      const graduationDate =
        this.shopStaffAddInfo.graduationDateRequirement !== REQUIRED_ITEM.HIDDEN
          ? `${this.gradMonth}-${
              this.gradDate || utilFunc.getLastDayOfMonth(this.gradMonth)
            }`
          : null;

      const studentCardExpiredDate =
        this.shopStaffAddInfo.studentCardExpiredDateRequirement !==
        REQUIRED_ITEM.HIDDEN
          ? `${this.expirationMonth}-${
              this.expirationDate ||
              utilFunc.getLastDayOfMonth(this.expirationMonth)
            }`
          : null;

      this.$emit("changeResidenceCard", {
        graduationDate: graduationDate,
        studentCardExpiredDate: studentCardExpiredDate,
      });
    },
  },
};
</script>
