<template>
  <div class="custom-alert">
    <v-alert type="success" v-if="show">{{ alert.text }}</v-alert>
  </div>
</template>

<script>
import { DEFAULT_TIMER_ALERT } from "@/common/constants.js";

export default {
  name: "AlertComponent",

  props: ["alert"],

  data() {
    return {
      show: false,
    };
  },

  watch: {
    alert: function () {
      this.show = true;
    },

    show: function () {
      if (this.show) {
        setTimeout(
          () => {
            this.hide();
          },
          this.alert.timer ? this.alert.timer : DEFAULT_TIMER_ALERT
        );
      }
    },
  },

  methods: {
    hide() {
      this.show = false;
    },
  },
};
</script>
