<template>
  <div class="schedule mt-11">
    <div class="text-center border-8">
      <img
        :src="require(`@/assets/img/immigration-department.png`)"
        style="width: 330px"
      />
    </div>

    <!-- 更新が終わったら教えてください -->
    <div
      class="bg-grey-light-3 fs-16 text-white-smoke text-center pa-4 border-8"
    >
      <p v-html="$t('tell me your schedule')" />
    </div>
  </div>
</template>

<script>
export default {
  name: "Schedule",
};
</script>
