<template>
  <div class="change-info mt-3">
    <div class="text-danger fs-12">
      <a class="btn-back" @click="onBackAction">
        <v-icon class="mb-1 mr-n2 mt-1">mdi-menu-left</v-icon>
        <span class="ml-n2" v-html="$t('previous screen')" />
      </a>
    </div>

    <!-- あなたの登録情報に変更はありますか -->
    <div
      class="bg-grey-light-3 fs-16 text-white-smoke text-center pa-4 border-8"
    >
      <p v-html="$t('any modification?')" />
    </div>

    <div class="mt-5" v-if="isShowStaffName">
      <input-component
        :value="name"
        :placeholder="$t('katakana name')"
        :error="$t(errorName)"
        :disabled="!isChangeInfo"
        :required="isRequiredStaffName"
        :squareLabeled="true"
        @handle-change="onChangeName"
      />
    </div>

    <div class="mt-5" v-if="isShowStaffEmailAddress">
      <input-component
        name="emailAddress"
        :value="emailAddress"
        :placeholder="$t('email address')"
        :error="$t(errorEmailAddress)"
        :disabled="!isChangeInfo"
        :required="isRequiredStaffEmailAddress"
        :squareLabeled="true"
        @handle-change="onChangeEmailAddress"
      />
    </div>

    <div class="mt-5" v-if="isShowStaffPhoneNumber">
      <input-component
        :value="phoneNumber"
        :placeholder="$t('phone number')"
        :disabled="!isChangeInfo"
        :required="isRequiredStaffPhoneNumber"
        :squareLabeled="true"
        @handle-change="phoneNumber = $event"
      />
    </div>

    <div class="portrait-mobile mx-auto px-2 py-7">
      <div v-if="isChangeInfo" class="text-center">
        <button
          :class="[
            'btn w-12 fs-16  border-8 h-4',
            activeNext ? 'btn-danger' : 'button-disabled',
          ]"
          :disabled="!activeNext"
          @click="next()"
        >
          <p v-html="$t('next')" />
        </button>
      </div>

      <div v-else class="text-center">
        <button
          :class="[
            'btn w-12 fs-16 border-8 mr-3 h-4',
            activeNext ? 'button-danger' : 'button-disabled',
          ]"
          :disabled="!activeNext"
          @click="next()"
        >
          <p v-html="$t('no change')" />
        </button>

        <button
          class="btn w-12 fs-16 btn-danger border-8 h-4 box-shadow-button"
          @click="isChangeInfo = true"
        >
          <p v-html="$t('changes')" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import InputComponent from "@/components/InputComponent.vue";
import { REGEX_KATAKANA_FULLSIZE, REQUIRED_ITEM } from "@/common/constants.js";
import utilFunc from "@/common/utilFunc.js";

export default {
  name: "ChangeInfo",

  components: {
    InputComponent,
  },

  props: ["residenceCard"],

  data() {
    return {
      name: this.residenceCard.nameStaffRegister,
      emailAddress: this.residenceCard.emailStaff,
      phoneNumber: this.residenceCard.phoneNumber,
      errorName: "",
      errorEmailAddress: "",
      utilFunc: utilFunc,
      isChangeInfo: false,

      isShowStaffName: true,
      isShowStaffEmailAddress: true,
      isShowStaffPhoneNumber: true,
      isRequiredStaffName: false,
      isRequiredStaffEmailAddress: false,
      isRequiredStaffPhoneNumber: false,
    };
  },

  created() {
    this.isShowStaffName =
      this.residenceCard.nameStaffRegisterRequirement !== REQUIRED_ITEM.HIDDEN;
    this.isShowStaffEmailAddress =
      this.residenceCard.emailStaffRequirement !== REQUIRED_ITEM.HIDDEN;
    this.isShowStaffPhoneNumber =
      this.residenceCard.phoneNumberRequirement !== REQUIRED_ITEM.HIDDEN;

    this.isRequiredStaffName =
      this.residenceCard.nameStaffRegisterRequirement ===
      REQUIRED_ITEM.REQUIRED;
    this.isRequiredStaffEmailAddress =
      this.residenceCard.emailStaffRequirement === REQUIRED_ITEM.REQUIRED;
    this.isRequiredStaffPhoneNumber =
      this.residenceCard.phoneNumberRequirement === REQUIRED_ITEM.REQUIRED;

    if (
      !this.isShowStaffName &&
      !this.isShowStaffEmailAddress &&
      !this.isShowStaffPhoneNumber
    ) {
      this.next();
    }
  },

  computed: {
    activeNext() {
      if (this.errorName || this.errorEmailAddress) {
        return false;
      }

      if (
        this.isRequiredStaffName &&
        (this.name === null || this.name === "")
      ) {
        return false;
      }

      if (
        this.isRequiredStaffEmailAddress &&
        (this.emailAddress === null || this.emailAddress === "")
      ) {
        return false;
      }

      if (
        this.isRequiredStaffPhoneNumber &&
        (this.phoneNumber === null || this.phoneNumber === "")
      ) {
        return false;
      }

      return true;
    },
  },

  methods: {
    onChangeName(name) {
      this.name = name;

      if (name && !name.match(REGEX_KATAKANA_FULLSIZE)) {
        this.errorName = "need to be katakana";
        return;
      }

      this.errorName = "";
    },

    onChangeEmailAddress(email) {
      this.emailAddress = email;

      if (!email) {
        this.errorEmailAddress = "";
        return;
      }

      if (!utilFunc.validateEmail(email)) {
        this.errorEmailAddress = "invalid email format";
        return;
      }

      this.errorEmailAddress = "";
    },

    onBackAction() {
      if (this.isChangeInfo) {
        this.isChangeInfo = false;
      } else {
        this.$emit("changeComponent", "UploadPhoto");
        this.isChangeInfo = false;
      }
    },

    next() {
      const data = {
        nameStaffRegister: this.name,
        emailStaff: this.emailAddress,
        phoneNumber: this.phoneNumber,
      };

      this.$emit("changeResidenceCard", data);
      this.$emit("changeComponent", "Signature");
    },
  },
};
</script>
