<template>
  <svg
    id="logo"
    :class="$style.logo"
    xmlns="http://www.w3.org/2000/svg"
    width="115.063"
    height="30.67"
    viewBox="0 0 115.063 30.67"
  >
    <g id="group_1" data-name="group 1" transform="translate(39.602 5.746)">
      <path
        id="path_1"
        :class="[$style.stroke, $style.fill]"
        data-name="path 1"
        d="M358.266,925.431a25.255,25.255,0,0,0,8.088-2.9c.847-.485,1.29-.908,1.775-.908a1.012,1.012,0,0,1,.908,1.008c0,.465-.182.767-1.432,1.493a29.652,29.652,0,0,1-9.338,3.146v4.175c0,1.331.2,1.977,3.853,1.977,1.815,0,2.843-.082,3.952-.142,1.109-.08,2.258-.262,2.722-.262.626,0,.948.322.948.867,0,.927-.342,1.13-4.114,1.291-1.028.04-2.42.06-3.732.06-3.569,0-5.485-.544-5.485-3.55v-11.9a.927.927,0,1,1,1.855,0Zm11.959-5.143a1.42,1.42,0,0,1,.323.787.737.737,0,0,1-.746.706c-.363,0-.5-.263-.685-.545-.283-.443-.525-.826-.787-1.169-.282-.382-.463-.625-.463-.867a.712.712,0,0,1,.746-.705C368.855,918.494,369.137,918.514,370.226,920.288Zm2.219-1.029a1.388,1.388,0,0,1,.282.767.713.713,0,0,1-.706.706.821.821,0,0,1-.705-.484,10.863,10.863,0,0,0-.827-1.23c-.222-.3-.464-.524-.464-.847a.719.719,0,0,1,.746-.686C371.315,917.485,372.182,918.857,372.445,919.26Z"
        transform="translate(-356.412 -917.485)"
        stroke-miterlimit="10"
        stroke-width="0.5"
      />
      <path
        id="path_2"
        :class="[$style.stroke, $style.fill]"
        data-name="path 2"
        d="M379.3,923.164h6.655v-3.327a.948.948,0,1,1,1.9,0v3.327h2.743a.877.877,0,0,1,0,1.754h-2.783c-.1,3.449-.564,7.563-4.982,10.406a4.286,4.286,0,0,1-1.976.887.992.992,0,0,1-.927-.988c0-.464.2-.706.726-.968,3.67-1.9,5.183-3.932,5.283-9.338H379.3v3.812a.908.908,0,1,1-1.815,0v-3.812H374.7a.877.877,0,0,1,0-1.754h2.783v-3.388a.907.907,0,1,1,1.815,0Zm11.113-2.621a1.591,1.591,0,0,1,.3.827.709.709,0,0,1-.726.625c-.263,0-.444-.182-.605-.484-.263-.422-.565-.927-.767-1.23a1.431,1.431,0,0,1-.342-.786.718.718,0,0,1,.705-.626C389.22,918.868,389.442,918.868,390.41,920.543Zm.907-2.339a5.762,5.762,0,0,1,1.291,2.157.668.668,0,0,1-.686.686c-.282,0-.4-.122-.645-.5-.262-.4-.585-.908-.887-1.312a1.359,1.359,0,0,1-.444-.786.689.689,0,0,1,.705-.605A.76.76,0,0,1,391.317,918.2Z"
        transform="translate(-356.023 -917.477)"
        stroke-miterlimit="10"
        stroke-width="0.5"
      />
      <path
        id="path_3"
        :class="[$style.stroke, $style.fill]"
        data-name="path 3"
        d="M409.423,920.78c.726,0,1.209.362,1.209,1.028,0,1.674-3.992,5.849-7.421,8.51.705.808,1.634,1.957,2,2.421.464.625.787,1.048.787,1.431a1.1,1.1,0,0,1-1.09,1.069c-.363,0-.585-.242-.948-.787a30.331,30.331,0,0,0-5.869-6.473c-.463-.363-.968-.686-.968-1.13a1.033,1.033,0,0,1,1.009-.988c.544,0,1.674.948,3.871,3.146,2.562-1.835,6.031-5.667,6.031-6.252,0-.141-.081-.182-.344-.182H394.862a.9.9,0,0,1,0-1.794Z"
        transform="translate(-355.573 -917.411)"
        stroke-miterlimit="10"
        stroke-width="0.5"
      />
      <path
        id="path_4"
        :class="[$style.stroke, $style.fill]"
        data-name="path 4"
        d="M422.945,921.42H427.1c.585,0,1.049.3,1.049.867,0,1.593-3.146,4.518-5.264,5.969V935.7a.938.938,0,1,1-1.875,0v-6.353a25.33,25.33,0,0,1-6.676,2.562.914.914,0,0,1-.827-.989c0-.464.283-.686.727-.807a25.7,25.7,0,0,0,8.026-3.569c2.077-1.492,3.368-2.966,3.368-3.268,0-.121-.06-.14-.242-.14h-9.175a.857.857,0,0,1,0-1.714h4.86v-2.158a.938.938,0,1,1,1.876,0Zm3.872,6.7a34.337,34.337,0,0,1,2.824,2.38c.4.4.707.726.707,1.19a1,1,0,0,1-.928.968c-.464,0-.564-.2-.948-.585a29.926,29.926,0,0,0-3.166-2.8c-.364-.3-.565-.5-.565-.827a.993.993,0,0,1,.908-.907A2.238,2.238,0,0,1,426.817,928.115Z"
        transform="translate(-355.137 -917.466)"
        stroke-miterlimit="10"
        stroke-width="0.5"
      />
    </g>
    <path
      id="Path_980"
      :class="$style.stroke"
      data-name="Path 980"
      d="M330.967,933.642a1.916,1.916,0,0,1-1.214-.445l-4.406-3.664A1.917,1.917,0,0,1,327.8,926.6l3,2.5,7.368-7.932a1.917,1.917,0,1,1,2.836,2.58l-.029.03-8.6,9.265a1.918,1.918,0,0,1-1.406.6Z"
      transform="translate(-317.518 -911.672)"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="3"
    />
    <g id="group_2" data-name="group 2">
      <path
        id="path_5"
        :class="$style.fill"
        data-name="path 5"
        d="M338.708,939.21a13.271,13.271,0,1,1,7.276-9.116l1.995.453a15.325,15.325,0,1,0-8.408,10.518Z"
        transform="translate(-317.675 -911.865)"
      />
      <circle
        id="楕円形_1"
        :class="$style.fill"
        data-name="楕円形 1"
        cx="1.022"
        cy="1.022"
        r="1.022"
        transform="translate(28.289 17.415)"
      />
      <circle
        id="楕円形_2"
        :class="$style.fill"
        data-name="楕円形 2"
        cx="1.022"
        cy="1.022"
        r="1.022"
        transform="translate(20.44 27.251)"
      />
      <circle
        id="楕円形_3"
        :class="$style.fill"
        data-name="楕円形 3"
        cx="1.022"
        cy="1.022"
        r="1.022"
        transform="translate(27.065 20.696)"
      />
    </g>
  </svg>
</template>

<style lang="scss" module>
@import "@/assets/scss/abstracts/variable.scss";

.logo {
  height: 100%;
}

.stroke {
  stroke: $color-danger;
}

.fill {
  fill: $color-danger;
}
</style>
