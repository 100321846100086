<template>
  <div class="privacy-pledge mt-6 fs-16 text-color-grey text-center">
    <p v-html="$t('sign')" />
  </div>
  <div class="signatureBox">
    <div ref="canvasHW">
      <canvas
        class="canvas-signature"
        ref="canvasF"
        @touchstart="touchStart"
        @touchmove="touchMove"
        @touchend="touchEnd"
        @mousedown="mouseDown"
        @mousemove="mouseMove"
        @mouseup="mouseUp"
      ></canvas>
    </div>
  </div>
  <div class="text-left mb-1 ml-1 mt-2">
    <button
      :class="[
        'fs-12',
        points.length >= 10 ? 'button-danger' : 'button-disabled',
      ]"
      :color="colorConst.CANCEL"
      @click="points.length >= 10 && handleGoBack()"
    >
      <p v-html="$t('rewrite')" />
    </button>
  </div>
</template>

<script>
import { BUTTON_COLOR } from "@/common/constants.js";

export default {
  name: "SignatureComponent",
  emits: ["signture", "signtureFile"],
  data() {
    return {
      points: [],
      canvas: null,
      canvasTxt: null,
      startX: 0,
      startY: 0,
      moveY: 0,
      moveX: 0,
      isDown: false,
      strokeStyle: "#000",
      lineWidth: 3,

      colorConst: BUTTON_COLOR,
    };
  },

  mounted() {
    this.canvas = this.$refs.canvasF;
    // Get the height of the canvas
    this.canvas.height = this.$refs.canvasHW.offsetHeight - 10;
    // Get the width of the canvas
    this.canvas.width = this.$refs.canvasHW.offsetWidth - 10;
    // Create context object
    this.canvasTxt = this.canvas.getContext("2d");
  },

  methods: {
    // Computer device events
    mouseDown(ev) {
      ev = ev || event;
      ev.preventDefault();
      if (ev) {
        let obj = {
          x: ev.offsetX,
          y: ev.offsetY,
        };
        this.startX = obj.x;
        this.startY = obj.y;
        this.canvasTxt.beginPath();
        this.canvasTxt.moveTo(this.startX, this.startY);
        this.canvasTxt.lineTo(obj.x, obj.y);
        this.canvasTxt.stroke();
        this.canvasTxt.closePath();
        this.points.push(obj);
        this.isDown = true;
      }
    },

    // Computer device events
    mouseMove(ev) {
      ev = ev || event;
      ev.preventDefault();
      if (this.isDown) {
        let obj = {
          x: ev.offsetX,
          y: ev.offsetY,
        };
        this.moveY = obj.y;
        this.moveX = obj.x;
        this.canvasTxt.strokeStyle = this.strokeStyle;
        this.canvasTxt.lineWidth = this.lineWidth;
        this.canvasTxt.beginPath();
        this.canvasTxt.moveTo(this.startX, this.startY);
        this.canvasTxt.lineTo(obj.x, obj.y);
        this.canvasTxt.stroke();
        this.canvasTxt.closePath();
        this.startY = obj.y;
        this.startX = obj.x;
        this.points.push(obj);
      }
    },

    // Computer device events
    mouseUp(ev) {
      ev = ev || event;
      ev.preventDefault();
      if (ev) {
        let obj = {
          x: ev.offsetX,
          y: ev.offsetY,
        };
        this.canvasTxt.beginPath();
        this.canvasTxt.moveTo(this.startX, this.startY);
        this.canvasTxt.lineTo(obj.x, obj.y);
        this.canvasTxt.stroke();
        this.canvasTxt.closePath();
        this.points.push(obj);
        this.points.push({ x: -1, y: -1 });
        this.isDown = false;
      }

      this.handleSubmit();
    },

    // Mobile device events
    touchStart(ev) {
      ev = ev || event;
      ev.preventDefault();
      if (ev.touches.length == 1) {
        let obj = {
          x:
            ev.targetTouches[0].clientX -
            this.canvas.getBoundingClientRect().left,
          y:
            ev.targetTouches[0].clientY -
            this.canvas.getBoundingClientRect().top,
        };
        this.startX = obj.x;
        this.startY = obj.y;
        this.canvasTxt.beginPath();
        this.canvasTxt.moveTo(this.startX, this.startY);
        this.canvasTxt.lineTo(obj.x, obj.y);
        this.canvasTxt.stroke();
        this.canvasTxt.closePath();
        this.points.push(obj);
      }
    },

    // Mobile device events
    touchMove(ev) {
      ev = ev || event;
      ev.preventDefault();
      if (ev.touches.length == 1) {
        let obj = {
          x:
            ev.targetTouches[0].clientX -
            this.canvas.getBoundingClientRect().left,
          y:
            ev.targetTouches[0].clientY -
            this.canvas.getBoundingClientRect().top,
        };
        this.moveY = obj.y;
        this.moveX = obj.x;
        // Set line color
        this.canvasTxt.strokeStyle = this.strokeStyle;
        // Set line width
        this.canvasTxt.lineWidth = this.lineWidth;
        // Draw start path
        this.canvasTxt.beginPath();
        // Define the start coordinates of the line
        this.canvasTxt.moveTo(this.startX, this.startY);
        // Define the end coordinates of the line
        this.canvasTxt.lineTo(obj.x, obj.y);
        // Draw lines
        this.canvasTxt.stroke();
        this.canvasTxt.closePath();
        this.startY = obj.y;
        this.startX = obj.x;
        this.points.push(obj);
      }
    },

    // Mobile device events
    touchEnd(ev) {
      ev = ev || event;
      ev.preventDefault();
      if (ev.touches.length == 1) {
        let obj = {
          x:
            ev.targetTouches[0].clientX -
            this.canvas.getBoundingClientRect().left,
          y:
            ev.targetTouches[0].clientY -
            this.canvas.getBoundingClientRect().top,
        };
        this.canvasTxt.beginPath();
        this.canvasTxt.moveTo(this.startX, this.startY);
        this.canvasTxt.lineTo(obj.x, obj.y);
        this.canvasTxt.stroke();
        this.canvasTxt.closePath();
        this.points.push(obj);
        this.points.push({ x: -1, y: -1 });
      }

      this.handleSubmit();
    },

    handleGoBack() {
      this.canvasTxt.clearRect(
        0,
        0,
        this.$refs.canvasF.width,
        this.$refs.canvasF.height
      );
      this.points = [];
      this.$emit("signture", null);
      this.$emit("signture-file", null);
    },

    handleSubmit() {
      if (this.points.length < 10) {
        return;
      }
      this.$emit("signture", this.$refs.canvasF.toDataURL());

      const blobBin = atob(this.$refs.canvasF.toDataURL().split(",")[1]);
      let array = [];
      for (let i = 0; i < blobBin.length; i++) {
        array.push(blobBin.charCodeAt(i));
      }
      const file = new File([new Uint8Array(array)], "image.png", {
        type: "image/png",
      });
      this.$emit("signture-file", file);
    },
  },
};
</script>

<style lang="scss">
.signatureBox .canvas-signature {
  height: 20rem;
}

canvas {
  border: 0.2rem solid #f8f8f9;
  border-radius: 0.8rem;
  opacity: 1;
}
</style>
