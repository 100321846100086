<template>
  <div class="upload-photo mt-3">
    <div class="text-danger fs-12" v-if="!getImageUrl(step)">
      <a class="btn-back" @click="backStep(step)">
        <v-icon class="mb-1 mr-n2 mt-1">mdi-menu-left</v-icon>
        <span class="ml-n2" v-html="$t('previous screen')" />
      </a>
    </div>
    <div
      class="text-white-smoke text-center"
      v-if="!initCamera && !getImageUrl(step)"
    >
      <div class="portrait-mobile image mx-auto">
        <img
          v-if="step == 'residenceCardFront'"
          class="w-100"
          :src="require(`@/assets/img/residenceCardFront.png`)"
        />
        <img
          v-else-if="step == 'residenceCardBack'"
          class="w-100"
          :src="require(`@/assets/img/residenceCardBack.png`)"
        />
        <img
          v-else-if="step == 'workDesignation'"
          class="w-100"
          :src="require(`@/assets/img/photo_example_designation.svg`)"
        />
        <img
          v-else-if="step == 'passport'"
          class="w-100"
          :src="require(`@/assets/img/photo_example_passport.svg`)"
        />
        <img
          v-else-if="step == 'studentCard'"
          class="w-100"
          :src="require(`@/assets/img/photo_example_student_ID.svg`)"
        />
        <img
          v-else
          class="w-100"
          :src="require(`@/assets/img/photo_example_other.svg`)"
        />
      </div>

      <p class="portrait-mobile box mx-auto pa-4">
        <span
          v-if="step == 'residenceCardFront'"
          v-html="$t('take the photo of your residence card frontside')"
        />
        <span
          v-else-if="step == 'residenceCardBack'"
          v-html="$t('take the photo of your residence card backside')"
        />
        <span
          v-else-if="step == 'workDesignation'"
          v-html="$t('take the photo of the designation')"
        />
        <span
          v-else-if="step == 'certificateLongVacation'"
          v-html="$t('take the photo of the certificate of long vacation')"
        />
        <span
          v-else-if="step == 'passport'"
          v-html="$t('take the photo of the passport')"
        />
        <span
          v-else-if="step == 'studentCard'"
          v-html="$t('take the photo of the student ID')"
        />
        <span
          v-else
          v-html="
            fixCustomizedItemReason(
              $t('take the photo of the customized item'),
              step
            )
          "
        />
      </p>

      <div
        v-if="step == 'residenceCardFront' || step == 'residenceCardBack'"
        class="
          portrait-mobile
          d-flex
          justify-space-between
          mx-auto
          px-2
          pt-7
          pb-4
        "
      >
        <label for="file">
          <p
            class="button-danger"
            :class="{ 'py-1': $i18n.locale !== 'ja' }"
            v-html="$t('select a file')"
          ></p>
          <input
            ref="file"
            class="d-none"
            id="file"
            type="file"
            @change="uploadImage('file')"
          />
        </label>

        <div @click="initCamera = true">
          <p
            :class="{
              'py-1': $i18n.locale !== 'ja',
              'button-bg-danger':
                $i18n.locale !== 'pr' && $i18n.locale !== 'my',
              'button-bg-danger-flex':
                $i18n.locale === 'pr' || $i18n.locale === 'my',
            }"
            v-html="$t('take photo')"
          ></p>
        </div>
      </div>
      <div v-else class="d-flex justify-center px-2 pt-7 pb-4">
        <label for="file">
          <p
            v-html="$t('select a file') + '/' + $t('take photo')"
            class="button-bg-danger select-take-photo"
            :class="{ 'py-1': $i18n.locale !== 'ja' }"
          ></p>
          <input
            ref="file"
            class="d-none"
            id="file"
            type="file"
            @change="uploadImage('file')"
          />
        </label>
      </div>
      <div class="d-flex justify-center px-2 pb-7">
        <p
          class="button-danger"
          :class="{ 'py-1': $i18n.locale !== 'ja' }"
          v-html="$t('no at hand')"
          @click="skipScreen(step)"
        ></p>
      </div>
    </div>

    <div :class="isMobile ? 'take-photo-section' : 'mt-7'">
      <take-photo-component
        v-if="!getImageUrl(step)"
        :init-camera="initCamera"
        @stop-camera="initCamera = false"
        @image-upload="setImageUpload($event, step)"
        @file-upload="setFileUpload($event, step)"
      >
        <div
          class="portrait-mobile box text-white-smoke text-center mx-auto"
          :class="isMobile ? 'guide-take-photo' : 'mt-5'"
        >
          <p class="pa-4" v-html="$t('within the frame')"></p>
        </div>
      </take-photo-component>
    </div>

    <div
      v-if="getImageUrl(step)"
      :class="{ 'mt-7': getImageUrl(step) }"
      class="text-center"
    >
      <image-preview-component
        :key="getImageUrl(step)"
        :url="getImageUrl(step)"
        :is-mobile="true"
        :max-width="343"
        :height="213"
      />
      <div class="portrait-mobile box text-white-smoke mx-auto mt-5">
        <p class="pa-4" v-html="$t('clear and readable')"></p>
      </div>
      <div
        class="portrait-mobile d-flex justify-space-between mx-auto px-2 py-7"
      >
        <p
          class="button-danger"
          :class="{ 'py-1': $i18n.locale !== 'ja' }"
          v-html="$t('retake')"
          @click="setImageUpload(null, step)"
        ></p>
        <p
          :class="{
            'py-1': $i18n.locale !== 'ja',
            'button-bg-danger': $i18n.locale !== 'ep' && $i18n.locale !== 'my',
            'button-bg-danger-flex':
              $i18n.locale === 'ep' || $i18n.locale === 'my',
          }"
          v-html="$t('next')"
          @click="nextStep(step)"
        ></p>
      </div>
    </div>

    <modal-component
      ref="modalLoading"
      :iconClose="false"
      :isClickOutside="false"
      :max-width="500"
    >
      <template #header> <span v-html="$t('upload image')" /> </template>
      <template #body>
        <div class="modal-body-data fs-14 mx-4 text-white-smoke">
          <div class="modal-body__img">
            <loading-component :loading="true" />
          </div>
          <h1 class="modal-body__title" v-html="$t('image uploading')" />
          <p v-html="$t('wait a moment')" />
        </div>
      </template>
    </modal-component>

    <modal-component
      :modalData="resultData"
      ref="resultModal"
      :max-width="500"
    />
  </div>
</template>

<script>
import EtcService from "@/services/etc.service";
import TakePhotoComponent from "@/components/TakePhotoComponent.vue";
import ImagePreviewComponent from "@/components/ImagePreviewComponent.vue";
import LoadingComponent from "@/components/LoadingComponent.vue";
import { MOBILE_LIST } from "@/common/constants.js";

export default {
  name: "UploadPhoto",

  props: ["staffUploadDocumentInfo", "idAdmin"],

  components: {
    TakePhotoComponent,
    ImagePreviewComponent,
    LoadingComponent,
  },
  data() {
    return {
      initCamera: false,
      step: null,
      stepList: [],
      customizedItems: [],

      residenceCardFrontURL: "",
      residenceCardBackURL: "",
      workDesignationURL: "",
      certificateLongVacationURL: "",
      passportURL: "",
      studentCardURL: "",

      residenceCardFrontFile: null,
      residenceCardBackFile: null,
      workDesignationFile: null,
      certificateLongVacationFile: null,
      passportFile: null,
      studentCardFile: null,

      resultData: null,
    };
  },

  computed: {
    isMobile() {
      return MOBILE_LIST.test(navigator.userAgent);
    },
  },

  created() {
    this.handleCustomizedItems();
    this.staffUploadDocumentInfo.requestData.forEach((o) =>
      this.stepList.push(o.type)
    );
    this.step = this.stepList[0];
  },

  methods: {
    //hander customized items to add screen upload image
    handleCustomizedItems() {
      this.customizedItems = this.staffUploadDocumentInfo.requestData
        .filter((o) => o.nameItem)
        .map((item) => ({
          idCompanySetting: item.idCustomizedItem,
          nameItem: item.nameItem,
          valueItem: null,
          file: null,
        }));
    },

    fixCustomizedItemReason(base, item) {
      if (base.match(/#customized_item#/)) {
        return base.replaceAll("#customized_item#", item);
      }

      return base;
    },

    nextStep(currentStep) {
      this.$refs.modalLoading.open();
      let formData = new FormData();
      formData.append("file", this.getImageFile(currentStep));
      formData.append("type", `${currentStep}URL`);
      formData.append("path", this.$route.path);
      formData.append(
        "idCompany",
        Number(this.staffUploadDocumentInfo.idCompany)
      );
      if (this.idAdmin) {
        formData.append("idAdmin", this.idAdmin);
      }

      EtcService.postPictureUploadAny(formData)
        .then((res) => {
          if (this.checkCustomizedStep(currentStep)) {
            this.$emit("changeCustomizedRequestData", {
              nameItem: currentStep,
              valueItem: res.data,
            });
          } else {
            this.$emit("changeRequestData", {
              [currentStep]: res.data,
            });
          }
          this.$emit("saveImageLocal", {
            [currentStep + "URL"]: this.checkCustomizedStep(currentStep)
              ? this.customizedItems.find((o) => o.nameItem == currentStep)
                  .valueItem
              : this[currentStep + "URL"],
          });
          this.nextScreen(currentStep);
        })
        .catch((err) => {
          this.resultData = err;
          this.$refs.resultModal.open();
        })
        .finally(() => {
          this.$refs.modalLoading.close();
        });
    },

    skipScreen(currentStep) {
      if (this.checkCustomizedStep(currentStep)) {
        this.$emit("changeCustomizedRequestData", {
          nameItem: currentStep,
          valueItem: null,
        });
      } else {
        this.$emit("changeRequestData", {
          [currentStep]: null,
        });
      }

      this.$emit("saveImageLocal", {
        [currentStep + "URL"]: null,
      });

      this.nextScreen(currentStep);
    },

    // next to screen
    nextScreen(currentStep) {
      let index = this.stepList.indexOf(currentStep);

      for (let i = index + 1; i < this.stepList.length; i++) {
        this.step = this.stepList[i];
        return;
      }

      if (this.residenceCardFrontURL) {
        this.$emit("changeComponent", "Signature");
      } else {
        this.$emit("changeRequestData", {
          signature: null,
        });
        this.$emit("saveImageLocal", {
          signatureURL: null,
        });
        this.$emit("changeComponent", "ConfirmInfo");
      }
    },

    //back screeen
    backStep(currentStep) {
      if (this.initCamera) {
        this.initCamera = false;
        return;
      }

      if (this.step == this.stepList[0]) {
        this.$emit("changeComponent", "RequestDataInfo");
        return;
      }

      let index = this.stepList.indexOf(currentStep) - 1;
      for (let i = index; i >= 0; i--) {
        this.step = this.stepList[i];
        return;
      }
    },

    uploadImage(refs) {
      const file = this.$refs[refs].files[0];
      this.setFileUpload(file, this.step);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        this.setImageUpload(reader.result.toString(), this.step);
      });
      if (file) {
        reader.readAsDataURL(file);
      }
    },

    checkCustomizedStep(currentStep) {
      return (
        currentStep != "residenceCardFront" &&
        currentStep != "residenceCardBack" &&
        currentStep != "workDesignation" &&
        currentStep != "certificateLongVacation" &&
        currentStep != "passport" &&
        currentStep != "studentCard"
      );
    },

    setFileUpload(file, currentStep) {
      if (this.checkCustomizedStep(currentStep)) {
        this.customizedItems.find((o) => o.nameItem == currentStep).file = file;
      } else {
        this[`${currentStep}File`] = file;
      }
    },

    getImageFile(currentStep) {
      if (this.checkCustomizedStep(currentStep)) {
        return this.customizedItems.find((o) => o.nameItem == currentStep).file;
      } else {
        return this[`${currentStep}File`];
      }
    },

    setImageUpload(imageUrl, currentStep) {
      if (this.checkCustomizedStep(currentStep)) {
        this.customizedItems.find((o) => o.nameItem == currentStep).valueItem =
          imageUrl;
      } else {
        this[`${currentStep}URL`] = imageUrl;
      }
    },

    getImageUrl(currentStep) {
      if (this.checkCustomizedStep(currentStep)) {
        return this.customizedItems.find((o) => o.nameItem == currentStep)
          .valueItem;
      } else {
        return this[`${currentStep}URL`];
      }
    },
  },
};
</script>
