import { FORMAT_NUMBER, FORMAT_EMAIL } from "@/common/constants";

export default {
  // format number, example: 1,000,000
  formatNumber(number) {
    return number.toString().replace(FORMAT_NUMBER, "$1,");
  },

  // check format email
  validateEmail(email) {
    if (email.match(FORMAT_EMAIL)) {
      return true;
    }
    return false;
  },

  downloadCsvFile(content, name, nameResponse = false) {
    const fileName = nameResponse ? `${name}` : `${name}.csv`;
    // bom付きUTF-8で出力する為のBOM
    const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
    let blob = new Blob([bom, content], { type: "text/csv;charset=utf-8" });
    let link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  },

  downloadFile(content, name, type, nameResponse = false) {
    let fileURL;

    if (typeof content === "string") {
      fileURL = content;
    } else {
      fileURL = window.URL.createObjectURL(new Blob([content]));
    }

    let link = document.createElement("a");
    link.setAttribute("href", fileURL);
    link.setAttribute("download", nameResponse ? `${name}` : `${name}.${type}`);
    link.click();

    if (typeof content !== "string") {
      window.URL.revokeObjectURL(fileURL);
    }
  },

  // get file name from header content-disposition of response
  getFileNameResponse(response) {
    let headerLine = response.headers["content-disposition"]
      .split("filename=")[1]
      .split(";")[0];
    return headerLine.slice(1, headerLine.length - 1);
  },

  formatDate(date = new Date(), toFormat = "yyyy-mm-dd") {
    if (date == "Invalid Date") {
      return null;
    }

    const [year, month, day] = date.toISOString().substr(0, 10).split("-");
    const [hour, minute] = date.toTimeString().substr(0, 5).split(":");
    let dateFormated = null;

    switch (toFormat) {
      case "dd/mm/yyyy":
        dateFormated = `${day}/${month}/${year}`;
        break;
      case "yyyy/mm/dd":
        dateFormated = `${year}/${month}/${day}`;
        break;
      case "yyyy-mm-dd":
        dateFormated = `${year}-${month}-${day}`;
        break;
      // format date, example: 2021年9月
      case "yyyy年mm月":
        dateFormated = `${year}年${month}月`;
        break;
      //format date, example: 2021年8月30日
      case "yyyy年mm月dd日":
        dateFormated = `${year}年${month}月${day}日`;
        break;
      case "yyyy年mm月dd日 hh時mm分":
        dateFormated = `${year}年${month}月${day}日 ${hour}時${minute}分`;
        break;
    }

    return dateFormated;
  },

  validatePassword(password, passwordValidation) {
    passwordValidation.isMoreThan8Letters = password.length >= 8;
    passwordValidation.isUppercaseLowercase =
      password.match(/[A-Z]/) && password.match(/[a-z]/);
    //eslint-disable-next-line
    const specialCharacters = /[`~!@#$%^&*()_+\-=\[\]{};'<>:"\\|,.<>\/?]+/;
    passwordValidation.isSpecialCharacters = password.match(specialCharacters);
    passwordValidation.isNumber = password.match(/\d/);
  },

  copyURL(refs) {
    const urlQrCode = refs;
    const range = document.createRange();
    window.getSelection().removeAllRanges();
    range.selectNode(urlQrCode);
    window.getSelection().addRange(range);
    document.execCommand("copy");
    window.getSelection().removeAllRanges();
  },

  // get date list of month
  getDateListOfMonth(date) {
    let daysInMonth;
    if (!date) {
      daysInMonth = 31;
    } else {
      const [year, month] = new Date(date)
        .toISOString()
        .substr(0, 10)
        .split("-");
      daysInMonth = new Date(year, month, 0).getDate();
    }

    let dayList = [];

    for (let i = 1; i <= daysInMonth; i++) {
      dayList.push({ name: i < 10 ? `0${i}` : i, value: i < 10 ? `0${i}` : i });
    }

    return dayList;
  },

  getLastDayOfMonth(date) {
    let daysInMonth;
    if (!date) {
      daysInMonth = 31;
    } else {
      const [year, month] = new Date(date)
        .toISOString()
        .substr(0, 10)
        .split("-");
      daysInMonth = new Date(year, month, 0).getDate();
    }

    return daysInMonth;
  },
};
