<template>
  <div class="modal-mask" v-if="modal">
    <div class="modal-wrapper">
      <div class="bounce-in bg-loading">
        <div class="bg-loading-icon"></div>
        <loading-component :loading="true" />
      </div>
    </div>
  </div>
</template>

<script>
import LoadingComponent from "@/components/LoadingComponent.vue";

export default {
  components: {
    LoadingComponent,
  },

  data() {
    return {
      modal: false,
    };
  },

  methods: {
    open() {
      this.modal = true;
    },

    close() {
      this.modal = false;
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss">
.bg-loading {
  margin: 0 auto;
  width: 8rem;
  height: 8rem;
  border-radius: 50%;

  .bg-loading-icon {
    width: 8rem;
    height: 8rem;
    border-radius: 50%;
    position: absolute;
    border: 5px solid white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
