<template>
  <div
    class="request-data-info h-100"
    v-if="isGetStaffUploadDocumentDone"
  >
    <div v-if="requestData.length">
      <div class="bg-grey-light-3 text-color-grey fs-16 mt-8 pa-4 border-8">
        <p v-html="$t('have request')" />
        <p v-html="$t('confirm and next')" />
      </div>

      <div
        v-for="(item, i) in requestData"
        :key="i"
        class="bg-grey-light-3 fs-16 py-4 border-8 my-8 itemData"
      >
        <div class="index">{{ ++i }}</div>
        <div class="d-flex px-4">
          <p class="text-color-grey fs-14" v-html="$t('kind')"></p>
          <p
            v-if="item.typeTrans"
            class="text-white-smoke fs-14 fw-500 pl-3"
            v-html="$t(item.typeTrans)"
          ></p>
        </div>

        <div class="border-bottom-white-grey"></div>

        <div class="px-4">
          <p class="text-color-grey fs-14 py-4" v-html="$t('reason')"></p>
          <p
            v-if="item.referenceTrans"
            class="text-white-smoke fw-500"
            v-html="
              fixCustomizedItemReason($t(item.referenceTrans), item.typeTrans)
            "
          ></p>
        </div>
      </div>

      <div class="pt-3 pb-16 text-center">
        <button
          class="btn btn-danger w-12 fs-16 border-8 h-4"
          @click="$emit('changeComponent', 'UploadPhoto')"
        >
          <p v-html="$t('next')" />
        </button>
      </div>
    </div>

    <div
      v-else-if="messageAlreadySubmitted"
      class="d-flex flex-column justify-center align-center h-100"
    >
      <img class="w-10" :src="require(`@/assets/img/success.svg`)" />
      <p
        class="text-white-smoke font-weight-bold mt-4"
        v-html="$t(messageAlreadySubmitted)"
      />
    </div>
    <div
      v-else-if="messageError"
      class="d-flex flex-column justify-center align-center h-100"
    >
      <img class="w-10" :src="require(`@/assets/img/alert.svg`)" />
      <p class="text-danger font-weight-bold mt-4" v-html="$t(messageError)" />
    </div>
  </div>
</template>

<script>
import StaffService from "@/services/staff.service.js";

export default {
  name: "RequestDataInfo",

  data() {
    return {
      idStaff: this.$route.query.idStaff,
      requestData: [],
      messageAlreadySubmitted: null,
      messageError: null,
      isGetStaffUploadDocumentDone: false,
    };
  },

  created() {
    this.onGetStaffUploadDocument();
  },

  methods: {
    onGetStaffUploadDocument() {
      const params = {
        idStaffHash: this.idStaff,
      };
      StaffService.getStaffUploadDocument(params)
        .then((res) => {
          if (res.message && res.message.content) {
            this.messageAlreadySubmitted = res.message.content;
            this.hideFooter();
            return;
          }

          this.requestData = res.data.requestData;
          this.$emit("changeStaffUploadDocument", res.data);

          const customizedItems = res.data.requestData
            .filter((o) => o.nameItem)
            .map((item) => ({
              idCompanySetting: item.idCustomizedItem,
              nameItem: item.nameItem,
              valueItem: null,
            }));

          this.$emit("changeRequestData", { customizedItems: customizedItems });
        })
        .catch((err) => {
            this.messageError = err.message ? err.message.content : "not found";
            this.hideFooter();
        })
        .finally(() => {
          this.isGetStaffUploadDocumentDone = true;
        });
    },

    fixCustomizedItemReason(base, item) {
      if (base.match(/#customized_item#/)) {
        return base.replaceAll("#customized_item#", item);
      }

      return base;
    },

    //hide footer
    hideFooter() {
      document.querySelector("#footer").className = "d-none";
    },
  },
};
</script>

<style scoped>
.index {
  background-color: white;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: red;
  text-align: center;
  box-shadow: 2px 4px 9px 1px rgb(213 213 213);
  position: absolute;
  top: -15px;
  left: 50%;
  transform: translateX(-50%);
  font-weight: bold;
}
.itemData {
  position: relative;
}
</style>
