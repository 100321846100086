import ApiService from "@/common/api.service";
import { APIS } from "@/common/api.constants";

export default {
  getCompanyInfo(params) {
    return ApiService.get(APIS.GET_COMPANY_INFO, { params: params })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        // throw error and go to catch block
        throw error.response.data;
      });
  },

  updateIsAccessableApp(data) {
    let formData = new FormData();
    formData.append(data.field, data.value);

    return ApiService.post(APIS.UPDATE_IS_ACCESSABLE_APP, formData)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  getCompanyInfoSetting() {
    return ApiService.get(APIS.COMPANY_INFO_SETTING)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        // throw error and go to catch block
        throw error.response.data;
      });
  },

  updateCompanyInfoSetting(payload) {
    return ApiService.post(APIS.COMPANY_INFO_SETTING, payload)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  updateCompanyPaymentSetting(payload) {
    return ApiService.post(APIS.COMPANY_PAYMENT_SETTING, payload)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  updateCompanyRequirement(payload) {
    return ApiService.post(APIS.COMPANY_REQUIREMENT, payload)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  getCompanyCustomItem() {
    return ApiService.get(APIS.COMPANY_CUSTOM_ITEM)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  updateCompanyCustomItem(payload) {
    return ApiService.post(APIS.COMPANY_CUSTOM_ITEM, payload)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  //register company
  postCompany(payload) {
    return ApiService.post(APIS.COMPANY, payload)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  // set password for new company
  setPasswordNewCompany(password, token) {
    let formData = new FormData();
    formData.append("password", password);
    formData.append("token", token);
    return ApiService.post(APIS.COMPANY_PASSWORD, formData)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  //get list company
  getCompanyList() {
    return ApiService.get(APIS.COMPANY_LIST)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  //post company select
  postCompanySelect(payload) {
    return ApiService.post(APIS.COMPANY_SELECT, payload)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

//to check whether contract expiration date has passed. (currently unused)
  getCompanyCheck(params) {
    return ApiService.get(APIS.COMPANY_CHECK, { params: params })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },
};
