<template>
  <div
    :class="[
      'filter-pulldown',
      { 'bg-white-red': redBackground && required && !data && !txtSearch },
    ]"
  >
    <div class="select" v-click-outside="onOutside">
      <input
        ref="input"
        :class="[
          'filter-select-input',
          { 'hidden-placeholder': data },
          { disabled: disabled },
        ]"
        :value="txtSearch"
        :disabled="disabled"
        @focus="onFocus"
        @input="txtSearch = $event.target.value"
      />
      <p class="item-value" v-if="txtSearch === ''" @click="focus">
        {{ data }}
      </p>

      <label :class="['placeholder-label']" v-if="!data && !txtSearch">
        <span>{{ label || placeholder }}</span>
        <span v-if="required && !disabled" class="required-label">必須</span>
      </label>

      <label class="floating-label" :class="{ 'd-none': !data && !txtSearch }">
        {{ label || placeholder }}
        <span v-if="required && !disabled" class="required-label">必須</span>
      </label>

      <div class="icon-group">
        <v-icon
          v-show="(data || txtSearch !== '') && !disabled && iconClear"
          @click="onClear"
          class="icon-close"
        >
          mdi-close
        </v-icon>
        <v-icon
          v-show="!disabled"
          :class="!typing ? 'icon-down' : 'icon-up'"
          @click="onShowMenu"
        >
          mdi-menu-down
        </v-icon>
      </div>
      <div
        class="data-list"
        v-if="typing && itemsFilterData.length && itemText"
      >
        <div
          class="data"
          v-for="(item, i) in itemsFilter"
          :key="i"
          @click="selectData(item)"
        >
          {{ item[itemText] }}
        </div>
      </div>
      <div class="data-list" v-if="noResult">
        <div class="no-result">{{ noDataText }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    itemText: {
      type: String,
      required: true,
      default: "",
    },
    itemValue: {
      type: String,
      required: true,
      default: "",
    },
    value: {
      type: [String, Number, Boolean],
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    placeholder: String,
    noDataText: {
      type: String,
      default: "データなし",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    iconClear: {
      type: Boolean,
      default: true,
    },
    redBackground: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      data: this.items.find((o) => o[this.itemValue] === this.value)
        ? this.items.find((o) => o[this.itemValue] === this.value)[
            this.itemText
          ]
        : null,
      txtSearch: "",
      typing: false,
      itemsFilterData: [],
    };
  },

  watch: {
    typing: function (val) {
      if (!val) {
        this.txtSearch = "";
      }
    },
  },

  computed: {
    itemsFilter() {
      return this.itemText
        ? this.txtSearch
          ? this.searchItemArray(
              this.itemsFilterData,
              this.itemText,
              this.txtSearch
            )
          : this.itemsFilterData
        : [];
    },
    noResult() {
      return this.itemsFilter.length || !this.typing ? false : true;
    },
  },

  created() {
    this.itemsFilterData = this.filterItemNotValue(this.items, this.itemText);
  },

  methods: {
    filterItemNotValue(items = [], value) {
      return items.filter((item) => item[value] != null);
    },

    searchItemArray(items = [], itemText, value) {
      return items.filter((item) =>
        item[itemText]
          .toString()
          .toLowerCase()
          .includes(value.toString().toLowerCase())
      );
    },

    selectData(item) {
      this.data = item[this.itemText];
      let selectedItemValue = item[this.itemValue];
      this.typing = false;

      let objSelected;
      for (let i = 0; i < this.items.length; i++) {
        let item = this.items[i];
        let itemValue = item[this.itemValue];
        if (selectedItemValue === itemValue) {
          objSelected = item;
          break;
        }
      }

      if (objSelected) {
        this.$emit("selected", objSelected[this.itemValue]);
      }
      this.txtSearch = "";
    },

    onOutside() {
      this.typing = false;
    },

    onClear() {
      this.data = null;
      this.txtSearch = "";
      this.typing = false;
      this.$emit("selected", null);
    },

    onFocus() {
      this.typing = true;
    },

    focus() {
      this.$refs.input.focus();
    },

    onShowMenu() {
      this.typing = !this.typing;
    },
  },
};
</script>
