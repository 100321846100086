<template>
  <div class="modal-mask" v-if="modal">
    <div class="modal-wrapper">
      <div
        class="modal-container container bounce-in"
        :style="{ 'max-width': maxWidth + 'px' }"
        v-click-outside="isClickOutside && close"
      >
        <div class="modal-header" v-if="header">
          <div
            class="modal-header-data"
            v-if="isNotEmpty(modalData) && modalData.message"
            v-html="modalData.message.header"
          ></div>

          <slot name="header"></slot>

          <v-spacer />
          <v-icon title="close" @click="close" v-if="iconClose"
            >mdi-close</v-icon
          >
        </div>

        <div class="modal-body">
          <div class="modal-body-data" v-if="isNotEmpty(modalData)">
            <div class="modal-body__img">
              <img
                v-if="modalData.result === 'ok'"
                class="w-10 success"
                :src="require(`@/assets/img/success.svg`)"
              />
              <img
                v-else-if="
                  modalData.result === 'error' || modalData.result === 'warning'
                "
                class="w-10"
                :src="require(`@/assets/img/alert.svg`)"
              />
            </div>
            <div
              class="modal-body__title"
              :class="{
                'modal-body__title--danger': modalData.result == 'error',
              }"
              v-if="modalData.message"
              v-html="modalData.message.title"
            ></div>
            <div
              class="modal-body__content"
              v-if="modalData.message"
              v-html="modalData.message.content"
            ></div>
          </div>

          <slot name="body"></slot>
        </div>

        <div class="modal-footer" v-if="footer">
          <div class="modal-footer-data" v-if="isNotEmpty(modalData)">
            <div v-if="modalData.button && modalData.button.length">
              <v-btn
                v-for="(btn, i) in modalData.button"
                :key="i"
                class="border-8"
                variant="outlined"
                :color="btn.color"
                @click="$emit(btn.action)"
              >
                {{ btn.text || "値なし" }}
              </v-btn>
            </div>
            <div v-else>
              <!-- deep link用のボタン。@clickで条件分岐が利かなかったため、処理を分離した -->
              <v-btn
                v-if="isDeepLink"
                class="close-modal border-8"
                variant="outlined"
                :color="colorConst.SET"
                @click="openDeepLink"
              >
                閉じる
              </v-btn>
              <v-btn
                v-if="defaultCloseBtn && !isDeepLink"
                class="close-modal border-8"
                variant="outlined"
                :color="colorConst.SET"
                @click="close"
              >
                閉じる
              </v-btn>
            </div>
          </div>

          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BUTTON_COLOR } from "@/common/constants";
export default {
  props: {
    modalData: {
      type: Object,
    },

    maxWidth: {
      type: Number,
      default: 700,
    },

    defaultCloseBtn: {
      type: Boolean,
      default: true,
    },

    iconClose: {
      type: Boolean,
      default: true,
    },

    isClickOutside: {
      type: Boolean,
      default: true,
    },

    isDeepLink: {
      type: Boolean,
      default: false,
    },

    header: {
      type: Boolean,
      default: true,
    },

    footer: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      modal: false,
      colorConst: BUTTON_COLOR,
    };
  },

  methods: {
    open() {
      this.modal = true;
    },

    close() {
      this.modal = false;
      this.$emit("close");
    },

    openDeepLink() {
      window.open("visamane://deep?page=readcard");
    },

    isNotEmpty(obj) {
      return obj && Object.keys(obj).length ? true : false;
    },
  },
};
</script>
