// debounce delay timeout
export function debounce(fn, delay) {
  let timeoutID = null;
  return function () {
    clearTimeout(timeoutID);
    timeoutID = setTimeout(() => {
      fn.apply(this, arguments);
    }, delay);
  };
}

//Handle option params to string format to request API
export function handleOptionParams(keyParams, field, value) {
  let keyParamsArr = keyParams ? keyParams.split(",") : [];

  keyParamsArr = value
    ? keyParamsArr.concat(field.split(","))
    : keyParamsArr.filter((o) => !field.split(",").includes(o));

  return keyParamsArr.length ? keyParamsArr.toString() : null;
}

export function convertDateNotTimeZone(str) {
  var months = {
      Jan: "01",
      Feb: "02",
      Mar: "03",
      Apr: "04",
      May: "05",
      Jun: "06",
      Jul: "07",
      Aug: "08",
      Sep: "09",
      Oct: "10",
      Nov: "11",
      Dec: "12",
    },
    date = str.split(" ");

  return [date[3], months[date[1]], date[2]].join("-");
}
