import ApiService from "@/common/api.service";
import { APIS } from "@/common/api.constants";

export default {
  getStaffInputStatusList(params) {
    return ApiService.get(`${APIS.STAFF_INPUTSTATUS_LIST}`, {
      params: params,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  // get staff list
  getStaffList(params) {
    return ApiService.get(`${APIS.STAFF_LIST}`, {
      params: params,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  // get staff csv format list
  getCSVFormatList(params) {
    return ApiService.get(`${APIS.STAFF_CSV_FORMAT}`, { params: params })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  // copy staff csv format
  copyCSVFormat(payloads) {
    return ApiService.post(`${APIS.STAFF_CSV_FORMAT}`, payloads)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  // delete staff csv format
  deleteCSVFormat(id) {
    return ApiService.delete(`${APIS.STAFF_CSV_FORMAT}`, { params: { id } })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  //get staff update target
  getStaffUpdateTargetList(params) {
    return ApiService.get(`${APIS.STAFF_UPDATETARGET_LIST}`, {
      params: params,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  //export file csv staff
  exportStaffCsvFile(params) {
    return ApiService.get(APIS.STAFF_CSV, { params: params })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  //import file csv staff
  importStaffCsvFile(formData) {
    return ApiService.post(APIS.STAFF_CSV, formData)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  //export file csv staff specify
  exportStaffCsvSpecifyFile(formData) {
    return ApiService.post(APIS.STAFF_CSV_SPECIFY, formData)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  // get csv format detail
  getCsvFormatDetail(params) {
    return ApiService.get(
      `${APIS.CSV_FORMAT_DETAIL}`
        .replace("{formatKind}", params.formatKind)
        .replace("{id}", params.id)
    )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  // create or update csv format
  createOrUpdateCSVFormat(payloads) {
    return ApiService.post(`${APIS.STAFF_CSV_FORMAT}`, payloads)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  //get staff detail
  getStaffData(idStaff) {
    return ApiService.get(APIS.STAFF_DETAIL.replace("{idStaff}", idStaff))
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  getStaffPostCode(params) {
    return ApiService.get(`${APIS.STAFF_POST_CODE}`, {
      params: params,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  // save staff detail
  postStaffDetail(idStaff, payload) {
    return ApiService.post(
      APIS.STAFF_DETAIL.replace("{idStaff}", idStaff),
      payload
    )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  postSendRemindExpiringMail(idStaff) {
    return ApiService.post(
      APIS.STAFF_REMIND_EXPIRING_MAIL.replace("{idStaff}", idStaff)
    )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  //get staff alert confirm
  staffAlertConfirm(payload) {
    return ApiService.post(`${APIS.STAFF_ALERT_CONFIRM}`, payload)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  //get staff request
  getStaffRequest(idStaff) {
    return ApiService.get(APIS.STAFF_REQUEST.replace("{idStaff}", idStaff))
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  // delete staff list
  deleteStaffDetail(idStaff) {
    return ApiService.delete(
      `${APIS.STAFF_DETAIL}`.replace("{idStaff}", idStaff)
    )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  //get staff request
  postStaffRequest(idStaff, payload) {
    return ApiService.post(
      APIS.STAFF_REQUEST.replace("{idStaff}", idStaff),
      payload
    )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  //read image ocr
  getStaffOcr(idStaff) {
    return ApiService.get(APIS.STAFF_OCR.replace("{idStaff}", idStaff))
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  // download staff pledge
  downloadStaffPledge(idStaff) {
    return ApiService.get(APIS.STAFF_PLEDGE.replace("{idStaff}", idStaff), {
      responseType: "blob",
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  //get staff alert confirm
  postStaffEtc(idStaff, payload) {
    return ApiService.post(
      APIS.STAFF_ETC.replace("{idStaff}", idStaff),
      payload
    )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  // post staff vacation
  postStaffVacation(idStaff, payload) {
    return ApiService.post(
      `${APIS.STAFF_VACATION}`.replace("{idStaff}", idStaff),
      payload
    )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  //get staff detail log residence
  getStaffLogResidence(idStaff) {
    return ApiService.get(
      APIS.STAFF_LOG_RESIDENCE.replace("{idStaff}", idStaff)
    )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  // get staff detail log
  getStaffLog(idStaff, logType) {
    return ApiService.get(
      APIS.STAFF_LOG.replace("{idStaff}", idStaff).replace("{logType}", logType)
    )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  // get staff detail log
  getStaffLogParams(idStaff, logType, params) {
    return ApiService.get(
      APIS.STAFF_LOG.replace("{idStaff}", idStaff).replace(
        "{logType}",
        logType
      ),
      { params: params }
    )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  // get data residence card approval
  getResidenceCardApproval(idStaff) {
    return ApiService.get(
      `${APIS.STAFF_RESIDENCE_CARD_APPROVAL}`.replace("{idStaff}", idStaff)
    )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  // post residence card approval
  postResidenceCardApproval(idStaff, mechanismType) {
    return ApiService.post(
      `${APIS.STAFF_RESIDENCE_CARD_APPROVAL}`.replace("{idStaff}", idStaff),
      { mechanismType }
    )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  // get data residence card reject
  getResidenceCardReject(idStaff) {
    return ApiService.get(
      `${APIS.STAFF_RESIDENCE_CARD_REJECT}`.replace("{idStaff}", idStaff)
    )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  // send residence card reject reason
  postResidenceCardRejectReason(idStaff, rejectReason) {
    return ApiService.post(
      `${APIS.STAFF_RESIDENCE_CARD_REJECT}`.replace("{idStaff}", idStaff),
      { rejectReason }
    )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  // verify info staff
  getStaffVerify(data) {
    return ApiService.get(APIS.STAFF_VERIFY, { params: data })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  getIsStaffLimit() {
    return ApiService.get(APIS.STAFF_LIMIT)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  // submit residence card
  postResidenceCard(idStaff, data) {
    return ApiService.post(
      APIS.STAFF_UPLOAD_RESIDENCE_CARD.replace("{idStaff}", idStaff),
      data
    )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  // check residence card number for register
  checkResidenceCardNumber(payload) {
    return ApiService.post(APIS.STAFF_CHECK_RESIDENCE_CARD_NUMBER, payload)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  // register staff from residence card
  registerStaffFromResidenceCard(cardId, payload) {
    return ApiService.post(
      APIS.STAFF_FROM_RESIDENCE_CARD.replace("{cardId}", cardId),
      payload
    )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  // post register residence card
  postStaffResidenceCard(payload) {
    return ApiService.post(APIS.STAFF, payload)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  // get data staff upload document
  getStaffUploadDocument(params) {
    return ApiService.get(APIS.STAFF_UPLOAD_DOCUMENT, { params: params })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  // post staff upload document
  postStaffUploadDocument(payload) {
    return ApiService.post(APIS.STAFF_UPLOAD_DOCUMENT, payload)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  // check exist email staff
  checkExistEmail(email) {
    return ApiService.post(APIS.STAFF_CHECK_EXIST_EMAIL, { email })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },
};
