const state = () => ({
  paramsElectronic: {
    idOffice: null,
    idPrefecture: null,
    deadline: null,
    onlyExpired: false,
    size: 30,
    page: 0,
  },

  paramsPaper: {
    idHelloWork: null,
    idPrefecture: null,
    deadline: null,
    onlyExpired: false,
    size: 30,
    page: 0,
  },
});

const mutations = {
  setStateParamsElectronic(state, data) {
    state.paramsElectronic = {
      ...state.paramsElectronic,
      ...data,
    };
  },

  setStateParamsPaper(state, data) {
    state.paramsPaper = {
      ...state.paramsPaper,
      ...data,
    };
  },

  resetStateParamsElectronic(state) {
    state.paramsElectronic = {
    idOffice: null,
    idPrefecture: null,
    deadline: null,
    onlyExpired: false,
    size: 30,
    page: 0,
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
};
