<template>
  <div class="reset-password" v-if="!isResetPasswordSuccess">
    <h1 class="title text-center mb-3">パスワード再設定</h1>
    <p class="text-white-smoke mb-8">
      ご登録されているメールアドレスにパスワード再設定のご案内を送信します。
      <br />
      送信後、１時間以内に再設定のお手続きを行ってください。
    </p>
    <div class="email-reset mb-5">
      <InputComponent
        type="email"
        :value="email"
        placeholder="ご登録しているメールアドレス"
        @handleChange="email = $event"
      />
    </div>
    <div class="text-center mt-10">
      <button
        :class="[
          'btn fs-16 border-8',
          { disabled: !utilFunc.validateEmail(email) },
        ]"
        @click="utilFunc.validateEmail(email) ? onResetPasswordMail() : ''"
      >
        送信する
      </button>
    </div>
  </div>

  <div class="reset-password-success" v-if="isResetPasswordSuccess">
    <div>
      <img class="w-12" :src="require('@/assets/img/email.png')" />
    </div>
    <h1 class="title text-left mb-8">
      ご登録のメールアドレスに <br />
      パスワード再設定のご案内を <br />
      送信いたしました。
    </h1>
    <p class="text-white-smoke mr-auto">
      メールが届かない場合、以下の点をご確認ください
    </p>
    <div class="questions text-white-smoke mr-auto">
      <p>① 入力したメールアドレスに誤りがないか？</p>
      <p>② 迷惑フォルダに振り分けられていないか？</p>
      <p>
        ③
        <span class="font-weight-bold">info@visamane.jp</span
        >からのメールがブロックされていないか？
      </p>
    </div>
  </div>

  <div class="link" v-if="isResetPasswordSuccess">
    <router-link :to="{ name: 'login' }"> ログインページに戻る </router-link>
  </div>

  <modal-component
    ref="resultResetPasswordMail"
    :modal-data="resultResetPasswordMail"
    :max-width="500"
  />
</template>

<script>
import InputComponent from "@/components/InputComponent.vue";
import AdminService from "@/services/admin.service.js";
import utilFunc from "@/common/utilFunc.js";

export default {
  name: "ResetPasswordMail",

  components: {
    InputComponent,
  },

  data() {
    return {
      email: "",
      resultResetPasswordMail: null,
      isResetPasswordSuccess: false,
      utilFunc: utilFunc,
    };
  },

  methods: {
    onResetPasswordMail() {
      let formData = new FormData();
      formData.append("email", this.email);

      AdminService.resetPasswordMail(formData)
        .then(() => {
          this.isResetPasswordSuccess = true;
        })
        .catch((err) => {
          this.resultResetPasswordMail = err;
          this.$refs.resultResetPasswordMail.open();
        });
    },
  },
};
</script>
