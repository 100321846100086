<template>
  <div class="submit-residence-card mt-3">
    <div class="text-danger fs-12">
      <a class="btn-back" @click="$emit('changeComponent', 'BasicInfo')">
        <v-icon class="mb-1 mr-n2 mt-1">mdi-menu-left</v-icon>
        <span class="ml-n2" v-html="$t('previous screen')" />
      </a>
    </div>

    <div class="text-center border-8">
      <img
        :src="require(`@/assets/img/residenceCardFront_demo.png`)"
        style="width: 270px"
      />
    </div>

    <div
      class="
        bg-grey-light-3
        fs-16
        text-white-smoke text-center
        py-7
        px-5
        border-8
      "
    >
      <p v-html="$t('tell me your visa status')" />
    </div>

    <div class="mt-5">
      <pulldown-component
        :items="visaTypeList"
        item-text="name"
        item-value="value"
        :required="true"
        :squareLabeled="true"
        :label="$t('visa status')"
        :value="visaTypeRegister"
        :key="$i18n.locale"
        :multiLanguage="true"
        @change="visaTypeRegister = $event"
      />
    </div>

    <div class="mt-8 text-center">
      <button
        :class="[
          'btn w-12 fs-16',
          visaTypeRegister ? ' btn-danger' : 'button-disabled',
          { 'h-4': $i18n.locale == 'ja' },
        ]"
        :disabled="!visaTypeRegister"
        @click="next"
      >
        <p v-html="$t('next')"></p>
      </button>
    </div>
  </div>
</template>

<script>
import { VISA_TYPE_LIST, REQUIRED_ITEM } from "@/common/constants.js";

import PulldownComponent from "@/components/forms/PulldownComponent.vue";

export default {
  name: "VisaStatus",

  props: ["shopStaffAddInfo"],

  components: {
    PulldownComponent,
  },

  data() {
    return {
      visaTypeRegister: null,
      visaTypeList: VISA_TYPE_LIST,
    };
  },

  methods: {
    next() {
      this.$emit("changeResidenceCard", {
        visaTypeRegister: this.visaTypeRegister,
      });

      if (
        this.visaTypeRegister === "Student" &&
        (this.shopStaffAddInfo.graduationDateRequirement !==
          REQUIRED_ITEM.HIDDEN ||
          this.shopStaffAddInfo.studentCardExpiredDateRequirement !==
            REQUIRED_ITEM.HIDDEN)
      ) {
        this.$emit("changeComponent", "GradDate");
        return;
      }

      this.$emit("changeComponent", "TakePhoto");
    },
  },
};
</script>
