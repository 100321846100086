<template>
  <div class="confirm-info mt-5" v-if="requestData">
    <div
      class="
        bg-grey-light-3
        fs-16
        text-white-smoke text-center
        py-5
        px-4
        border-8
      "
    >
      <p v-html="$t('clear and readable')" />
    </div>

    <div
      class="mt-5"
      v-for="(value, key) in removeEmptyValue(imageUrlLocal)"
      :key="key"
    >
      <p
        v-if="key != 'signatureURL'"
        v-html="$t(getImageName(key))"
        class="pb-4 text-white-smoke"
      />
      <image-preview-component
        v-if="key != 'signatureURL'"
        :url="value"
        :height="213"
        :isMobile="true"
      />
    </div>

    <div
      class="mt-5"
      v-for="(value, key) in removeEmptyValue(imageUrlLocal)"
      :key="key"
    >
      <p
        v-if="key == 'signatureURL' && value"
        v-html="$t('signature')"
        class="pb-4 text-white-smoke"
      />
      <image-preview-component
        v-if="key == 'signatureURL' && value"
        :url="value"
        :height="213"
        :isMobile="true"
      />
    </div>

    <div class="mt-6 d-flex justify-center">
      <button
        class="btn button-danger w-12 fs-16 mr-3 border-8"
        @click="
          removeEmptyValue(imageUrlLocal).hasOwnProperty(
            'residenceCardFrontURL'
          )
            ? $emit('changeComponent', 'Signature')
            : $emit('changeComponent', 'UploadPhoto')
        "
      >
        <p v-html="$t('back')" />
      </button>
      <button
        class="btn w-12 fs-16 border-8"
        :class="
          Object.keys(removeEmptyValue(requestData)).length
            ? ' btn-danger'
            : 'button-disabled'
        "
        :disabled="!Object.keys(removeEmptyValue(requestData)).length"
        @click="submitStaffUploadDocument"
      >
        <p v-html="$t('submit')" />
      </button>
    </div>

    <modal-component
      :modalData="modalData"
      ref="resultModal"
      @close="
        isSubmitSuccess &&
          ($emit('changeComponent', 'RequestDataInfo'),
          $emit('resetRequestUploadDocument'))
      "
    />

    <ModalLoadingComponent ref="loadingModal" />
  </div>
</template>

<script>
import StaffService from "@/services/staff.service";
import ImagePreviewComponent from "@/components/ImagePreviewComponent.vue";
import ModalLoadingComponent from "@/components/ModalLoadingComponent.vue";

export default {
  name: "ConfirmInfo",

  components: {
    ImagePreviewComponent,
    ModalLoadingComponent,
  },

  props: ["requestData", "imageUrlLocal"],

  data() {
    return {
      idStaff: this.$route.query.idStaff,
      isSubmitSuccess: false,
      modalData: null,
    };
  },

  created() {
    this.removeCustomizedItems(this.requestData.customizedItems);
  },

  methods: {
    submitStaffUploadDocument() {
      this.$refs.loadingModal.open();

      const payload = {
        ...this.removeEmptyValue(this.requestData),
        ...{
          idStaffHash: this.idStaff,
        },
      };

      StaffService.postStaffUploadDocument(payload)
        .then((res) => {
          this.isSubmitSuccess = true;
          this.modalData = {
            result: res.result,
            message: {
              header: this.$t(res.message.header),
              title: this.$t(res.message.title),
              content: this.$t(res.message.content),
            },
          };
        })
        .catch((err) => {
          this.isSubmitSuccess = false;
          this.modalData = {
            result: err.result,
            message: {
              header: this.$t(err.message.header),
              title: this.$t(err.message.title),
              content: this.$t(err.message.content),
            },
          };
        })
        .finally(() => {
          this.$refs.loadingModal.close();
          this.$refs.resultModal.open();
        });
    },

    //Remove empty values in params object
    removeEmptyValue(object) {
      return Object.entries(object).reduce(
        (a, [k, v]) => (v == null || v == "" || v == [] ? a : ((a[k] = v), a)),
        {}
      );
    },

    //remove customized item have not valueItem
    removeCustomizedItems(customizedItems) {
      customizedItems.forEach((element) => {
        if (!element.valueItem) {
          customizedItems.splice(customizedItems.indexOf(element), 1);
        }
      });
    },

    getImageName(url) {
      switch (url) {
        case "residenceCardFrontURL":
          return "residence card frontside";
        case "residenceCardBackURL":
          return "residence card backside";
        case "workDesignationURL":
          return "designation";
        case "passportURL":
          return "passport";
        case "studentCardURL":
          return "student ID";
        case "signatureURL":
          return "signature";
        case "certificateLongVacationURL":
          return "certificate of long vacation";
        default:
          return url.substr(0, url.length - 3);
      }
    },
  },
};
</script>
