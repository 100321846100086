<template>
  <div class="mt-8">
    <div class="text-danger fs-12">
      <a class="btn-back" @click="$emit('changeComponent', 'Inquiry')">
        <v-icon class="mb-1 mr-n2 mt-1">mdi-menu-left</v-icon>
        <span class="ml-n2" v-html="$t('previous screen')" />
      </a>
    </div>
    <div class="text-center border-8">
      <div class="residence-card-front">
        <img :src="require(`@/assets/img/residence_card_front.svg`)" />
      </div>
    </div>

    <div
      class="bg-grey-light-3 fs-16 text-white-smoke text-center pa-4 border-8"
    >
      <!-- 在留カードを今持っていますか？ -->
      <span v-html="$t('carry residence card?')" />
    </div>

    <div class="py-7 text-center">
      <button
        class="btn w-25 fs-16 h-4 border-8 mr-4"
        v-bind:class="{
          'button-bg-white': !isClickYes,
          'button-danger': isClickYes,
        }"
        @click="btnClick('yes')"
      >
        <div class="text-left ml-2">
          <input type="radio" name="yesNo" value="yes" :checked="isClickYes" />
          <label class="radio-label cursor-pointer">
            <span class="button-text" v-html="$t('yes')" />
          </label>
        </div>
      </button>

      <button
        class="btn w-25 fs-16 border-8 mr-4 mt-2"
        v-bind:class="{
          'button-bg-white': !isClickNo,
          'button-danger': isClickNo,
        }"
        @click="btnClick('no')"
      >
        <div class="text-left ml-2">
          <input type="radio" name="yesNo" value="no" :checked="isClickNo" />
          <label class="radio-label cursor-pointer">
            <span class="button-text" v-html="$t('no')" />
          </label>
        </div>
      </button>

      <button
        :class="[
          'btn w-12 fs-16 mt-6',
          isClickYes || isClickNo ? 'btn-danger' : 'button-disabled',
          { 'h-4': $i18n.locale == 'ja' },
        ]"
        @click="nextAction()"
      >
        <p v-html="$t('next')"></p>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "ConfirmCard",

  data() {
    return {
      currentScreen: "ConfirmCardFront",
      isClickYes: false,
      isClickNo: false,
    };
  },

  methods: {
    btnClick(clickedType) {
      if (clickedType === "yes") {
        this.isClickYes = true;
        this.isClickNo = false;
      } else {
        this.isClickYes = false;
        this.isClickNo = true;
      }
    },
    nextAction() {
      if (this.isClickNo) {
        this.$emit("changeComponent", "CardWithAnyone");
      }

      if (this.isClickYes) {
        this.$emit("changeComponent", "VisaStatus");
      }

      this.isClickYes = false;
      this.isClickNo = false;
    },
  },
};
</script>

<style lang="scss">
.residence-card-front {
  width: 27rem;
  height: 18rem;
  overflow: hidden;
  margin: auto;
  position: relative;

  img {
    position: absolute;
    width: 41.5rem;
    left: 50%;
    transform: translateX(-50%);
  }
}

.button-text {
  color: #4a576e;
}
</style>
