<template>
  <div class="corporate-information">
    <p class="text-white-smoke fs-14 pb-2 pt-3">
      登録者様の情報を教えてください。<span
        style="color: #f50057; font-weight: bold"
      >
        ※必須</span
      >
    </p>
    <p class="text-white-smoke fs-16 text-bold" style="line-height: 4">
      ログイン情報
    </p>
    <div class="v-row">
      <div class="v-col-12">
        <input-component
          placeholder="メールアドレス"
          :required="true"
          :value="corporateInformation.emailAdmin"
          :error="errorEmailAdmin"
          :redBackground="false"
          @handle-change="changeEmailAdmin"
        />
      </div>

      <div class="reset-password-page">
        <div class="form-reset-100">
          <div class="v-col-12">
            <InputComponent
              placeholder="パスワード"
              type="text"
              :required="true"
              :value="corporateInformation.newPassword"
              :redBackground="false"
              :eyeIcon="true"
              @handleChange="changePassword"
            />
          </div>

          <div class="validate-password">
            <p :class="['validate-item', { 'active-valid': minLength }]">
              <v-icon x-small>mdi-checkbox-marked-circle-outline</v-icon>
              ８文字以上を含む
            </p>
            <p :class="['validate-item', { 'active-valid': validCharacters }]">
              <v-icon x-small>mdi-checkbox-marked-circle-outline</v-icon>
              英大文字と英子文字を含む
            </p>
            <p
              :class="['validate-item', { 'active-valid': specialCharacters }]"
            >
              <v-icon x-small>mdi-checkbox-marked-circle-outline</v-icon>
              記号(!#$%&'()*+,-./:;&lt;=&gt;?@[]^_`{|}~）")を含む
            </p>
            <p :class="['validate-item', { 'active-valid': includeNumber }]">
              <v-icon x-small>mdi-checkbox-marked-circle-outline</v-icon>
              数字を含む
            </p>
          </div>

          <div class="v-col-12">
            <InputComponent
              placeholder="パスワード(確認用)"
              type="text"
              :eyeIcon="true"
              :value="corporateInformation.confirmPassword"
              @handleChange="changeConfirmPassword"
            />
            <div class="error-input" v-if="error !== ''">
              <p v-html="this.errorConfirmPassword" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <p class="text-white-smoke fs-16 text-bold" style="line-height: 4">
      企業/担当者情報
    </p>

    <div class="v-row">
      <div class="v-col-12">
        <input-component
          placeholder="企業名"
          :required="true"
          :value="corporateInformation.nameCompany"
          :redBackground="false"
          @handle-change="corporateInformation.nameCompany = $event"
        />
      </div>
      <div class="v-col-12">
        <input-component
          placeholder="担当者名"
          :required="true"
          :value="corporateInformation.nameAdmin"
          :redBackground="false"
          @handle-change="corporateInformation.nameAdmin = $event"
        />
      </div>
      <div class="v-col-12">
        <input-component
          placeholder="担当者電話番号"
          :required="true"
          :value="corporateInformation.telChargePerson"
          :redBackground="false"
          @handle-change="corporateInformation.telChargePerson = $event"
        />
      </div>

      <div class="w-100 d-flex flex-wrap">
        <div class="v-col-8">
          <input-component
            placeholder="本社郵便番号"
            :required="true"
            :value="corporateInformation.postalCompany"
            :error="errorPostalCompany"
            :redBackground="false"
            @handle-change="changePostalCompany"
          />
        </div>
        <div class="v-col-4">
          <div
            class="d-flex justify-center align-center h-4 fs-16 cursor-pointer"
            :class="
              corporateInformation.postalCompany &&
              !errorPostalCompany &&
              !requestZipCloud
                ? 'button-danger'
                : 'button-disabled'
            "
            @click="
              corporateInformation.postalCompany &&
                !errorPostalCompany &&
                !requestZipCloud &&
                onGetZipCloud(corporateInformation.postalCompany)
            "
          >
            住所検索
          </div>
        </div>
        <div class="v-col-12">
          <input-component
            placeholder="本社住所地"
            :required="true"
            :value="corporateInformation.addressCompany"
            :error="errorConfirmAddress"
            :redBackground="false"
            @handle-change="changeAddress"
          />
        </div>
      </div>

      <div class="v-col-12">
        <p class="text-white-smoke fs-14 pb-2 pt-3">
          雇用中の外国籍の従業員数を教えてください。
        </p>
        <div class="v-row">
          <div class="v-col-12">
            <page-list-filter-pulldown-component
              :items="foreignerAboutList"
              item-text="name"
              item-value="value"
              label="雇用中の外国籍の従業員数"
              :required="true"
              :value="corporateInformation.foreignerAbout"
              :redBackground="false"
              @selected="corporateInformation.foreignerAbout = $event"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="text-center mt-15">
      <button
        :class="activeNext ? 'btn-danger' : 'disabled'"
        :disabled="!activeNext"
        class="btn h-36px w-22 fs-16"
        @click="next"
      >
        次へ
      </button>
    </div>
  </div>
</template>

<script>
import EtcService from "@/services/etc.service.js";
import AdminService from "@/services/admin.service.js";
import InputComponent from "@/components/InputComponent.vue";
import PageListFilterPulldownComponent from "@/components/forms/PageListFilterPulldownComponent.vue";
//import CheckboxButtonComponent from "@/components/forms/CheckboxButtonComponent.vue";
import { REGEX_ZIP_CODE } from "@/common/constants.js";
import utilFunc from "@/common/utilFunc.js";
import {
  MIN_LENGTH_PASSWORD,
  REGEX_UPPERCASE_LOWERCASE,
  REGEX_ONE_NUMBER,
  REGEX_ONE_SPECIAL_CHAR,
} from "@/common/constants.js";

export default {
  name: "CorporateInformation",

  components: {
    InputComponent,
    PageListFilterPulldownComponent,
    //CheckboxButtonComponent,
  },

  data() {
    return {
      foreignerAboutList: [
        { name: "0名（これから採用予定）", value: "0名（これから採用予定）" },
        { name: "0名（採用予定なし）", value: "0名（採用予定なし）" },
        { name: "1〜10名", value: "1〜10名" },
        { name: "11〜30名", value: "11〜30名" },
        { name: "31〜50名", value: "31〜50名" },
        { name: "51〜100名", value: "51〜100名" },
        { name: "101〜200名", value: "101〜200名" },
        { name: "201〜500名", value: "201〜500名" },
        { name: "501〜1,000名", value: "501〜1,000名" },
        { name: "不明", value: "不明" },
      ],
      errorPostalCompany: "",
      errorEmailAdmin: "",
      errorConfirmPassword: "",
      errorConfirmAddress: "",
      errorPassword: "",

      corporateInformation: {
        nameCompany: null,
        postalCompany: null,
        addressCompany: null,
        telChargePerson: null,
        nameAdmin: null,
        emailAdmin: null,
        newPassword: null,
        confirmPassword: null,
        foreignerAbout: null,
      },

      requestZipCloud: false,
      timeOutCheckExistEmail: 0,
      addressCompanyZipcode: null,
    };
  },

  computed: {
    minLength: function () {
      return (
        this.corporateInformation.newPassword &&
        this.corporateInformation.newPassword.length >= MIN_LENGTH_PASSWORD
      );
    },

    validCharacters: function () {
      return (
        this.corporateInformation.newPassword &&
        this.corporateInformation.newPassword.match(REGEX_UPPERCASE_LOWERCASE)
      );
    },

    specialCharacters: function () {
      return (
        this.corporateInformation.newPassword &&
        this.corporateInformation.newPassword.match(REGEX_ONE_SPECIAL_CHAR)
      );
    },

    includeNumber: function () {
      return (
        this.corporateInformation.newPassword &&
        this.corporateInformation.newPassword.match(REGEX_ONE_NUMBER)
      );
    },

    activeNext() {
      const basicInfo =
        this.corporateInformation.nameCompany &&
        this.corporateInformation.nameAdmin &&
        this.corporateInformation.emailAdmin &&
        this.corporateInformation.newPassword &&
        this.corporateInformation.confirmPassword &&
        this.corporateInformation.newPassword ===
          this.corporateInformation.confirmPassword &&
        !this.errorEmailAdmin;

      const companyInfo =
        this.corporateInformation.postalCompany &&
        this.corporateInformation.addressCompany &&
        this.corporateInformation.telChargePerson &&
        this.corporateInformation.foreignerAbout &&
        this.isHaveChome("addressCompany") &&
        !this.errorPostalCompany;

      return basicInfo && companyInfo;
    },
  },

  methods: {
    onGetZipCloud(zipcode) {
      this.requestZipCloud = true;
      const params = {
        zipcode: zipcode,
      };

      EtcService.zipCloudApiSearch(params)
        .then((res) => {
          this.errorPostalCompany = res.results
            ? ""
            : "住所取得に失敗しました。再度お試しになるか、手動でご入力ください";
          this.corporateInformation.addressCompany = res.results
            ? `${res.results[0].address1}${res.results[0].address2}${res.results[0].address3}`
            : null;
          this.addressCompanyZipcode = `${res.results[0].address1}${res.results[0].address2}${res.results[0].address3}`;
          this.checkAddressInput();
        })
        .catch(() => {
          this.errorPostalCompany =
            "住所取得に失敗しました。再度お試しになるか、手動でご入力ください";
        })
        .finally(() => {
          this.requestZipCloud = false;
        });
    },

    changePostalCompany(postalCompany) {
      this.corporateInformation.postalCompany = postalCompany;

      if (postalCompany && !postalCompany.match(REGEX_ZIP_CODE)) {
        this.errorPostalCompany = "半角数字7桁の郵便番号を入力してください";
        return;
      }

      this.errorPostalCompany = "";
    },

    changeEmailAdmin(emailAdmin) {
      clearTimeout(this.timeOutCheckExistEmail);

      this.corporateInformation.emailAdmin = emailAdmin;

      if (emailAdmin && !utilFunc.validateEmail(emailAdmin)) {
        this.errorEmailAdmin = "正しいメールアドレスを入力してください";
        return;
      }

      if (emailAdmin && utilFunc.validateEmail(emailAdmin)) {
        this.timeOutCheckExistEmail = setTimeout(() => {
          this.onCheckExistEmail(emailAdmin);
        }, 1000);
      }

      this.errorEmailAdmin = "";
    },

    changePassword(newPassword) {
      this.corporateInformation.newPassword = newPassword;
      this.checkConfirmPassword(
        this.corporateInformation.newPassword,
        this.corporateInformation.confirmPassword
      );
    },

    changeConfirmPassword(confirmPassword) {
      this.corporateInformation.confirmPassword = confirmPassword;
      this.checkConfirmPassword(
        this.corporateInformation.newPassword,
        this.corporateInformation.confirmPassword
      );
    },

    changeAddress(address) {
      this.corporateInformation.addressCompany = address;
      this.checkAddressInput();
    },

    checkConfirmPassword(password1, password2) {
      if (password2 && password1 !== password2) {
        this.errorConfirmPassword =
          "パスワード(確認用)とパスワードの入力が一致していません";
      } else {
        this.errorConfirmPassword = "";
      }
    },

    onCheckExistEmail(emailAdmin) {
      AdminService.checkExistEmail(emailAdmin).then((res) => {
        if (res.data) {
          this.errorEmailAdmin = "このメールアドレスはすでに登録されています";
          return;
        }
        this.errorEmailAdmin = "";
      });
    },

    next() {
      this.$emit("changeComponent", "Confirmation");
      const basicInfo = {
        nameAdmin: this.corporateInformation.nameAdmin,
        emailAdmin: this.corporateInformation.emailAdmin,
        password: this.corporateInformation.newPassword,
      };
      const companyInfo = {
        nameCompany: this.corporateInformation.nameCompany,
        postalCompany: this.corporateInformation.postalCompany,
        addressCompany: this.corporateInformation.addressCompany,
        telChargePerson: this.corporateInformation.telChargePerson,
      };
      const employmentStatus = {
        foreignerAbout: this.corporateInformation.foreignerAbout,
      };

      const corporateInformation = Object.assign(
        basicInfo,
        companyInfo,
        employmentStatus
      );

      this.$emit("change-corporate-information", corporateInformation);
    },

    isHaveChome(name) {
      if (name == "addressCompany") {
        if (this.addressCompanyZipcode) {
          return (
            this.corporateInformation.addressCompany &&
            this.corporateInformation.addressCompany.trim() !=
              this.addressCompanyZipcode
          );
        }
        return true;
      }
    },

    checkAddressInput() {
      if (
        this.addressCompanyZipcode &&
        this.corporateInformation.addressCompany === this.addressCompanyZipcode
      ) {
        this.errorConfirmAddress = "住所入力が完了しておりません";
      } else {
        this.errorConfirmAddress = "";
      }
    },
  },
};
</script>
