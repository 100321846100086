<template>
  <div
    class="image-preview"
    :style="{ 'max-width': maxWidth + 'px', height: height + 'px' }"
  >
    <div class="image text-center" v-if="currentUrl">
      <img
        class="ma-auto"
        :src="currentUrl"
        alt=""
        :style="{
          transform: `rotate(${rotate}deg) scale(${scale}) translate(${translate}px, ${
            translate / 2
          }px)`,
        }"
        @click="showZoomExpand && openModalImage()"
      />
    </div>

    <div v-else class="no-image text-center">
      <div class="no-image-sector ma-auto">
        <img class="img-file" :src="require('@/assets/img/icon_file.svg')" />
        <br />
        <b class="text-white-smoke" v-if="placeholder">
          アップロードされているファイルはありません。
        </b>
      </div>
    </div>

    <div
      class="change-side-button"
      v-if="(frontUrl && backUrl) || changeSideButton"
      @click="changeFrontBack()"
    >
      {{ labelBtnTop }}
    </div>

    <div
      class="change-side-button ml-30"
      v-if="designationUrl && !forceActivate"
      @click="changeDesignationUrl()"
    >
      {{ labelBtnBottom }}
    </div>

    <div v-if="currentUrl">
      <div :class="isMobile ? 'expand-mobile' : 'expand'" v-if="showZoomExpand">
        <v-icon @click="openModalImage()">mdi-arrow-expand-all</v-icon>
      </div>
      <div class="rotate-right" v-if="!isMobile">
        <v-icon @click="rotate = rotate + 90">mdi-rotate-right</v-icon>
      </div>
      <div class="rotate-left" v-if="!isMobile">
        <v-icon @click="rotate = rotate - 90">mdi-rotate-left</v-icon>
      </div>
      <div class="plus" v-if="!isMobile">
        <v-icon @click="onRoomIn()">mdi-magnify-plus-outline</v-icon>
      </div>
      <div class="minus" v-if="!isMobile">
        <v-icon @click="onRoomOut()">mdi-magnify-minus-outline</v-icon>
      </div>
    </div>

    <ModalComponent ref="modalImage" :max-width="maxWidth">
      <template #header>
        <p>{{ headerModal || curenHeaderModal }}</p>
      </template>
      <template #body>
        <div class="po-re" :style="{ height: height + 'px' }">
          <div class="image">
            <img
              class="ma-auto"
              :src="currentUrl"
              alt=""
              :style="{
                transform: `rotate(${rotateModal}deg) scale(${scaleModal}) translate(${translateModal}px, ${
                  translateModal / 2
                }px)`,
              }"
            />
          </div>

          <div class="mb-1" :class="isMobile ? 'expand-mobile' : 'expand'">
            <v-icon @click="$refs.modalImage.close()">
              mdi-arrow-collapse-all
            </v-icon>
          </div>
          <div class="rotate-right mb-1" v-if="!isMobile">
            <v-icon @click="rotateModal = rotateModal + 90">
              mdi-rotate-right
            </v-icon>
          </div>
          <div class="rotate-left mb-1" v-if="!isMobile">
            <v-icon @click="rotateModal = rotateModal - 90">
              mdi-rotate-left
            </v-icon>
          </div>
          <div class="plus mb-1" v-if="!isMobile">
            <v-icon @click="onRoomInModal()"> mdi-magnify-plus-outline </v-icon>
          </div>
          <div class="minus mb-1" v-if="!isMobile">
            <v-icon @click="onRoomOutModal()">
              mdi-magnify-minus-outline
            </v-icon>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="text-center">
          <v-btn
            class="fs-14 w-18 border-8"
            variant="outlined"
            :color="colorConst.SET"
            @click="$refs.modalImage.close()"
          >
            <p>閉じる</p>
          </v-btn>
        </div>
      </template>
    </ModalComponent>
  </div>
</template>

<script>
import ModalComponent from "@/components/ModalComponent.vue";
import { BUTTON_COLOR } from "@/common/constants";

export default {
  name: "ImagePreview",

  components: {
    ModalComponent,
  },

  props: {
    maxWidth: {
      type: Number,
      default: 600,
    },
    height: {
      type: Number,
      default: 337,
    },
    url: {
      type: String,
      default: "",
    },
    placeholder: {
      type: Boolean,
      default: true,
    },
    frontUrl: {
      type: String,
      default: "",
    },
    backUrl: {
      type: String,
      default: "",
    },
    designationUrl: {
      type: String,
      default: "",
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
    showZoomExpand: {
      type: Boolean,
      default: true,
    },
    changeSideButton: {
      type: Boolean,
      default: false,
    },
    isFrontSide: {
      type: Boolean,
      default: false,
    },
    headerModal: {
      type: String,
    },
    forceActivate: {
      type: String,
      default: "",
    },
    isAzukarisho: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      labelBtnTop: "",
      labelBtnBottom: "",
      frontUrlActive: true,
      backUrlActive: false,
      designationUrlActive: false,

      txtFrontUrl: "表面を見る",
      txtBackUrl: "裏面を見る",
      txtFirstAzukarishoUrl: "１枚目を見る",
      txtSecondAzukarishoUrl: "２枚目を見る",
      txtDesignationUrl: "指定書を見る",

      rotate: 0,
      scale: 1,
      translate: 0,
      rotateModal: 0,
      scaleModal: 1,
      translateModal: 0,
      colorConst: BUTTON_COLOR,
    };
  },

  computed: {
    currentUrl: function () {
      if (this.url) {
        return this.url;
      }

      if (this.frontUrlActive) {
        return this.frontUrl;
      }

      if (this.backUrlActive) {
        return this.backUrl;
      }

      if (this.designationUrlActive) {
        return this.designationUrl;
      }

      return this.frontUrl;
    },

    curenHeaderModal: function () {
      if (this.frontUrlActive) {
        return "在留カード表面";
      }

      if (this.backUrlActive) {
        return "在留カード裏面";
      }

      if (this.designationUrlActive) {
        return "指定書を見る";
      }

      return "";
    },
  },

  created() {
    this.labelBtnTop = this.isAzukarisho
      ? this.txtSecondAzukarishoUrl
      : this.txtBackUrl;
    this.labelBtnBottom = this.txtDesignationUrl;

    if (this.forceActivate) {
      switch (this.forceActivate) {
        case "front":
          break;
        case "back":
          this.frontUrlActive = false;
          this.backUrlActive = true;
          this.designationUrlActive = false;
          break;
        case "designation":
          this.frontUrlActive = false;
          this.backUrlActive = false;
          this.designationUrlActive = true;
          break;
        default:
          break;
      }
    }
  },

  watch: {
    frontUrlActive: function (data) {
      if (!this.backUrl) {
        this.labelBtnBottom = this.txtDesignationUrl;
        return;
      }
      if (data) {
        this.labelBtnTop = this.isAzukarisho
          ? this.txtSecondAzukarishoUrl
          : this.txtBackUrl;
        this.labelBtnBottom = this.txtDesignationUrl;
      }
    },

    backUrlActive: function (data) {
      if (data) {
        this.labelBtnTop = this.isAzukarisho
          ? this.txtFirstAzukarishoUrl
          : this.txtFrontUrl;
        this.labelBtnBottom = this.txtDesignationUrl;
      }
    },

    designationUrlActive: function (data) {
      if (this.designationUrlActive && !this.backUrl) {
        this.labelBtnBottom = this.isAzukarisho
          ? this.txtFirstAzukarishoUrl
          : this.txtFrontUrl;
        return;
      }
      if (data) {
        this.labelBtnTop = this.isAzukarisho
          ? this.txtFirstAzukarishoUrl
          : this.txtFrontUrl;
        this.labelBtnBottom = this.isAzukarisho
          ? this.txtSecondAzukarishoUrl
          : this.txtBackUrl;
      }
    },
  },

  methods: {
    changeFrontBack() {
      this.$emit("change-side-image", this.isFrontSide);
      if (this.changeSideButton && this.isFrontSide) {
        this.labelBtnTop = this.isAzukarisho
          ? this.txtFirstAzukarishoUrl
          : this.txtFrontUrl;
        return;
      }

      if (this.changeSideButton && !this.isFrontSide) {
        this.labelBtnTop = this.isAzukarisho
          ? this.txtSecondAzukarishoUrl
          : this.txtBackUrl;
        return;
      }

      if (this.frontUrlActive) {
        this.backUrlActive = true;
        this.frontUrlActive = false;
        this.designationUrlActive = false;
        return;
      }

      this.frontUrlActive = true;
      this.backUrlActive = false;
      this.designationUrlActive = false;
    },

    changeDesignationUrl() {
      if (this.designationUrlActive && !this.backUrl) {
        this.backUrlActive = false;
        this.frontUrlActive = true;
        this.designationUrlActive = false;
        return;
      }

      if (this.designationUrlActive) {
        this.backUrlActive = true;
        this.frontUrlActive = false;
        this.designationUrlActive = false;
        return;
      }

      this.backUrlActive = false;
      this.frontUrlActive = false;
      this.designationUrlActive = true;
    },

    onRoomIn() {
      this.scale = this.scale < 1.8 ? this.scale + 0.2 : this.scale;
      this.translate = (this.maxWidth * (this.scale - 1)) / 4;
    },

    onRoomOut() {
      this.scale = this.scale > 0.4 ? this.scale - 0.2 : this.scale;
      this.translate = (this.maxWidth * (this.scale - 1)) / 4;
    },

    openModalImage() {
      this.$refs.modalImage.open();
      this.rotateModal = 0;
      this.scaleModal = 1;
      this.translateModal = 0;
    },

    onRoomInModal() {
      this.scaleModal =
        this.scaleModal < 1.8 ? this.scaleModal + 0.2 : this.scaleModal;
      this.translateModal = (this.maxWidth * (this.scaleModal - 1)) / 4;
    },

    onRoomOutModal() {
      this.scaleModal =
        this.scaleModal > 0.4 ? this.scaleModal - 0.2 : this.scaleModal;
      this.translateModal = (this.maxWidth * (this.scaleModal - 1)) / 4;
    },
  },
};
</script>
