<script>
import { h } from "vue";
import { toCanvas } from "qrcode";

export default {
  name: "QrcodeComponent",

  props: {
    value: {
      type: String,
      default: undefined,
    },

    options: {
      type: Object,
      default: undefined,
    },
  },

  watch: {
    $props: {
      deep: true,
      immediate: true,

      handler() {
        if (this.$el) {
          this.generate();
        }
      },
    },
  },

  mounted() {
    this.generate();
  },

  methods: {
    generate() {
      const options = this.options || {};
      const value = this.value;
      const done = () => {
        this.$emit("ready", this.$el);
      };

      toCanvas(this.$el, value, options, (error) => {
        if (error) {
          throw error;
        }

        done();
      });
    },
  },

  render() {
    return h("canvas", this.$slots.default);
  },
};
</script>
