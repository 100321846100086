<template>
  <div
    :class="[
      'custom-input',
      className,
      { 'bg-white-red': redBackground && required && !valueInput },
    ]"
    v-if="!squareLabeled || disabled || readonly"
  >
    <input
      :accept="accept"
      :type="eyeIcon && !eye ? 'password' : type"
      :class="[
        { 'is-invalid': error !== '' },
        { 'no-placeholder': placeholder === '' },
        { disabled: disabled },
        { 'color-transparent': type == 'file' && !valueInput },
        { 'highlight-field': highlight },
      ]"
      :id="id"
      :name="name"
      :value="valueInput"
      :disabled="disabled"
      :readonly="readonly"
      @input="handleChange"
      autocomplete="new-password"
      :maxlength="maxlength"
    />

    <label :class="['floating-label', { 'not-value': !valueInput }]">
      <span v-html="placeholder" />
      <span v-if="required && !disabled" class="required-label">必須</span>
    </label>

    <div class="error-input" v-if="error !== ''">
      <p v-html="error" />
    </div>

    <div class="eye" v-if="eyeIcon" @click="eye = !eye">
      <v-icon v-show="eye">mdi-eye-outline</v-icon>
      <v-icon v-show="!eye">mdi-eye-off-outline</v-icon>
    </div>
    <div class="help" v-if="helpIcon">
      <v-icon>mdi-help-circle</v-icon>
    </div>
  </div>

  <div class="d-flex" v-else>
    <div class="square-label-block">
      <div
        :class="[
          'square-label',
          {
            'square-label-required': required,
            'square-label-optional': !required,
            'lang-en': $store.state.lang == 'en',
            'lang-ja': $store.state.lang == 'ja',
            'lang-kr': $store.state.lang == 'kr',
            'lang-my': $store.state.lang == 'my',
            'lang-np': $store.state.lang == 'np',
            'lang-pr': $store.state.lang == 'pr',
            'lang-sc': $store.state.lang == 'sc',
            'lang-tc': $store.state.lang == 'tc',
            'lang-tg': $store.state.lang == 'tg',
            'lang-vi': $store.state.lang == 'vi',
            'lang-ep': $store.state.lang == 'ep',
          },
        ]"
      >
        <p v-html="required ? $t('required') : $t('optional')" />
      </div>
    </div>

    <div :class="['custom-input', className, 'w-100']">
      <input
        :accept="accept"
        :type="eyeIcon && !eye ? 'password' : type"
        :class="[
          { 'is-invalid': error !== '' },
          { 'no-placeholder': placeholder === '' },
          { disabled: disabled },
          { 'color-transparent': type == 'file' && !valueInput },
          { 'highlight-field': highlight },
        ]"
        :id="id"
        :name="name"
        :value="valueInput"
        :disabled="disabled"
        :readonly="readonly"
        @input="handleChange"
        autocomplete="new-password"
        :maxlength="maxlength"
      />

      <label :class="['floating-label', { 'not-value': !valueInput }]">
        <span v-html="placeholder" />
      </label>

      <div class="error-input" v-if="error !== ''">
        <p v-html="error" />
      </div>

      <div class="eye" v-if="eyeIcon" @click="eye = !eye">
        <v-icon v-show="eye">mdi-eye-outline</v-icon>
        <v-icon v-show="!eye">mdi-eye-off-outline</v-icon>
      </div>
      <div class="help" v-if="helpIcon">
        <v-icon>mdi-help-circle</v-icon>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from "@/common/helpers";

export default {
  name: "InputComponent",

  props: {
    name: String,
    id: String,
    className: String,
    type: {
      type: String,
      default: "text",
    },
    value: {
      type: [String, Boolean, Number],
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    error: {
      type: String,
      default: "",
    },
    disabled: {
      type: [Boolean, String],
      default: false,
    },
    highlight: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    squareLabeled: {
      type: Boolean,
      default: false,
    },
    delay: {
      type: Boolean,
      default: false,
    },
    accept: {
      type: String,
      default: "",
    },
    eyeIcon: {
      type: Boolean,
      default: false,
    },
    helpIcon: {
      type: Boolean,
      default: false,
    },
    maxlength: {
      type: Number,
    },
    redBackground: {
      type: Boolean,
      default: true,
    },
  },

  emits: ["handleChange"],

  data() {
    return {
      eye: false,
      valueInput: this.value,
    };
  },

  watch: {
    value() {
      this.valueInput = this.value;
    },
  },

  methods: {
    handleChange(e) {
      this.valueInput = e.target.value;

      if (!this.delay) {
        this.$emit("handleChange", this.valueInput);
        return;
      }
      this.changeInputDelay();
    },

    changeInputDelay: debounce(function () {
      this.$emit("handleChange", this.valueInput);
    }, 1000),
  },
};
</script>
