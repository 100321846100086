<template>
  <div class="register-success my-auto">
    <div class="d-flex">
      <img class="w-12 mx-auto" :src="require('@/assets/img/email.png')" />
    </div>
    <h1 class="title text-left">
      ご登録のメールアドレスに <br />
      アカウント開設のご案内を <br />
      送信いたしました。
    </h1>
    <p class="text-white-smoke fs-15 mt-16">
      在留カードの偽造チェックができるアプリはこちらから
    </p>

    <div class="d-flex justify-center align-center my-4 icon-download">
      <a
        href="https://apps.apple.com/jp/app/%E3%83%93%E3%82%B6%E3%83%9E%E3%83%8D/id1533068341?l=en"
        target="_blank"
      >
        <img class="app-store" :src="require('@/assets/img/app-store-2.svg')" />
      </a>
      <a
        href="https://play.google.com/store/apps/details?id=jp.co.willgroup.visamane"
        target="_blank"
      >
        <img
          class="google-play"
          :src="require('@/assets/img/google-play.png')"
        />
      </a>
    </div>
  </div>
</template>
