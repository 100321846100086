<template>
  <div class="tabs">
    <ul>
      <li
        v-for="(tab, index) in tabs"
        :key="index"
        :class="{ 'is-active': index == active }"
        @click="changeCurrentTab(tab)"
      >
        {{ tab.name }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "PageSubTitleSetComponent",

  props: {
    tabs: {
      type: Array,
      required: true,
    },
    currentTab: [String, Number],
  },

  computed: {
    active() {
      const getContentTabs = this.tabs.map((e) => e.content);
      return getContentTabs.indexOf(this.currentTab);
    },
  },

  methods: {
    changeCurrentTab(selectedTab) {
      this.$emit("changeTab", selectedTab.content);
    },
  },
};
</script>
