<template>
  <div class="page-list">
    <div class="data-table">
      <table class="table">
        <thead v-if="columns.length">
          <tr>
            <th v-if="selection" class="w-5">
              <input
                type="checkbox"
                class="checkbox-filled"
                :checked="selectAll && items.length > 0"
                @click="onCheckAll($event.target.checked)"
              />
            </th>
            <th v-for="(column, i) in columns" :key="i">
              <slot :name="`column_${Object.keys(column)[0]}`" :data="column">
                {{ Object.values(column)[0] }}
              </slot>
            </th>
          </tr>
        </thead>
        <tbody v-if="items.length">
          <tr
            v-for="(item, i) in items"
            :key="i"
            @click="onSelectRow(item)"
            :class="{ 'not-select-row': !isNotSelectRow(item) }"
          >
            <td v-if="selection">
              <input
                type="checkbox"
                class="checkbox-filled"
                :checked="selectedList.includes(item[keySelected])"
                @click.stop="onCheckItem(item[keySelected])"
                :disabled="disabledCheckbox(item)"
              />
            </td>
            <td
              v-for="(column, i) in columns"
              :key="i"
              :class="{ disabledSelect: isNotSelectRow(item) }"
            >
              <slot :name="Object.keys(column)[0]" :data="item">
                {{ item[Object.keys(column)[0]] }}
              </slot>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="no-data text-center" v-if="!items.length && showNoDataText">
        <p class="py-4">{{ noDataText }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageListComponent",

  props: {
    columns: {
      type: Array,
      required: true,
    },

    items: {
      type: Array,
      required: true,
    },

    selection: {
      type: Boolean,
      default: true,
    },

    noDataText: {
      type: String,
      default: "データなし",
    },

    showNoDataText: {
      type: Boolean,
      default: true,
    },

    selectedList: Array,
    keySelected: String,
    disabledCheckbox: {
      type: Function,
      default: () => false,
    },

    isNotSelectRow: {
      type: Function,
      default: () => false,
    },
  },

  computed: {
    selectAll: function () {
      const filterItemNotSelect = this.items.filter(
        (i) => !this.selectedList.includes(i[this.keySelected])
      );
      if (filterItemNotSelect.length > 0) {
        return false;
      }
      return true;
    },
  },

  methods: {
    onCheckItem(key) {
      let result = this.selectedList;

      if (!this.selectedList.includes(key)) {
        result.push(key);
      } else {
        result = this.selectedList.filter((e) => e !== key);
      }

      this.$emit("items-selected", result);
    },

    onCheckAll(data) {
      let result = this.selectedList;

      if (data) {
        this.items.forEach((el) => {
          if (
            !this.selectedList.includes(el[this.keySelected]) &&
            !this.disabledCheckbox(el)
          ) {
            result.push(el[this.keySelected]);
          }
        });
      } else {
        const keyItemsList = this.items.map((el) => el[this.keySelected]);
        result = this.selectedList.filter((el) => !keyItemsList.includes(el));
      }

      this.$emit("items-selected", result);
    },
    onSelectRow(item) {
      if (!this.isNotSelectRow(item)) {
        this.$emit("select-row", item);
      }
    },
  },
};
</script>
