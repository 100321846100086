<template>
  <div class="take-photo-component">
    <div class="camera text-center">
      <video class="d-none" autoplay playsinline="true" ref="video"></video>
      <canvas v-show="isVideoStream" ref="videoCanvas"></canvas>
      <slot v-if="videoStream" />
    </div>

    <div v-if="videoStream" class="screenshot-button d-flex justify-center">
      <div
        class="screenshot"
        :class="{ 'screenshot-mobile': isMobile }"
        @click="screenshot"
      >
        <v-icon>mdi-camera</v-icon>
      </div>
    </div>
  </div>
</template>

<script>
import { MOBILE_LIST } from "@/common/constants.js";

export default {
  name: "TakePhotoComponent",

  props: {
    initCamera: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isVideoStream: false,
      videoStream: null,
      sizeRatio: 2.4,
      constraints: {
        audio: false,
        video: {
          // width:200(height:320) * sizeRatio を初期値としておく
          width: 480,
          height: 768,
          aspectRatio: 0.62,
          facingMode: "environment",
        },
      },
    };
  },

  computed: {
    isMobile() {
      return MOBILE_LIST.test(navigator.userAgent);
    },
  },

  watch: {
    initCamera: {
      handler: function () {
        if (this.initCamera) {
          this.initializeCamera();
        } else {
          this.stopCamera();
        }
      },
      immediate: true,
    },
  },

  mounted() {
    window.addEventListener("orientationchange", this.handleOrientationChange);
  },

  methods: {
    handleOrientationChange() {
      const orientation = window.screen.orientation.type;
      if (orientation == "portrait-primary") {
        this.constraints = {
          audio: false,
          video: {
            with: 200 * this.sizeRatio,
            height: 320 * this.sizeRatio,
            aspectRatio: 0.62,
            facingMode: "environment",
          },
        };
      } else if (orientation == "landscape-primary") {
        this.constraints = {
          audio: false,
          video: {
            with: 320 * this.sizeRatio,
            height: 200 * this.sizeRatio,
            aspectRatio: 1.62,
            facingMode: "environment",
          },
        };
      }
      if (this.initCamera) {
        this.initializeCamera();
      }
    },

    async initializeCamera() {
      this.isVideoStream = true;
      this.$emit("initialize-camera");
      this.drawCamera();
      try {
        this.videoStream = await navigator.mediaDevices.getUserMedia(
          this.constraints
        );
        if (this.$refs.video) {
          this.$refs.video.srcObject = this.videoStream;
        }
      } catch {
        this.stopCamera();
        alert("Could not access the camera");
      }
    },

    drawCamera() {
      const videoCanvas = this.$refs.videoCanvas;
      if (videoCanvas) {
        const video = this.$refs.video;
        const ctx = videoCanvas.getContext("2d");

        // HTMLのcanvasタグに表示する大きさ指定
        videoCanvas.width = video.videoWidth / this.sizeRatio;
        videoCanvas.height = video.videoHeight / this.sizeRatio;
        ctx.drawImage(video, 0, 0, videoCanvas.width, videoCanvas.height);

        const width = videoCanvas.width - 40;
        const height = videoCanvas.height - 20;
        const paddingX = 20;
        const paddingY = 10;

        ctx.rect(paddingX, paddingY, width, height);
        ctx.setLineDash([20, 10]);
        ctx.lineWidth = "3";
        ctx.strokeStyle = "white";
        ctx.stroke();

        setTimeout(this.drawCamera, 100);
      }
    },

    stopCamera() {
      this.isVideoStream = false;
      this.$emit("stop-camera");
      if (this.videoStream) {
        const tracks = this.$refs.video.srcObject.getTracks();
        tracks.forEach((track) => {
          track.stop();
          this.$refs.video.srcObject = null;
          this.videoStream = null;
          this.videoCanvas = null;
        });
      }
    },

    screenshot() {
      const canvas = document.createElement("canvas");
      const video = this.$refs.video;
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      canvas.getContext("2d").drawImage(video, 0, 0);
      this.$emit("image-upload", canvas.toDataURL("image/png").toString());

      const blobBin = atob(canvas.toDataURL("image/png").split(",")[1]);
      let array = [];
      for (let i = 0; i < blobBin.length; i++) {
        array.push(blobBin.charCodeAt(i));
      }
      const file = new File([new Uint8Array(array)], "image.png", {
        type: "image/png",
      });
      this.$emit("file-upload", file);

      this.stopCamera();
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/abstracts/variable.scss";
@import "@/assets/scss/abstracts/mixin.scss";

.take-photo-component {
  @include respond(landscape-mobile) {
    .screenshot-mobile {
      position: fixed;
      top: 50%;
      right: 3rem;
    }
  }

  .screenshot {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    border: 0.3rem solid $color-dark;
    cursor: pointer;

    i {
      font-size: 3.3rem;
    }
  }
}
</style>
