import SessionStorage from "@/common/sessionStorage";

const state = () => ({
  recentlyItemPulldownList: JSON.parse(
    SessionStorage.getData("recentlyFilterPulldownList") || "[]"
  ),
});

const mutations = {
  addRecentlyItem(state, data) {
    state.recentlyItemPulldownList = data;
  },
};

const actions = {
  handleAddRecentlyItem({ state, commit }, payload) {
    let recentlyItemListResult = [];
    const findKeyPulldown = state.recentlyItemPulldownList.filter(
      (el) => el.field === payload.field
    );

    if (findKeyPulldown.length > 0) {
      let listData = [];
      state.recentlyItemPulldownList.forEach((el) => {
        if (el.field === payload.field) {
          listData = el.data;
        }
      });

      let listRecentlyData = listData.filter(
        (x) => x[payload.itemText] !== payload.value[payload.itemText]
      );
      listRecentlyData = [payload.value, ...listRecentlyData];

      recentlyItemListResult = state.recentlyItemPulldownList;
      recentlyItemListResult.forEach((el) => {
        if (el.field === payload.field) {
          el.data = listRecentlyData.slice(0, 5);
        }
      });
    } else {
      recentlyItemListResult = [
        ...state.recentlyItemPulldownList,
        {
          field: payload.field,
          data: [payload.value],
        },
      ];
    }

    commit("addRecentlyItem", recentlyItemListResult);
    SessionStorage.saveData(
      "recentlyFilterPulldownList",
      JSON.stringify(recentlyItemListResult)
    );
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
