import ShopService from "@/services/shop.service.js";

const state = () => ({
  shops: [],

  paramsShopFilter: {
    isAlertExist: false,
    office: null,
  },
});

const mutations = {
  setStateShops(state, data) {
    state.shops = data;
  },

  setStateParamsShopFilter(state, data) {
    state.paramsShopFilter = { ...state.paramsShopFilter, ...data };
  },

  resetParamsShopFilter(state) {
    state.paramsShopFilter = {
      isAlertExist: false,
      office: null,
    };
  },
};

const actions = {
  getShops({ commit }) {
    ShopService.getShops()
      .then((res) => {
        commit("setStateShops", res.data);
      })
      .catch(() => {
        return;
      });
  },
};

export default {
  state,
  mutations,
  actions,
};
