<template>
  <div :key="isResetData">
    <keep-alive>
      <component
        v-bind:is="currentComponent"
        :residenceCard="residenceCard"
        :imageUrlLocal="imageUrlLocal"
        :idAdmin="adminInfoState ? adminInfoState.idAdmin : null"
        @changeResidenceCard="onChangeResidenceCard"
        @saveImageLocal="saveImageLocal"
        @resetDataResidenceCard="resetDataResidenceCard"
        @changeComponent="onChangeCurrentComponent"
        :componentBack="componentBack"
        @changeComponentback="onChangeComponentBack"
      ></component>
    </keep-alive>
  </div>
</template>

<script>
import SessionStorage from "@/common/sessionStorage";
import Inquiry from "./inquiry.vue";
import ConfirmCard from "./confirm-card.vue";
// card no stamp
import ChangeInfo from "./no-stamp-card/change-info.vue";
import VisaStatus from "./no-stamp-card/visa-status.vue";
import UploadPhoto from "./no-stamp-card/upload-photo.vue";
import Signature from "./no-stamp-card/signature.vue";
import ConfirmInfo from "./no-stamp-card/confirm-info.vue";
// no residence card
import CardWithAnyone from "./no-residence-card/card-with-anyone.vue";
import PhotoCertificate from "./no-residence-card/photo-certificate.vue";
import ConfirmCertificate from "./no-residence-card/confirm-certificate.vue";
// no send card
import Schedule from "./no-send-card/schedule.vue";

export default {
  components: {
    Inquiry,
    ConfirmCard,

    // card no stamp
    ChangeInfo,
    VisaStatus,
    UploadPhoto,
    Signature,
    ConfirmInfo,

    // no residence card
    CardWithAnyone,
    PhotoCertificate,
    ConfirmCertificate,

    // no send card
    Schedule,
  },

  data() {
    return {
      adminInfoState: JSON.parse(SessionStorage.getData("adminInfo") || null),
      currentComponent: "Inquiry",
      residenceCard: null,
      imageUrlLocal: null,
      isResetData: 0,
      componentBack: "Inquiry",
    };
  },

  methods: {
    // change screen
    onChangeCurrentComponent(component) {
      this.currentComponent = component;
    },

    onChangeComponentBack(component) {
      this.componentBack = component;
    },

    // change data residence card
    onChangeResidenceCard(data) {
      this.residenceCard = {
        ...this.residenceCard,
        ...data,
      };
    },

    // save imageURL to show final screen
    saveImageLocal(data) {
      this.imageUrlLocal = {
        ...this.imageUrlLocal,
        ...data,
      };
    },

    // reset data residence card null
    resetDataResidenceCard() {
      this.isResetData++;
      this.residenceCard = null;
      this.imageUrlLocal = null;
    },
  },
};
</script>
