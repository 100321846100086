<template>
  <modal-component
    ref="noticeDetailModal"
    class="notice-detail-modal"
    :max-width="900"
  >
    <template #header class="modal-header">
      <div>お知らせ</div>
    </template>
    <template #body class="modal-body" v-if="noticeDetail">
      <div class="modal-body__content">
        <p class="modal-body__title text-left text-dark-light mt-0">
          {{ noticeDetail.subject }}
        </p>
        <p class="notice-date text-left">
          {{
            formatDate(new Date(noticeDetail.releasedDate), "yyyy年mm月dd日")
          }}
          <span
            class="text-success font-weight-bold pl-3"
            :style="{ color: getNoticeTypeColor(noticeDetail.type) }"
          >
            {{ noticeType(noticeDetail.type) }}
          </span>
        </p>
        <div
          class="text-left pt-5"
          v-html="markdownToHtml(noticeDetail.body)"
        ></div>
      </div>
    </template>
    <template #footer>
      <div class="text-center">
        <v-btn
          class="close"
          variant="outlined"
          :color="colorConst.SET"
          @click="$refs.noticeDetailModal.close()"
        >
          閉じる
        </v-btn>
      </div>
    </template>
  </modal-component>
</template>

<script>
import marked from "marked";
import constFunc from "@/common/constFunc";
import utilFunc from "@/common/utilFunc";
import { BUTTON_COLOR } from "@/common/constants";

export default {
  name: "NoticeModal",

  props: {
    noticeDetail: Object,
  },

  data() {
    return {
      formatDate: utilFunc.formatDate,
      getNoticeTypeColor: constFunc.getNoticeTypeColor,
      noticeType: constFunc.getNoticeType,
      colorConst: BUTTON_COLOR,
    };
  },

  methods: {
    open() {
      this.$refs.noticeDetailModal.open();
    },

    markdownToHtml(markdown) {
      return marked(markdown);
    },
  },
};
</script>
