<template>
  <div class="upload-image-smartphone">
    <div class="text-center" v-if="!isVideoStream">
      <a class="button-bg-danger fs-16 py-1 px-8" @click="isUpload = true">
        ファイルを選択 / 写真を撮る
      </a>
    </div>

    <div class="upload-section" v-if="isUpload" v-click-outside="onOutside">
      <div class="upload-button">
        <a>ファイルを選択 <v-icon>mdi-attachment</v-icon></a>
      </div>
      <div class="upload-type">
        <div class="option">
          <label for="image">
            <div class="option-item">
              <p>写真ライブラリ</p>
              <p><v-icon>mdi-folder-multiple-image</v-icon></p>
            </div>
            <input
              ref="image"
              class="invisible po-ab"
              id="image"
              type="file"
              accept="image/*"
              @change="uploadImage('image')"
            />
          </label>
        </div>
        <div class="option option-item" @click="initializeCamera()">
          <p>写真を撮る</p>
          <p><v-icon>mdi-camera-enhance-outline</v-icon></p>
        </div>
        <div class="option">
          <label for="file">
            <div class="option-item">
              <p>ファイルを選択</p>
              <p><v-icon>mdi-folder-outline</v-icon></p>
            </div>
            <input
              ref="file"
              class="invisible po-ab"
              id="file"
              type="file"
              @change="uploadImage('file')"
            />
          </label>
        </div>
      </div>
    </div>

    <div>
      <div class="text-center">
        <video class="d-none" autoplay ref="video"></video>
        <canvas v-show="isVideoStream" ref="videoCanvas"></canvas>
      </div>
      <div v-if="videoStream" class="d-flex justify-center py-10">
        <div class="screenshot" @click="screenshot">
          <v-icon>mdi-camera</v-icon>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UploadImageSmartphone",

  data() {
    return {
      isUpload: false,
      isVideoStream: false,
      videoStream: null,
      sizeRatio: 2.4,
      constraints: {
        audio: false,
        video: {
          width: 480,
          height: 768,
          aspectRatio: 0.62,
          facingMode: "environment",
        },
      },
    };
  },

  methods: {
    async initializeCamera() {
      this.isUpload = false;
      this.isVideoStream = true;
      this.$emit("initialize-camera");
      this.drawCamera();
      this.constraints.video.facingMode = "environment";
      try {
        this.videoStream = await navigator.mediaDevices.getUserMedia(
          this.constraints
        );
        this.$refs.video.srcObject = this.videoStream;
      } catch {
        this.stopCamera();
        alert("Could not access the camera");
      }
    },

    drawCamera() {
      const videoCanvas = this.$refs.videoCanvas;
      if (videoCanvas) {
        const video = this.$refs.video;
        const ctx = videoCanvas.getContext("2d");

        videoCanvas.width = video.videoWidth / this.sizeRatio;
        videoCanvas.height = video.videoHeight / this.sizeRatio;
        ctx.drawImage(video, 0, 0, videoCanvas.width, videoCanvas.height);

        const width = videoCanvas.width - 40;
        const height = videoCanvas.height - 20;
        const paddingX = 20;
        const paddingY = 10;

        ctx.rect(paddingX, paddingY, width, height);
        ctx.setLineDash([20, 10]);
        ctx.lineWidth = "3";
        ctx.strokeStyle = "white";
        ctx.stroke();

        setTimeout(this.drawCamera, 100);
        return;
      }

      this.isUpload = false;
    },

    stopCamera() {
      this.isVideoStream = false;
      this.$emit("stop-camera");
      if (this.videoStream) {
        const tracks = this.$refs.video.srcObject.getTracks();
        tracks.forEach((track) => {
          track.stop();
          this.$refs.video.srcObject = null;
          this.videoStream = null;
          this.videoCanvas = null;
        });
      }
    },

    screenshot() {
      const canvas = document.createElement("canvas");
      const video = this.$refs.video;
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      canvas.getContext("2d").drawImage(video, 0, 0);
      this.$emit("image-url", canvas.toDataURL("image/png").toString());
      this.stopCamera();
    },

    uploadImage(refs) {
      const file = this.$refs[refs].files[0];
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        this.$emit("image-url", reader.result.toString());
      });
      if (file) {
        reader.readAsDataURL(file);
      }
      this.isUpload = false;
    },

    onOutside() {
      if (this.isUpload) {
        this.isUpload = false;
      }
    },
  },
};
</script>
