<template>
  <div class="reset-password-page">
    <div class="form-reset" v-if="!isSetPasswordSuccess">
      <form @submit.prevent="submit">
        <h1 class="title">パスワード設定</h1>
        <div class="item">
          <InputComponent
            type="text"
            :eyeIcon="true"
            :value="newPassword"
            placeholder="新しいパスワード"
            @handleChange="newPassword = $event"
          />
        </div>

        <div class="validate-password">
          <p :class="['validate-item', { 'active-valid': minLength }]">
            <v-icon x-small>mdi-checkbox-marked-circle-outline</v-icon>
            ８文字以上を含む
          </p>
          <p :class="['validate-item', { 'active-valid': validCharacters }]">
            <v-icon x-small>mdi-checkbox-marked-circle-outline</v-icon>
            英大文字と英子文字を含む
          </p>
          <p :class="['validate-item', { 'active-valid': specialCharacters }]">
            <v-icon x-small>mdi-checkbox-marked-circle-outline</v-icon>
            記号(!#$%&'()*+,-./:;&lt;=&gt;?@[]^_`{|}~）")を含む
          </p>
          <p :class="['validate-item', { 'active-valid': includeNumber }]">
            <v-icon x-small>mdi-checkbox-marked-circle-outline</v-icon>
            数字を含む
          </p>
        </div>

        <div class="item">
          <InputComponent
            type="text"
            :eyeIcon="true"
            :value="confirmPassword"
            placeholder="新しいパスワード(確認用)"
            @handleChange="confirmPassword = $event"
          />
        </div>

        <div class="btn-reset mt-15">
          <button
            :class="['btn fs-16 border-8', { disabled: disabledBtnReset }]"
            :disabled="disabledBtnReset"
            @click="handleResetPassword"
          >
            パスワードを設定する
          </button>
        </div>
      </form>
    </div>

    <div class="reset-success" v-if="isSetPasswordSuccess">
      <img :src="require(`@/assets/img/lock.png`)" />
      <h1 class="title">パスワードを設定いたしました。</h1>
      <div class="btn-reset">
        <button
          class="btn fs-16 border-8"
          @click="$router.push({ name: 'login' })"
        >
          ログインへ
        </button>
      </div>
    </div>

    <modal-component
      :modal-data="modalData"
      ref="modalComponent"
      :max-width="650"
      @redirectLoginPage="$router.push({ name: 'login' })"
    >
      <template #body>
        <img
          v-if="result == 'ok'"
          :src="require(`@/assets/img/success.svg`)"
          class="icon-modal"
        />
        <img
          v-if="result == 'error'"
          :src="require(`@/assets/img/alert.svg`)"
          class="icon-modal"
        />
        <p class="text-danger text-bold fs-20">
          {{ titleModal }}
        </p>
        <p class="text-white-smoke fs-16" v-html="contentModal" />
      </template>
    </modal-component>
  </div>
</template>

<script>
import {
  MIN_LENGTH_PASSWORD,
  REGEX_UPPERCASE_LOWERCASE,
  REGEX_ONE_NUMBER,
  REGEX_ONE_SPECIAL_CHAR,
  BUTTON_COLOR,
  HTTP_RESPONSE_STATUS_CODES,
} from "@/common/constants.js";
import CompanyService from "@/services/company.service.js";

import InputComponent from "@/components/InputComponent.vue";
import ModalComponent from "@/components/ModalComponent.vue";

export default {
  name: "Password",

  components: {
    InputComponent,
    ModalComponent,
  },

  data() {
    return {
      newPassword: null,
      confirmPassword: null,
      token: this.$route.query.token,
      modalData: null,
      isSetPasswordSuccess: false,
      colorConst: BUTTON_COLOR,
      result: null,
      contentModal: null,
      titleModal: null,
    };
  },

  created() {
    if (!this.token) {
      this.$router.push({ name: "registerCompany" });
    }
  },

  computed: {
    minLength: function () {
      return this.newPassword && this.newPassword.length >= MIN_LENGTH_PASSWORD;
    },

    validCharacters: function () {
      return (
        this.newPassword && this.newPassword.match(REGEX_UPPERCASE_LOWERCASE)
      );
    },

    specialCharacters: function () {
      return this.newPassword && this.newPassword.match(REGEX_ONE_SPECIAL_CHAR);
    },

    includeNumber: function () {
      return this.newPassword && this.newPassword.match(REGEX_ONE_NUMBER);
    },

    disabledBtnReset: function () {
      if (!this.newPassword || !this.confirmPassword) {
        return true;
      }
      if (this.newPassword !== this.confirmPassword) {
        return true;
      }
      if (
        !this.minLength ||
        !this.validCharacters ||
        !this.specialCharacters ||
        !this.includeNumber
      ) {
        return true;
      }
      return false;
    },
  },

  methods: {
    handleResetPassword() {
      CompanyService.setPasswordNewCompany(this.newPassword, this.token)
        .then((res) => {
          if (!res.message) {
            this.isSetPasswordSuccess = true;
            return;
          }
          this.result = res.result;
          this.modalData = {
            message: {
              header: res.message.header,
            },
            button: [
              {
                color: BUTTON_COLOR.SET,
                text: "ログインページへ移動",
                action: "redirectLoginPage",
              },
            ],
          };
          this.titleModal = res.message.title;
          this.contentModal = res.message.content.replaceAll("\n", "<br />");
          this.$refs.modalComponent.open();
        })
        .catch((err) => {
          if (
            this.$store.state.errorStatus ==
            HTTP_RESPONSE_STATUS_CODES.STATUS_400_BAD_REQUEST
          ) {
            this.result = err.result;
            this.modalData = {
              message: {
                header: err.message.header,
              },
            };
            this.titleModal = err.message.title;
            this.contentModal = err.message.content.replaceAll("\n", "<br />");
            this.$refs.modalComponent.open();
          }
        });
    },
  },
};
</script>
<style scoped>
.icon-modal {
  width: 10rem;
}
</style>
