<template>
  <div class="signature-staff mt-3">
    <div class="text-danger fs-12">
      <a
        class="btn-back"
        @click="
          $emit(
            'changeComponent',
            isExistChangeInfo ? 'ChangeInfo' : 'UploadPhoto'
          )
        "
      >
        <v-icon class="mb-1 mr-n2 mt-1">mdi-menu-left</v-icon>
        <span class="ml-n2" v-html="$t('previous screen')" />
      </a>
    </div>
    <div
      class="bg-grey-light-3 fs-16 text-white-smoke text-center pa-4 border-8"
    >
      <p v-html="$t('agreement')" />
    </div>

    <div class="privacy-pledge mt-3 fs-16 text-danger text-center">
      <div class="px-1 fs-14 text-white-smoke text-left">
        <p v-html="$t('contract3')" />
        <p v-html="$t('contract4')" />
        <p v-html="$t('contract5')" />

        <br />
        <b v-html="$t('contract13')" /><br />
        <p v-html="$t('contract6')" />

        <br />
        <b v-html="$t('contract14')" /><br />
        <p v-html="$t('contract8')" />
        <p v-html="$t('contract7')" />
      </div>
    </div>

    <div class="mt-2 fs-12 text-center text-white-smoke">
      <SignatureComponent
        @signture="signatureURL = $event"
        @signture-file="signatureFile = $event"
      />
    </div>

    <div class="py-7 text-center">
      <button
        :class="[
          'btn w-12 fs-16  border-8',
          signatureURL ? ' btn-danger' : 'button-disabled',
          { 'h-4': $i18n.locale == 'ja' },
        ]"
        :disabled="!signatureURL"
        @click="nextScreen"
      >
        <p v-html="$t('next')" />
      </button>
    </div>

    <modal-component ref="modalLoading">
      <template #header> <span v-html="$t('upload image')" /> </template>
      <template #body>
        <div class="modal-body-data fs-14 mx-4 text-white-smoke">
          <div class="modal-body__img">
            <loading-component :loading="true" />
          </div>
          <h1 class="modal-body__title" v-html="$t('image uploading')" />
          <p v-html="$t('wait a moment')" />
        </div>
      </template>
    </modal-component>

    <modal-component :modalData="modalDefault" ref="modalDefault" />
  </div>
</template>

<script>
import EtcService from "@/services/etc.service";
import { REQUIRED_ITEM } from "@/common/constants.js";

import ModalComponent from "@/components/ModalComponent.vue";
import SignatureComponent from "@/components/SignatureComponent.vue";
import LoadingComponent from "@/components/LoadingComponent.vue";

export default {
  name: "Signature",

  components: {
    ModalComponent,
    LoadingComponent,
    SignatureComponent,
  },

  props: ["residenceCard", "idAdmin"],

  data() {
    return {
      signatureURL: null,
      signatureFile: null,

      modalDefault: null,
      isExistChangeInfo: true,
    };
  },

  created() {
    if (
      this.residenceCard.nameStaffRegisterRequirement ===
        REQUIRED_ITEM.HIDDEN &&
      this.residenceCard.emailStaffRequirement === REQUIRED_ITEM.HIDDEN &&
      this.residenceCard.phoneNumberRequirement === REQUIRED_ITEM.HIDDEN
    ) {
      this.isExistChangeInfo = false;
    }
  },

  methods: {
    nextScreen() {
      this.$refs.modalLoading.open();

      let formData = new FormData();
      formData.append("file", this.signatureFile);
      formData.append("type", "signatureURL");
      formData.append("idStaff", this.residenceCard.idStaff);
      formData.append("idCompany", this.residenceCard.idCompany);
      formData.append("path", this.$route.path);
      if (this.idAdmin) {
        formData.append("idAdmin", this.idAdmin);
      }

      EtcService.postPictureUploadAny(formData)
        .then((res) => {
          this.$refs.modalLoading.close();
          this.$emit("changeResidenceCard", {
            signatureURL: res.data,
          });

          this.$emit("saveImageLocal", {
            signatureURL: this.signatureURL,
          });

          this.$emit("changeComponent", "ConfirmInfo");
        })
        .catch((err) => {
          this.$refs.modalLoading.close();
          this.modalDefault = err;
          this.$refs.modalDefault.open();
        });
    },
  },
};
</script>
