<template>
  <div class="upload-image-smartphone-page">
    <div class="text-center pt-10" v-if="!urlImage">
      <img
        v-if="!takePhoto"
        class="man"
        :src="require(`@/assets/img/man.svg`)"
      />
      <p v-if="!takePhoto" class="box text-white-smoke pa-5 ma-5">
        証明書が内容が <br />
        しっかり写るように <br />
        写真を撮ってください。
      </p>
      <div>
        <upload-image-smartphone-component
          @image-url="urlImage = $event"
          @initialize-camera="takePhoto = true"
          @stop-camera="takePhoto = false"
        />
      </div>
    </div>

    <div v-if="urlImage" class="text-center pt-10">
      <image-preview-component
        :key="urlImage"
        :url="urlImage"
        :max-width="343"
        :height="213"
      />
      <p class="box text-white-smoke pa-5 ma-5">
        写真の文字がちゃんと読めるか<br />
        確認してください。
      </p>
      <div class="pt-8 pb-3 text-center">
        <a class="button-danger fs-16 py-1 px-8" @click="urlImage = null">
          撮り直す
        </a>
        <a class="button-bg-danger fs-16 py-1 px-8 ml-3"> 次へ </a>
      </div>
    </div>
  </div>
</template>

<script>
import ImagePreviewComponent from "@/components/ImagePreviewComponent.vue";
import UploadImageSmartphoneComponent from "@/components/UploadImageSmartphoneComponent.vue";

export default {
  components: {
    ImagePreviewComponent,
    UploadImageSmartphoneComponent,
  },

  data() {
    return {
      takePhoto: false,
      urlImage: null,
    };
  },
};
</script>

<style lang="scss">
.upload-image-smartphone-page {
  --color-grey-light: #f9f9fa;
  .box {
    background: var(--color-grey-light);
    border-radius: 0.8rem;
  }
}
</style>
