<template>
  <div class="date-picker">
    <input-component
      :placeholder="placeholder"
      :value="dateFomatted"
      :disabled="disabled"
      :required="required"
      :delay="delay"
      :readonly="!typeable"
      @handle-change="onChangeDateFomatted"
      @click="isShowCalendar = !disabled ? true : false"
    />

    <div class="calendar" v-if="isShowCalendar" v-click-outside="onOutside">
      <div class="calendar-header">
        <span class="month-picker" @click="isShowMonth = !isShowMonth">
          {{
            validateDate ? getMonthName(monthSelected) : getMonthName(nowMonth)
          }}
        </span>
        <div class="year-picker">
          <span class="year-change" @click="prevYear">
            <pre><v-icon>mdi-chevron-left</v-icon></pre>
          </span>
          <span>{{ yearSelected }}</span>
          <span class="year-change" @click="nextYear">
            <pre><v-icon>mdi-chevron-right</v-icon></pre>
          </span>
        </div>
      </div>

      <div class="month-list show">
        <div
          v-for="(month, i) in months"
          :key="i"
          :class="{
            'month-selected': isSelectedMonth(month.value),
            'current-month': isCurrentMonth(month.value),
          }"
          @click="selectMonth(month.value)"
        >
          {{ month.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputComponent from "@/components/InputComponent.vue";
import constFunc from "@/common/constFunc.js";
import utilFunc from "@/common/utilFunc.js";

export default {
  name: "MonthPickerComponent",

  components: {
    InputComponent,
  },

  props: {
    dateDefault: {
      type: String,
      default: "",
    },

    placeholder: {
      type: String,
      default: "",
    },

    disabled: {
      type: [Boolean, String],
      default: false,
    },

    required: {
      type: Boolean,
      default: false,
    },

    delay: {
      type: Boolean,
      default: false,
    },

    typeable: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      dateFomatted: "",
      isShowMonth: false,
      isShowCalendar: false,
      yearSelected: null,
      monthSelected: null,
      nowYear: new Date().getFullYear(),
      nowMonth: new Date().getMonth() + 1,
      dateSelected: this.dateDefault,
      constFunc: constFunc,
    };
  },

  computed: {
    validateDate() {
      return constFunc.validateDate(this.dateSelected + "-01");
    },

    months() {
      return [
        { name: "January", value: 1, days: 31 },
        {
          name: "February",
          value: 2,
          days: this.getFebDays(this.yearSelected),
        },
        { name: "March", value: 3, days: 31 },
        { name: "April", value: 4, days: 30 },
        { name: "May", value: 5, days: 31 },
        { name: "June", value: 6, days: 30 },
        { name: "July", value: 7, days: 31 },
        { name: "August", value: 8, days: 31 },
        { name: "September", value: 9, days: 30 },
        { name: "October", value: 10, days: 31 },
        { name: "November", value: 11, days: 30 },
        { name: "December", value: 12, days: 31 },
      ];
    },
  },

  created() {
    if ((!this.yearSelected || !this.monthSelected) && this.dateSelected) {
      const [yearSelectedDefault, monthSelectedDefault] =
        this.dateSelected.split("-");
      this.yearSelected = Number(yearSelectedDefault);
      this.monthSelected = Number(monthSelectedDefault);
    }

    const yearSelected = this.pad2(this.yearSelected);
    const monthSelected = this.pad2(this.monthSelected);
    this.dateSelected = `${yearSelected}-${monthSelected}`;
    if (this.validateDate) {
      this.dateFomatted = utilFunc.formatDate(
        new Date(this.dateSelected + "-01"),
        "yyyy年mm月"
      );
    }
  },

  methods: {
    getMonthName(month) {
      return this.months.find((o) => o.value == month).name;
    },

    selectMonth(month) {
      this.monthSelected = month;

      const yearSelected = this.pad2(this.yearSelected);
      const monthSelected = this.pad2(this.monthSelected);
      this.dateSelected = `${yearSelected}-${monthSelected}`;
      this.dateFomatted = utilFunc.formatDate(
        new Date(this.dateSelected + "-01"),
        "yyyy年mm月"
      );
      this.$emit("handle-change", this.dateSelected);

      this.isShowCalendar = false;
    },

    onChangeDateFomatted(data) {
      this.dateFomatted = data;

      if (!data) {
        this.yearSelected = this.nowYear;
        this.monthSelected = this.nowMonth;
        this.$emit("handle-change", null);
        return;
      }

      const dateFomatted = this.dateFomatted;
      const yearSelected = dateFomatted.substr(0, 4);
      const monthSelected = dateFomatted.substr(5, 2);
      this.dateSelected = `${yearSelected}-${monthSelected}`;

      if (this.validateDate) {
        this.yearSelected = Number(yearSelected);
        this.monthSelected = Number(monthSelected);
        this.$emit("handle-change", this.dateSelected);
      }
    },

    prevYear() {
      this.yearSelected -= 1;
    },

    nextYear() {
      this.yearSelected += 1;
    },

    isCurrentMonth(month) {
      return this.nowMonth == month && this.nowYear == this.yearSelected;
    },

    isSelectedMonth(month) {
      return (
        this.monthSelected == month &&
        this.yearSelected == this.dateSelected.split("-")[0]
      );
    },

    isLeapYear(year) {
      return (
        (year % 4 === 0 && year % 100 !== 0 && year % 400 !== 0) ||
        (year % 100 === 0 && year % 400 === 0)
      );
    },

    getFebDays(year) {
      return this.isLeapYear(year) ? 29 : 28;
    },

    onOutside() {
      this.isShowCalendar = false;
    },

    pad2(number) {
      return (number < 10 ? "0" : "") + number;
    },
  },
};
</script>
