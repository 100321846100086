import ApiService from "@/common/api.service";
import { APIS } from "@/common/api.constants";

export default {
  getOffices(params) {
    return ApiService.get(`${APIS.GET_OFFICES}`, { params: params })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  getOfficeList(params) {
    return ApiService.get(`${APIS.OFFICE_LIST}`, { params: params })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  getMainOfficeList(params) {
    return ApiService.get(`${APIS.MAINOFFICE_LIST}`, { params: params })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  postOffice(payload) {
    return ApiService.post(`${APIS.OFFICE}`, payload)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  postMainOffice(payload) {
    return ApiService.post(`${APIS.MAINOFFICE}`, payload)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  getOfficeDetail(idOffice) {
    return ApiService.get(
      `${APIS.OFFICE_DETAIL}`.replace("{idOffice}", idOffice)
    )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  //delete office
  deleteOfficeDetail(idOffice) {
    return ApiService.delete(
      `${APIS.OFFICE_DETAIL}`.replace("{idOffice}", idOffice)
    )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  //update office detail
  postOfficeDetail(idOffice, payload) {
    return ApiService.post(
      `${APIS.OFFICE_DETAIL}`.replace("{idOffice}", idOffice),
      payload
    )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  //get mainOffice detail
  getMainOfficeDetail(idOffice) {
    return ApiService.get(
      `${APIS.MAINOFFICE_DETAIL}`.replace("{idOffice}", idOffice)
    )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  //delete mainOffice
  deleteMainOfficeDetail(idOffice) {
    return ApiService.delete(
      `${APIS.MAINOFFICE_DETAIL}`.replace("{idOffice}", idOffice)
    )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  //update mainOffice detail
  postMainOfficeDetail(idOffice, payload) {
    return ApiService.post(
      `${APIS.MAINOFFICE_DETAIL}`.replace("{idOffice}", idOffice),
      payload
    )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  // get office list related or not related of main office
  getOfficeRelatedMainOfficeList(idOffice, params) {
    return ApiService.get(
      `${APIS.OFFICE_RELATED_MAINOFFICE_LIST}`.replace("{idOffice}", idOffice),
      { params: params }
    )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  // link office with mainOffice
  linkOfficeWithMainOffice(idOffice, payload) {
    return ApiService.post(
      `${APIS.OFFICE_MAINOFFICE}`.replace("{idOffice}", idOffice),
      payload
    )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  //export office csv file
  exportOfficeCsvFile(params) {
    return ApiService.get(
      APIS.OFFICE_CSV,
      { params: params },
      {
        headers: {
          "Content-Type": "text/csv;charset=utf_8_sig",
          "Content-Disposition": 'attachment; filename="offices.csv"',
        },
      }
    )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  // import office csv file
  importOfficeCsvFile(formData) {
    return ApiService.post(APIS.OFFICE_CSV, formData)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  //check office name exist
  getOfficeExists(params) {
    return ApiService.get(APIS.OFFICE_EXISTS, { params: params })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  //check main office name exist
  getMainOfficeExists(params) {
    return ApiService.get(APIS.MAINOFFICE_EXISTS, { params: params })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },
};
