import ApiService from "@/common/api.service";
import { APIS } from "@/common/api.constants";

export default {
  getDashboardHelloWork() {
    return ApiService.get(APIS.DASHBOARD_HELLOWORK)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  getDashboardAlert() {
    return ApiService.get(APIS.DASHBOARD_ALERT)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  postCompanyPopupFlag(popupAction) {
    return ApiService.post(APIS.COMPANY_POPUP_FLAG, { popupAction })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },
};
