import ApiService from "@/common/api.service";
import { APIS } from "@/common/api.constants";

export default {
  getShopList(params) {
    return ApiService.get(`${APIS.GET_SHOP_LIST}`, { params: params })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  // get shop list for filter
  getShops(params) {
    return ApiService.get(APIS.GET_SHOPS, { params: params })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  // get shop registerurl, updateurl
  getShopQRUrl(params) {
    return ApiService.get(APIS.SHOP_QR_URL, { params: params })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  // get all shops for staff registration
  getShopListPaginated(params) {
    return ApiService.get(APIS.GET_SHOP_LIST_PAGINATED, { params: params })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  // get shop list for admin detail
  getShopAdminList(idAdmin, params) {
    return ApiService.get(
      `${APIS.SHOP_ADMIN_LIST}`.replace("{idAdmin}", idAdmin),
      { params: params }
    )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  // delete shop for admin detail
  deleteAdminShop(idAdmin, idShop) {
    return ApiService.delete(
      `${APIS.SHOP_ADMIN_RELATION}`.replace("{idAdmin}", idAdmin),
      {
        params: {
          idShop,
        },
      }
    )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  // get shop list admin not relate
  getShopListAdminNotRelated(idAdmin, params) {
    return ApiService.get(
      `${APIS.SHOP_ADMIN_LIST}`.replace("{idAdmin}", idAdmin),
      { params: params }
    )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  // add link shop for admin account
  addLink(idAdmin, shops, isAll) {
    return ApiService.post(
      `${APIS.SHOP_ADMIN_RELATION}`.replace("{idAdmin}", idAdmin),
      {
        shops,
        isAll,
      }
    )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  //export file Csv
  exportShopCsvFile(params) {
    return ApiService.get(APIS.SHOP_CSV, { params: params })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  //import file Csv
  importShopCsvFile(formData) {
    return ApiService.post(APIS.SHOP_CSV, formData)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  //get shop manual make
  getShopManualMake() {
    return ApiService.get(APIS.SHOP_MANUAL_MAKE)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  //create shop manual make
  postShopManualMake() {
    return ApiService.post(APIS.SHOP_MANUAL_MAKE)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  //export file zip shop manual
  exportShopManualZipFile() {
    return ApiService.get(APIS.SHOP_MANUAL)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  // add new shop
  addShop(shop) {
    return ApiService.post(APIS.SHOP, shop)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  //get shop detail
  getShopDetail(idShop) {
    return ApiService.get(`${APIS.SHOP_DETAIL}`.replace("{idShop}", idShop))
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  //export file shop manual pdf file
  exportShopDetailManual(idShop) {
    return ApiService.get(
      `${APIS.SHOP_DETAIL_MANUAL}`.replace("{idShop}", idShop),
      { responseType: "blob" }
    )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  //delete shop
  deleteShopDetail(idShop) {
    return ApiService.delete(`${APIS.SHOP_DETAIL}`.replace("{idShop}", idShop))
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  //update shop detail
  postShopDetail(idShop, payload) {
    return ApiService.post(
      `${APIS.SHOP_DETAIL}`.replace("{idShop}", idShop),
      payload
    )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  // get admin shop not relate list
  getAdminShopNotRelatedList(idShop, params) {
    return ApiService.get(
      `${APIS.ADMIN_SHOP_LIST}`.replace("{idShop}", idShop),
      { params: params }
    )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  // add link admin to shop
  postLinkAdminToShop(idShop, admins) {
    return ApiService.post(
      `${APIS.ADMIN_SHOP_RELATION}`.replace("{idShop}", idShop),
      {
        admins,
      }
    )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  getShopOfficeList(idOffice, params) {
    return ApiService.get(
      `${APIS.SHOP_OFFICE_LIST}`.replace("{idOffice}", idOffice),
      { params: params }
    )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  postShopOffice(idOffice, payload) {
    return ApiService.post(
      `${APIS.SHOP_OFFICE}`.replace("{idOffice}", idOffice),
      payload
    )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  //Get employee registration related information
  getShopStaffAddInfo(params) {
    return ApiService.get(APIS.SHOP_STAFFADDINFO, { params: params })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  //check shop name exist
  getShopExists(params) {
    return ApiService.get(APIS.SHOP_EXISTS, { params: params })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  //check not related shop exist
  getShopNotRelatedExists() {
    return ApiService.get(APIS.SHOP_NOT_RELATED_EXISTS)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },
};
