<template>
  <div class="confirmation">
    <p class="text-white-smoke fs-14 pb-2 pt-3">
      ご登録内容をご確認の上、登録を完了してください。
    </p>

    <div class="border-white-grey border-8 pa-4">
      <p class="text-dark-light fs-16 fw-500">ログイン情報</p>
      <p class="text-color-grey fs-10 pt-6 fw-700">メールアドレス</p>
      <p class="text-dark-light fs-14 fw-500">
        {{ corporateInformation.emailAdmin }}
      </p>
      <p class="text-color-grey fs-10 pt-2 fw-700">パスワード</p>
      <p class="text-dark-light fs-14 fw-500">●●●●●●●●</p>
    </div>
    <p
      class="text-danger text-right pt-3 pb-11 fs-14 cursor-pointer"
      @click="$emit('changeComponent', 'CorporateInformation')"
    >
      編集する
    </p>

    <div class="border-white-grey border-8 pa-4">
      <p class="text-dark-light fs-16 fw-500">企業/担当者情報</p>
      <p class="text-color-grey fs-10 pt-6 fw-700">企業名</p>
      <p class="text-dark-light fs-14 fw-500">
        {{ corporateInformation.nameCompany }}
      </p>

      <p class="text-color-grey fs-10 pt-3 fw-700">担当者名</p>
      <p class="text-dark-light fs-14 fw-500">
        {{ corporateInformation.nameAdmin }}
      </p>

      <p class="text-color-grey fs-10 pt-2 fw-700">担当者電話番号</p>
      <p class="text-dark-light fs-14 fw-500">
        {{ corporateInformation.telChargePerson }}
      </p>

      <p class="text-color-grey fs-10 pt-2 fw-700">本社郵便番号</p>
      <p class="text-dark-light fs-14 fw-500">
        {{ corporateInformation.postalCompany }}
      </p>

      <p class="text-color-grey fs-10 pt-2 fw-700">本社住所地</p>
      <p class="text-dark-light fs-14 fw-500">
        {{ corporateInformation.addressCompany }}
      </p>
    </div>
    <p
      class="text-danger text-right pt-3 pb-11 fs-14 cursor-pointer"
      @click="$emit('changeComponent', 'CorporateInformation')"
    >
      編集する
    </p>

    <div class="border-white-grey border-8 pa-4">
      <p class="text-dark-light fs-16 fw-500">雇用状況</p>

      <p class="text-color-grey fs-10 pt-6 fw-700">雇用中の外国籍の従業員数</p>
      <p class="text-dark-light fs-14 fw-500">
        {{ corporateInformation.foreignerAbout }}
      </p>
    </div>
    <p
      class="text-danger text-right pt-3 pb-11 fs-14 cursor-pointer"
      @click="$emit('changeComponent', 'CorporateInformation')"
    >
      編集する
    </p>

    <label class="h-7" :class="isNewsLetter ? 'checked' : 'no-checked'">
      <input
        type="checkbox"
        class="checkbox-filled mx-2"
        v-model="isNewsLetter"
      />
      <span class="text-dark-light">
        外国人雇用における役立つコンテンツの受け取りを<br />希望します。
      </span>
    </label>

    <div
      class="d-flex flex-column justify-center align-start h-7 mt-2"
      :class="isPrivacyPolicy ? 'checked' : 'no-checked'"
    >
      <label class="d-flex align-center cursor-pointer">
        <input
          type="checkbox"
          class="checkbox-filled mx-2"
          v-model="isPrivacyPolicy"
        />
        <span class="text-dark-light">
          利用規約/個人情報の取扱いについてに同意します。
        </span>
      </label>
      <p class="pl-8 fs-14 cursor-pointer">
        <span class="text-danger" @click="$refs.termsOfService.open()">
          利用規約
        </span>
        <span class="text-grey-light px-2">|</span>
        <span class="text-danger" @click="$refs.privacyPolicy.open()">
          個人情報の取扱いについて
        </span>
      </p>
    </div>

    <div class="text-center my-8">
      <button
        :class="isPrivacyPolicy ? 'btn-danger' : 'disabled'"
        :disabled="!isPrivacyPolicy"
        class="btn h-36px w-22 fs-16"
        @click="onPostCompany"
      >
        登録する
      </button>
    </div>

    <modal-component ref="termsOfService" class="terms-of-service">
      <template #header> 利用規約 </template>

      <template #body>
        <div class="ml-8 mr-4 mt-4">
          <div v-html="terms.content" />
          <div>
            <p class="text-dark-light font-weight-bold">変更履歴</p>

            <p class="text-danger">
              {{
                utilFunc.formatDate(
                  new Date(terms.enforcement),
                  "yyyy年mm月dd日"
                )
              }}
              改定
            </p>
            <p class="text-danger">
              {{
                utilFunc.formatDate(
                  new Date(terms.promulgation),
                  "yyyy年mm月dd日"
                )
              }}
              制定
            </p>
          </div>
        </div>
      </template>
    </modal-component>

    <modal-component ref="privacyPolicy" class="privacy-policy">
      <template #header> 個人情報の取り扱いについて </template>

      <template #body>
        <div class="ml-12 mr-4 mt-4">
          <div v-html="privacyPolicy.content" />
        </div>
      </template>
    </modal-component>

    <modal-component
      :modalData="resultData"
      ref="resultModal"
      :max-width="500"
    />
  </div>
</template>

<script>
import EtcService from "@/services/etc.service";
import CompanyService from "@/services/company.service";
import utilFunc from "@/common/utilFunc";
import { mapActions } from "vuex";

export default {
  name: "Confirmation",

  props: {
    corporateInformation: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      isNewsLetter: true,
      isPrivacyPolicy: false,
      terms: null,
      privacyPolicy: null,
      resultData: null,
      utilFunc: utilFunc,
    };
  },

  created() {
    this.onGetTerms();
    this.onGetPrivacyPolicy();
  },

  methods: {
    ...mapActions(["login"]),

    onGetTerms() {
      EtcService.getTerms().then((res) => {
        this.terms = res.data;
      });
    },

    onGetPrivacyPolicy() {
      EtcService.getPrivacyPolicy().then((res) => {
        this.privacyPolicy = res.data;
      });
    },

    onPostCompany() {
      const payload = {
        ...this.corporateInformation,
        ...{
          isNewsLetter: this.isNewsLetter,
          isPrivacyPolicy: this.isPrivacyPolicy,
        },
      };
      CompanyService.postCompany(payload)
        .then(() => {
          //this.$emit("changeComponent", "RegisterSuccess");
          this.login({
            email: this.corporateInformation.emailAdmin,
            password: this.corporateInformation.password,
          });
        })
        .catch((err) => {
          this.resultData = err;
          this.$refs.resultModal.open();
        });
    },
  },
};
</script>
