<template>
  <div
    class="inquiry h-100 d-flex flex-column justify-center"
  >
    <div
      class="bg-grey-light-3 fs-16 text-white-smoke text-center pa-4 border-8"
    >
      <!-- 在留カードに書いている名前と生年月日を教えてください -->
      <p v-if="idStaff" v-html="$t('tell me your birthday')"></p>
      <p v-if="!idStaff" v-html="$t('tell me name and birthday')"></p>
    </div>

    <div class="mt-5" v-if="!idStaff">
      <input-component
        :value="name"
        :placeholder="$t('alphabet name')"
        :required="true"
        :squareLabeled="true"
        :error="errorName"
        @handle-change="onChangeName"
      />
    </div>
    <div class="mt-4">
      <DatePickerComponent
        :value="birthday"
        :placeholder="$t('birthday')"
        :error="errorBirthday"
        :required="true"
        :squareLabeled="true"
        @changeDate="onChangeBirthday"
      />
    </div>

    <div class="d-flex justify-center text-center my-8">
      <p
        :class="{
          'py-1': $i18n.locale !== 'ja',
          'button-bg-danger': activeNext,
          'button-disabled': !activeNext,
        }"
        @click="activeNext && next()"
        v-html="$t('next')"
      ></p>
    </div>

    <modal-component :modal-data="resultData" ref="result" :max-width="500" />
  </div>
</template>

<script>
import StaffService from "@/services/staff.service.js";
import { REGEX_ALPHABET } from "@/common/constants.js";
import constFunc from "@/common/constFunc.js";

import InputComponent from "@/components/InputComponent.vue";
import DatePickerComponent from "@/components/DatePickerComponent.vue";

export default {
  name: "Inquiry",

  components: {
    InputComponent,
    DatePickerComponent,
  },

  data() {
    return {
      idStaff: this.$route.query.idStaff,
      idShop: this.$route.query.idShop,

      birthday: null,
      name: null,

      errorBirthday: "",
      errorName: "",

      resultData: null,
      isCompanyCheckDone: false,
    };
  },

  computed: {
    activeNext() {
      if (this.idStaff) {
        return this.birthday && !this.errorBirthday;
      }
      return (
        this.birthday && !this.errorBirthday && this.name && !this.errorName
      );
    },
  },

  created() {
    if (!this.idStaff && !this.idShop) {
      this.$router.push({ name: "login" });
      return;
    }
  },

  methods: {
    onChangeBirthday(date) {
      this.birthday = date;
      this.errorBirthday = !constFunc.validateDate(date)
        ? this.$i18n.t("invalid date format")
        : "";
    },

    onChangeName(name) {
      this.name = name.toUpperCase();
      this.errorName =
        (name && !name.match(REGEX_ALPHABET)) || name === ""
          ? this.$i18n.t("need to be alphabet")
          : "";
    },

    next() {
      // call api 従業員の入力データ確認 (comfirm data)
      let data = {
        birthday: this.birthday,
      };

      if (this.idShop) {
        data = {
          ...data,
          idShop: this.idShop,
          staffName: this.name,
        };
      }

      if (this.idStaff) {
        data = {
          ...data,
          idStaff: this.idStaff,
        };
      }

      StaffService.getStaffVerify(data)
        .then((res) => {
          if (res.message) {
            this.resultData = {
              result: res.result,
              message: {
                header: this.$t(res.message.header),
                title: this.$t(res.message.title),
                content: this.$t(res.message.content),
              },
            };
            this.$refs.result.open();
            return;
          }

          this.$emit("changeResidenceCard", res.data);
          this.$emit("changeComponent", "ConfirmCard");
        })
        .catch((err) => {
          this.resultData = {
            result: err.result,
            message: {
              header: this.$t(err.message.header),
              title: this.$t(err.message.title),
              content: this.$t(err.message.content),
            },
          };
          this.$refs.result.open();
        });
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/abstracts/variable.scss";

.inquiry {
  .button-bg-danger,
  .button-disabled {
    padding: 0.9rem 0.4rem 0.1rem 0.4rem;
    font-size: 1.6rem;
    width: 12rem;
  }
}
</style>
