<template>
  <div class="list-filter">
    <div
      :class="['filter', { 'filter-center': isCenter }]"
      v-for="(filter, index) in filters"
      :key="index"
    >
      <div class="hr-filter" v-show="showFull && index > 0">
        <div class="border-bottom-white-grey w-100"></div>
      </div>

      <div class="item" v-for="(item, i) in filter" :key="i">
        <div
          class="item-input"
          v-show="item.type === 'input' && (index === 0 || showFull)"
        >
          <InputComponent
            :name="item.field"
            :value="item.value"
            :placeholder="item.placeholder"
            :delay="true"
            @handleChange="
              $emit('changeInput', { field: item.field, value: $event })
            "
          />
        </div>

        <div
          class="item-input"
          v-if="item.type === 'month' && (index === 0 || showFull)"
        >
          <month-picker-component
            placeholder="提出期限"
            :date-default="item.value"
            :delay="item.delay"
            :typeable="item.typeable"
            :disabled="item.disabled"
            @handle-change="
              $emit('changeInput', { field: item.field, value: $event })
            "
          />
        </div>

        <div
          class="item-input"
          v-if="item.type === 'monthPicker' && (index === 0 || showFull)"
        >
          <DatePickerComponent
            placeholder="提出期限"
            :value="item.value"
            :typeable="item.typeable"
            :disabled="item.disabled"
            :inputFormat="item.inputFormat"
            :minimumView="item.minimumView"
            @changeDate="
              $emit('changeInput', { field: item.field, value: $event })
            "
          />
        </div>

        <div
          class="item-select"
          v-show="item.type === 'select' && (index === 0 || showFull)"
        >
          <PageListFilterPulldownComponent
            v-if="!item.noFilter"
            :items="item.items"
            :itemText="item.itemText"
            :itemValue="item.itemValue"
            :value="item.value"
            :label="item.label"
            :placeholder="item.placeholder"
            :name="item.field"
            @selected="
              $emit('changeInput', { field: item.field, value: $event })
            "
            :noDataText="item.noDataText"
          />

          <PulldownComponent
            v-if="item.noFilter"
            :items="item.items"
            :itemText="item.itemText"
            :itemValue="item.itemValue"
            :value="item.value"
            :label="item.label"
            :placeholder="item.placeholder"
            :name="item.field"
            :showCancel="item.showCancel"
            @change="$emit('changeInput', { field: item.field, value: $event })"
          />
        </div>

        <div
          class="item-option"
          v-show="item.type === 'option' && (index === 0 || showFull)"
        >
          <label class="label">{{ item.label }}</label>
          <div class="list" style="display: flex">
            <label class="list-item" v-for="(option, i) in item.list" :key="i">
              <input
                type="checkbox"
                :name="option.name"
                :checked="option.value"
                @click="
                  $emit('changeOption', {
                    field: option.name,
                    value: $event.target.checked,
                  })
                "
              />
              <span class="option">
                {{ option.label }}
              </span>
            </label>
          </div>
        </div>
      </div>

      <div class="show-full-list" v-show="filters.length > 1 && index === 0">
        <button class="refinement" @click="showFull = !showFull">
          <label>
            <i class="mdi mdi-filter-variant"></i>
            <span v-if="showFull">絞り込みを閉じる</span>
            <span v-else>絞り込みを表示</span>
          </label>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import PageListFilterPulldownComponent from "@/components/forms/PageListFilterPulldownComponent.vue";
import PulldownComponent from "@/components/forms/PulldownComponent.vue";
import InputComponent from "@/components/InputComponent.vue";
import MonthPickerComponent from "@/components/MonthPickerComponent.vue";
import DatePickerComponent from "@/components/DatePickerComponent.vue";

export default {
  name: "PageListFilterComponent",
  components: {
    PageListFilterPulldownComponent,
    PulldownComponent,
    InputComponent,
    MonthPickerComponent,
    DatePickerComponent,
  },

  props: {
    filters: Array,
    isCenter: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    filters: {
      handler() {
        if (this.filters.length >= 2) {
          for (let i = 1; i < this.filters.length; i++) {
            for (let j = 0; j < this.filters[i].length; j++) {
              if (this.filters[i][j].type == "option") {
                this.filters[i][j].list.forEach((el) => {
                  if (el.value) {
                    this.showFull = true;
                    return;
                  }
                });
              }

              if (
                (this.filters[i][j].type == "input" ||
                  this.filters[i][j].type == "select") &&
                this.filters[i][j].value
              ) {
                this.showFull = true;
                return;
              }
            }
          }
        }
      },
      immediate: true,
    },
  },

  data() {
    return {
      showFull: true,
    };
  },
};
</script>
