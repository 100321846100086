<template>
  <div class="card-with-anyone mt-3">
    <div class="text-danger fs-12">
      <a
        class="btn-back"
        @click="
          cardWithAnyone
            ? $emit('changeComponent', 'ConfirmCard')
            : (cardWithAnyone = true)
        "
      >
        <v-icon class="mb-1 mr-n2 mt-1">mdi-menu-left</v-icon>
        <span class="ml-n2" v-html="$t('previous screen')" />
      </a>
    </div>

    <div class="text-center border-8">
      <div v-if="cardWithAnyone" class="residence-card-front">
        <img :src="require(`@/assets/img/residence_card_front.svg`)" />
      </div>

      <img
        v-else
        :src="require(`@/assets/img/certificate.png`)"
        style="width: 250px"
      />
    </div>

    <div
      class="bg-grey-light-3 fs-16 text-white-smoke text-center pa-4 border-8"
    >
      <!-- 在留カードを誰かに預けていますか？ -->
      <div v-if="cardWithAnyone">
        <p class="mb-2" v-html="$t('leave card with anyone?')" />
      </div>

      <!-- 在留カードを預けたときに証明書はもらいましたか？ -->
      <p v-else v-html="$t('got a certificate?')" />
    </div>

    <div class="mt-8 text-center">
      <button
        class="btn w-25 fs-16 h-4 border-8 mr-4"
        v-bind:class="{
          'button-bg-white': !isClickYes,
          'button-danger': isClickYes,
        }"
        @click="btnClick('yes')"
      >
        <div class="text-left ml-2">
          <input type="radio" name="yesNo" value="yes" :checked="isClickYes" />
          <label class="radio-label cursor-pointer">
            <span class="button-text" v-html="$t('yes')" />
          </label>
        </div>
      </button>

      <button
        class="btn w-25 fs-16 border-8 mr-4 mt-2"
        v-bind:class="{
          'button-bg-white': !isClickNo,
          'button-danger': isClickNo,
        }"
        @click="btnClick('no')"
      >
        <div class="text-left ml-2">
          <input type="radio" name="yesNo" value="no" :checked="isClickNo" />
          <label class="radio-label cursor-pointer">
            <span class="button-text" v-html="$t('no')" />
          </label>
        </div>
      </button>

      <button
        :class="[
          'btn w-12 fs-16 mt-6',
          isClickYes || isClickNo ? 'btn-danger' : 'button-disabled',
          { 'h-4': $i18n.locale == 'ja' },
        ]"
        @click="nextAction()"
      >
        <p v-html="$t('next')"></p>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardWithAnyone",

  data() {
    return {
      cardWithAnyone: true,
      isClickYes: false,
      isClickNo: false,
    };
  },

  methods: {
    btnClick(clickedType) {
      if (clickedType === "yes") {
        this.isClickYes = true;
        this.isClickNo = false;
      } else {
        this.isClickYes = false;
        this.isClickNo = true;
      }
    },
    nextAction() {
      if (this.isClickNo) {
        // 在留カードを誰かに預けていますか？ or 在留カードを預けたときに証明書はもらいましたか？
        // 更新が終わったら教えてくださいへ遷移
        this.$emit("changeComponent", "Schedule");
      }

      if (this.isClickYes) {
        // 在留カードを誰かに預けていますか？
        if (this.cardWithAnyone) {
          this.cardWithAnyone = false;
        }
        // 在留カードを預けたときに証明書はもらいましたか？
        else {
          this.$emit("changeComponent", "PhotoCertificate");
        }
      }

      this.isClickYes = false;
      this.isClickNo = false;
    },
  },
};
</script>
