<template>
  <div class="photo-certificate mt-3">
    <div class="text-danger fs-12" v-if="!getImageUrl(step)">
      <a class="btn-back" @click="backStep(step)">
        <v-icon class="mb-1 mr-n2 mt-1">mdi-menu-left</v-icon>
        <span class="ml-n2" v-html="$t('previous screen')" />
      </a>
    </div>
    <div class="text-white-smoke text-center" v-if="!getImageUrl(step)">
      <div class="portrait-mobile image mx-auto">
        <img
          v-if="step == 'isAllCertificates'"
          class="w-100"
          :src="require(`@/assets/img/multi-photo-certificates.png`)"
        />
        <img
          v-else
          class="w-100"
          :src="require(`@/assets/img/upload-photo-certificate.png`)"
        />
      </div>

      <p class="portrait-mobile box mx-auto pa-4">
        <!-- すべての証明書は撮れましたか？ -->
        <span
          v-if="step == 'isAllCertificates'"
          v-html="$t('take all certificates?')"
        />
        <!-- 証明書の内容がしっかり映るように写真を摂ってください -->
        <span v-else v-html="$t('take the photo of certificate')" />
      </p>

      <div
        class="d-flex justify-center px-2 py-7"
        v-if="step != 'isAllCertificates'"
      >
        <label for="file">
          <p
            v-html="`${$t('select a file')} / ${$t('take photo')}`"
            class="button-bg-danger select-take-photo"
            :class="{ 'py-1': $i18n.locale !== 'ja' }"
          ></p>
          <input
            ref="file"
            class="d-none"
            id="file"
            type="file"
            @change="uploadImage('file')"
          />
        </label>
      </div>

      <div v-else class="py-7 text-center">
        <button
          class="btn w-25 fs-16 h-4 border-8 mr-4"
          v-bind:class="{
            'button-bg-white': !isClickYes,
            'button-danger-limit': isClickYes,
          }"
          @click="btnClick('yes')"
        >
          <div class="text-left ml-2">
            <input
              type="radio"
              name="yesNo"
              value="yes"
              :checked="isClickYes"
            />
            <label class="radio-label cursor-pointer">
              <span class="button-text" v-html="$t('yes')" />
            </label>
          </div>
        </button>

        <button
          class="btn w-25 fs-16 border-8 mr-4 mt-2"
          v-bind:class="{
            'button-bg-white': !isClickNo,
            'button-danger-limit': isClickNo,
          }"
          @click="btnClick('no')"
        >
          <div class="text-left ml-2">
            <input type="radio" name="yesNo" value="no" :checked="isClickNo" />
            <label class="radio-label cursor-pointer">
              <span class="button-text" v-html="$t('no')" />
            </label>
          </div>
        </button>

        <button
          :class="[
            'btn w-12 fs-16 mt-6',
            isClickYes || isClickNo ? 'btn-danger' : 'button-disabled',
            { 'h-4': $i18n.locale == 'ja' },
          ]"
          @click="nextAction()"
        >
          <p v-html="$t('next')"></p>
        </button>
      </div>
    </div>

    <div
      v-if="getImageUrl(step)"
      :class="{ 'mt-7': getImageUrl(step) }"
      class="text-center"
    >
      <image-preview-component
        :key="getImageUrl(step)"
        :url="getImageUrl(step)"
        :is-mobile="true"
        :max-width="343"
        :height="213"
      />
      <div class="portrait-mobile box text-white-smoke mx-auto mt-5">
        <p class="pa-4" v-html="$t('clear and readable')"></p>
      </div>

      <div
        class="portrait-mobile d-flex justify-space-between mx-auto px-2 py-7"
      >
        <p
          class="button-danger"
          :class="{ 'py-1': $i18n.locale !== 'ja' }"
          v-html="$t('retake')"
          @click="
            getImageUpload(null, step);
            $emit('saveImageLocal', {
              [step + 'URL']: null,
            });
          "
        ></p>
        <p
          :class="{
            'py-1': $i18n.locale !== 'ja',
            'button-bg-danger': $i18n.locale !== 'ep' && $i18n.locale !== 'my',
            'button-bg-danger-flex':
              $i18n.locale === 'ep' || $i18n.locale === 'my',
          }"
          v-html="$t('next')"
          @click="nextStep(step)"
        ></p>
      </div>
    </div>

    <modal-component
      ref="modalLoading"
      :iconClose="false"
      :isClickOutside="false"
    >
      <template #header> <span v-html="$t('upload image')" /> </template>
      <template #body>
        <div class="modal-body-data fs-14 mx-4 text-white-smoke">
          <div class="modal-body__img">
            <loading-component :loading="true" />
          </div>
          <h1 class="modal-body__title" v-html="$t('image uploading')" />
          <p v-html="$t('wait a moment')" />
        </div>
      </template>
    </modal-component>

    <modal-component :modalData="modalDefault" ref="modalDefault" />
  </div>
</template>

<script>
import { MOBILE_LIST } from "@/common/constants.js";
import EtcService from "@/services/etc.service";

import ImagePreviewComponent from "@/components/ImagePreviewComponent.vue";
import LoadingComponent from "@/components/LoadingComponent.vue";
import ModalComponent from "@/components/ModalComponent.vue";

export default {
  name: "PhotoCertificate",

  components: {
    ImagePreviewComponent,
    LoadingComponent,
    ModalComponent,
  },

  props: ["residenceCard", "idAdmin"],

  data() {
    return {
      step: "residenceCardFront",
      stepList: [
        "residenceCardFront",
        "isAllCertificates",
        "residenceCardBack",
      ],

      residenceCardFrontURL: null,
      residenceCardBackURL: null,

      residenceCardFrontFile: null,
      residenceCardBackFile: null,

      modalDefault: null,

      isClickYes: false,
      isClickNo: false,
    };
  },

  computed: {
    isMobile() {
      return MOBILE_LIST.test(navigator.userAgent);
    },
  },

  methods: {
    getImageUpload(imageUrl, currentStep) {
      switch (currentStep) {
        case "residenceCardFront":
          this.residenceCardFrontURL = imageUrl;
          break;
        case "residenceCardBack":
          this.residenceCardBackURL = imageUrl;
          break;
      }
    },

    getFileUpload(file, currentStep) {
      switch (currentStep) {
        case "residenceCardFront":
          this.residenceCardFrontFile = file;
          break;
        case "residenceCardBack":
          this.residenceCardBackFile = file;
          break;
      }
    },

    getImageUrl(currentStep) {
      switch (currentStep) {
        case "residenceCardFront":
          return this.residenceCardFrontURL;
        case "residenceCardBack":
          return this.residenceCardBackURL;
      }
    },

    getImageFile(currentStep) {
      switch (currentStep) {
        case "residenceCardFront":
          return this.residenceCardFrontFile;
        case "residenceCardBack":
          return this.residenceCardBackFile;
      }
    },

    backStep(currentStep) {
      let index = this.stepList.indexOf(currentStep) - 1;

      if (index < 0) {
        this.$emit("changeComponent", "CardWithAnyone");
        return;
      }

      this.step = this.stepList[index];
    },

    nextStep(currentStep) {
      if (this.step == "isAllCertificates") {
        this.step = "residenceCardBack";
        return;
      }

      if (
        this.step == "residenceCardFront" ||
        this.step == "residenceCardBack"
      ) {
        this.$refs.modalLoading.open();
      }

      let formData = new FormData();
      formData.append("file", this.getImageFile(currentStep));
      formData.append("type", `${currentStep}URL`);
      formData.append("idStaff", this.residenceCard.idStaff);
      formData.append("idCompany", this.residenceCard.idCompany);

      if (this.idAdmin) {
        formData.append("idAdmin", this.idAdmin);
      }

      formData.append("path", this.$route.path);

      EtcService.postPictureUploadAny(formData)
        .then((res) => {
          this.$refs.modalLoading.close();

          this.$emit("changeResidenceCard", {
            [currentStep + "URL"]: res.data,
          });

          this.$emit("saveImageLocal", {
            [currentStep + "URL"]: this[currentStep + "URL"],
          });

          this.nextScreen(currentStep);
        })
        .catch((err) => {
          this.$refs.modalLoading.close();
          this.modalDefault = err;
          this.$refs.modalDefault.open();
        });
    },

    // next to screen
    nextScreen(currentStep) {
      let index = this.stepList.indexOf(currentStep) + 1;
      if (index == this.stepList.length) {
        this.$emit("changeComponent", "ConfirmCertificate");
        return;
      }

      this.step = this.stepList[index];
    },

    uploadImage(refs) {
      const file = this.$refs[refs].files[0];
      this.getFileUpload(file, this.step);

      const reader = new FileReader();
      reader.addEventListener("load", () => {
        this.getImageUpload(reader.result.toString(), this.step);
      });
      if (file) {
        reader.readAsDataURL(file);
      }
    },

    btnClick(clickedType) {
      if (clickedType === "yes") {
        this.isClickYes = true;
        this.isClickNo = false;
      } else {
        this.isClickYes = false;
        this.isClickNo = true;
      }
    },
    nextAction() {
      if (this.isClickNo) {
        this.nextStep(this.step);
      }

      if (this.isClickYes) {
        this.$emit("changeComponent", "ConfirmCertificate");
      }

      this.isClickYes = false;
      this.isClickNo = false;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/abstracts/mixin.scss";
@import "@/assets/scss/abstracts/variable.scss";

.photo-certificate {
  .take-photo-section {
    @include respond(portrait-mobile) {
      margin-top: 2.7rem;
    }
    margin-top: -1.6rem;

    .guide-take-photo {
      @include respond(portrait-mobile) {
        margin: 2rem;
      }
    }
  }

  .button-danger,
  .button-bg-danger {
    padding: 0.8rem 0.4rem 0rem 0.4rem;
    font-size: 1.6rem;
    width: 15rem;
    cursor: pointer;
  }

  .select-take-photo {
    width: 25rem !important;
  }

  .box {
    background: $color-grey-light-v3;
    border-radius: 0.8rem;
  }
}

.button-danger-limit {
  display: inline-block;
  padding: 0.4rem 1.6rem;
  font-size: 1.2rem;
  font-weight: 500;
  background-color: $color-white;
  color: $color-danger;
  border: 0.1rem solid $color-danger;
  border-radius: 0.8rem;
  user-select: none;
  cursor: pointer;
}
</style>
