import { createApp } from "vue";
import { createI18n } from "vue-i18n";
import vuetify from "./plugins/vuetify";
import { createGtm } from "vue-gtm";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/assets/scss/main.scss";
import { registerGlobalComponent } from "./globalComponent";

const messages = {
  ja: require("./locales/ja.json"), // 日本語
  en: require("./locales/en.json"), // English
  kr: require("./locales/kr.json"), // 한국어
  np: require("./locales/np.json"), // नेपाली
  pr: require("./locales/pr.json"), // Português
  sc: require("./locales/sc.json"), // 简体中文
  tc: require("./locales/tc.json"), // 繁體中文
  tg: require("./locales/tg.json"), // Tagalog
  vi: require("./locales/vi.json"), // Tiếng Việt
  my: require("./locales/my.json"), // မြန်မာဘာသာစကား (burmese)
  ep: require("./locales/ep.json"), // Español(Spanish)
  id: require("./locales/id.json"), // Indonesian
};

const i18n = createI18n({
  locale: "ja", // set locale
  fallbackLocale: "en",
  warnHtmlInMessage: "off",
  messages, // set locale messages)
});

let app = createApp(App);
registerGlobalComponent(app);
app.use(router);
app.use(store);
app.use(vuetify);
app.use(i18n);

if (process.env.NODE_ENV === "production") {
  app.use(
    createGtm({
      id: "GTM-PWG8LPQK",
      debug: false,
      vueRouter: router,
      trackOnNextTick: false,
    })
  );
}

app.mount("#app");
