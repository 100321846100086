const state = () => ({
  accountAdmin: null,
});

const mutations = {
  setStateAccountAdmin(state, data) {
    state.accountAdmin = data;
  },
};

const actions = {
  saveAccountAdmin({ commit }, data) {
    commit("setStateAccountAdmin", data);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
