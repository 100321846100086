<template>
  <div :key="resetData" class="h-100">
    <keep-alive>
      <component
        v-bind:is="currentComponent"
        :staffUploadDocumentInfo="staffUploadDocumentInfo"
        :requestData="requestData"
        :idAdmin="adminInfoState ? adminInfoState.idAdmin : null"
        :imageUrlLocal="imageUrlLocal"
        @changeComponent="currentComponent = $event"
        @changeStaffUploadDocument="staffUploadDocumentInfo = $event"
        @changeRequestData="onChangeRequestData"
        @changeCustomizedRequestData="onChangeCustomizedRequestData"
        @saveImageLocal="saveImageLocal"
        @resetRequestUploadDocument="resetRequestUploadDocument"
      />
    </keep-alive>
  </div>
</template>

<script>
import RequestDataInfo from "./request-data-info.vue";
import UploadPhoto from "./upload-photo.vue";
import Signature from "./signature.vue";
import ConfirmInfo from "./confirm-info.vue";
import SessionStorage from "@/common/sessionStorage";

export default {
  components: {
    RequestDataInfo,
    UploadPhoto,
    Signature,
    ConfirmInfo,
  },

  data() {
    return {
      currentComponent: "RequestDataInfo",
      staffUploadDocumentInfo: null,
      adminInfoState: JSON.parse(SessionStorage.getData("adminInfo")),
      requestData: null,
      imageUrlLocal: null,
      resetData: 0,
    };
  },

  methods: {
    // change data residence card
    onChangeRequestData(data) {
      this.requestData = {
        ...this.requestData,
        ...data,
      };
    },

    //change customized request data
    onChangeCustomizedRequestData(data) {
      this.requestData.customizedItems.find(
        (o) => o.nameItem == data.nameItem
      ).valueItem = data.valueItem;
    },

    // save imageURL to show final screen
    saveImageLocal(data) {
      this.imageUrlLocal = {
        ...this.imageUrlLocal,
        ...data,
      };
    },

    // reset data residence card null
    resetRequestUploadDocument() {
      this.resetData++;
      this.requestData = null;
      this.imageUrlLocal = null;
    },
  },
};
</script>
