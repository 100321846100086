<template>
  <div class="confirm-certificate mt-5" v-if="residenceCard">
    <div class="text-danger fs-12">
      <a class="btn-back" @click="$emit('changeComponent', 'PhotoCertificate')">
        <v-icon class="mb-1 mr-n2 mt-1">mdi-menu-left</v-icon>
        <span class="ml-n2" v-html="$t('previous screen')" />
      </a>
    </div>
    <div
      class="bg-grey-light-3 fs-16 text-white-smoke text-center py-5 border-8"
    >
      <!-- 写真の文字がちゃんと読めるか確認してください -->
      <p v-html="$t('clear and readable')" />
    </div>

    <div class="mt-5" v-if="imageUrlLocal.residenceCardFrontURL">
      <p v-html="$t('first')" class="pb-4 text-white-smoke" />
      <image-preview-component
        :url="imageUrlLocal.residenceCardFrontURL"
        :height="213"
        :isMobile="true"
      />
    </div>

    <div class="mt-5" v-if="imageUrlLocal.residenceCardBackURL">
      <p v-html="$t('second')" class="pb-4 text-white-smoke" />
      <image-preview-component
        :url="imageUrlLocal.residenceCardBackURL"
        :height="213"
        :isMobile="true"
      />
    </div>

    <div class="mt-6 d-flex justify-center">
      <button
        class="btn btn-danger w-25 fs-16 border-8 h-4"
        @click="submitResidenceCard"
      >
        <p v-html="$t('submit')" />
      </button>
    </div>

    <modal-component
      :modalData="modalDefault"
      ref="modalDefault"
      @close="
        isSubmitSuccess &&
          ($emit('changeComponent', 'Inquiry'), $emit('resetDataResidenceCard'))
      "
    />
  </div>
</template>

<script>
import utilFunc from "@/common/utilFunc.js";
import StaffService from "@/services/staff.service";

import ImagePreviewComponent from "@/components/ImagePreviewComponent.vue";
import ModalComponent from "@/components/ModalComponent.vue";

export default {
  name: "ConfirmCertificate",

  components: {
    ImagePreviewComponent,
    ModalComponent,
  },

  props: ["residenceCard", "imageUrlLocal"],

  data() {
    return {
      utilFunc: utilFunc,

      modalDefault: null,
      isSubmitSuccess: false,
    };
  },

  methods: {
    // call api 更新在留カード提出
    submitResidenceCard() {
      const idStaff = this.residenceCard.idStaff;

      let payload = {
        idCompany: this.residenceCard.idCompany,
        nameStaffRegister: this.residenceCard.nameStaffRegister,
        emailStaff: this.residenceCard.emailStaff,
        phoneNumber: this.residenceCard.phoneNumber,
        visaTypeRegister: this.residenceCard.visaTypeRegister,
        residenceCardFrontURL: this.residenceCard.residenceCardFrontURL,
        residenceCardBackURL: this.residenceCard.residenceCardBackURL,
        isAzukarisho: true,
        isCertificate: true,
      };

      StaffService.postResidenceCard(idStaff, payload)
        .then((res) => {
          this.isSubmitSuccess = true;
          this.modalDefault = {
            result: res.result,
            message: {
              header: this.$t(res.message.header),
              title: this.$t(res.message.title),
              content: this.$t(res.message.content),
            },
          };
        })
        .catch((err) => {
          this.isSubmitSuccess = false;
          this.modalDefault = {
            result: err.result,
            message: {
              header: this.$t(err.message.header),
              title: this.$t(err.message.title),
              content: this.$t(err.message.content),
            },
          };
        })
        .finally(() => {
          this.$refs.modalDefault.open();
        });
    },
  },
};
</script>
