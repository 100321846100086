<template>
  <v-app id="inspire">
    <component :is="layout">
      <router-view></router-view>
    </component>

    <div v-if="layout != 'auth-layout' && layout != 'mobile-layout'">
      <modal-component
        ref="modalWarningTimeout"
        :max-width="500"
        @close="$router.push({ name: 'login' })"
      >
        <template #header>セッションタイムアウト</template>
        <template #body>
          <img class="w-12" :src="require(`@/assets/img/alert.svg`)" />
          <p class="text-danger text-bold fs-16">
            セッションの時間が切れました
          </p>
          <p class="text-white-smoke fs-14">
            一定時間操作がなかったため、ログアウトします
          </p>
        </template>
        <template #footer>
          <div class="text-center">
            <v-btn
              class="fs-16 w-13 border-8 font-weight-medium"
              variant="outlined"
              :color="colorConst.SET"
              @click="$router.push({ name: 'login' })"
            >
              閉じる
            </v-btn>
          </div>
        </template>
      </modal-component>
    </div>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import { PUBLIC_LAYOUT, BUTTON_COLOR } from "@/common/constants";

export default {
  name: "App",

  data() {
    return {
      colorConst: BUTTON_COLOR,
    };
  },

  computed: {
    ...mapState(["isSessionError"]),

    layout() {
      return (this.$route.meta.layout || PUBLIC_LAYOUT) + "-layout";
    },
  },

  watch: {
    isSessionError: function () {
      if (
        this.isSessionError &&
        this.layout != "auth-layout" &&
        this.layout != "mobile-layout"
      ) {
        this.$refs.modalWarningTimeout.open();
      }
    },
  },
};
</script>
