<template>
  <div>
    <div
      v-if="isGetShopStaffAddInfoDone"
      :class="[
        'info-residence-card',
        shopStaffAddInfo && shopStaffAddInfo.isAddable
          ? 'mt-5'
          : 'd-flex h-100',
      ]"
    >
      <div v-if="shopStaffAddInfo && shopStaffAddInfo.isAddable">
        <!-- 在留カード番号を教おしえてください -->
        <div v-if="!checkResidenceCardNumberDone && !cardId">
          <div class="text-center border-8">
            <img
              :src="require(`@/assets/img/zaimane_enter_card_number.svg`)"
              style="width: 270px"
            />
          </div>

          <div
            class="
              bg-grey-light-3
              fs-16
              text-white-smoke text-center
              py-7
              px-5
              border-8
            "
          >
            <p v-html="$t('tell me your residence card number')" />
          </div>

          <div class="mt-5">
            <input-component
              :value="residenceCardNumber"
              :error="$t(errorResidenceCardNumber)"
              :placeholder="$t('residence card number')"
              :required="true"
              :squareLabeled="true"
              :maxlength="12"
              @handle-change="onChangeResidenceCardNumber($event)"
            />
          </div>

          <div class="mt-8 text-center">
            <button
              :class="[
                'btn w-12 fs-16',
                residenceCardNumberValidated
                  ? ' btn-danger'
                  : 'button-disabled',
                { 'h-4': $i18n.locale == 'ja' },
              ]"
              :disabled="!residenceCardNumberValidated"
              @click="onCheckResidenceCardNumber"
            >
              <p v-html="$t('next')"></p>
            </button>
          </div>
        </div>

        <div v-else>
          <!-- あなたについて教おしえてください -->
          <div class="mt-3" v-if="!cardId">
            <div class="text-danger fs-12">
              <a class="btn-back" @click="handleGoBack">
                <v-icon class="mb-1 mr-n2 mt-1">mdi-menu-left</v-icon>
                <span class="ml-n2" v-html="$t('previous screen')" />
              </a>
            </div>
          </div>

          <div
            class="
              bg-grey-light-3
              fs-16
              text-white-smoke text-center
              py-5
              border-8
            "
          >
            <p v-html="$t('about you')" />
          </div>

          <div class="mt-4" v-if="requiredItemShow.startWorkingDateRequirement">
            <DatePickerComponent
              :value="startWorkingDate"
              :placeholder="$t('start working date')"
              :squareLabeled="true"
              :required="requiredItems.startWorkingDateRequirement"
              :error="$t(errorStartWorkingDate)"
              @changeDate="onChangeStartWorkingDate($event)"
            />
          </div>

          <div class="mt-5" v-if="requiredItemShow.staffidRequirement">
            <input-component
              :value="staffID"
              :placeholder="$t('employee ID')"
              :squareLabeled="true"
              :required="requiredItems.staffidRequirement"
              @handle-change="staffID = $event"
            />
          </div>

          <div
            class="mt-5"
            v-if="requiredItemShow.nameStaffRegisterRequirement"
          >
            <input-component
              :value="nameStaffRegister"
              :placeholder="$t('katakana name')"
              :squareLabeled="true"
              :required="requiredItems.nameStaffRegisterRequirement"
              :error="$t(errorName)"
              @handle-change="onChangeName"
            />
          </div>

          <div class="mt-5" v-if="requiredItemShow.emailStaffRequirement">
            <input-component
              :value="emailStaff"
              :placeholder="$t('email address')"
              :squareLabeled="true"
              :required="requiredItems.emailStaffRequirement"
              :error="$t(errorEmailStaff)"
              @handle-change="onChangeEmailStaff"
            />
          </div>

          <div class="mt-5" v-if="requiredItemShow.phoneNumberRequirement">
            <input-component
              :value="phoneNumber"
              :squareLabeled="true"
              :required="requiredItems.phoneNumberRequirement"
              :placeholder="$t('phone number')"
              @handle-change="phoneNumber = $event"
            />
          </div>

          <div
            class="mt-5"
            v-for="(item, index) in customizedString"
            :key="index"
          >
            <div
              v-if="
                requiredItemShow.customizedItemsRequirement.includes(
                  item.idCompanySetting
                )
              "
            >
              <input-component
                :placeholder="item.nameItem"
                :squareLabeled="true"
                :required="
                  requiredItems.customizedItemsRequirement.includes(
                    item.idCompanySetting
                  )
                "
                :value="item.valueItem"
                @handle-change="item.valueItem = $event"
              />
            </div>
          </div>

          <div class="mt-8 text-center">
            <button
              :class="[
                'btn w-12 fs-16',
                activeBtnNext ? 'btn-danger' : 'button-disabled',
                { 'h-4': $i18n.locale == 'ja' },
              ]"
              :disabled="!activeBtnNext"
              @click="next"
            >
              <p v-html="$t('next')"></p>
            </button>
          </div>
        </div>
      </div>
      <div
        v-else-if="shopStaffAddInfo && !shopStaffAddInfo.isAddable"
        class="text-center ma-auto"
      >
        <img class="w-12" :src="require(`@/assets/img/alert.svg`)" />

        <p class="text-color-grey text-left mt-7">
          登録従業員の上限に達しているため、新たに従業員を追加することはできません。
          <br />
          <br />
          アドミンアカウントをお持ちの担当者様に現在ご利用中のプランについてご確認ください。
        </p>
      </div>
    </div>

    <modal-component
      :modal-data="modalData"
      ref="modalComponent"
      :max-width="500"
    >
    </modal-component>
  </div>
</template>

<script>
import { REGEX_KATAKANA_FULLSIZE, REQUIRED_ITEM } from "@/common/constants.js";
import constFunc from "@/common/constFunc.js";
import utilFunc from "@/common/utilFunc.js";

import InputComponent from "@/components/InputComponent.vue";
import DatePickerComponent from "@/components/DatePickerComponent.vue";
import ModalComponent from "@/components/ModalComponent.vue";
import ShopService from "@/services/shop.service";
import StaffService from "@/services/staff.service";

export default {
  name: "BasicInfo",

  components: {
    InputComponent,
    DatePickerComponent,
    ModalComponent,
  },

  data() {
    return {
      residenceCardNumber: null,
      errorResidenceCardNumber: "",
      checkResidenceCardNumberDone: false,
      modalData: {},

      idShop: this.$route.query.idShop,
      shopStaffAddInfo: null,

      startWorkingDate: null,
      staffID: null,
      nameStaffRegister: null,
      emailStaff: null,
      phoneNumber: null,
      customizedItems: [],
      customizedString: [],

      errorStartWorkingDate: "",
      errorName: "",
      errorEmailStaff: "",
      isGetShopStaffAddInfoDone: false,
      requiredItems: {
        startWorkingDateRequirement: false,
        staffidRequirement: false,
        nameStaffRegisterRequirement: false,
        emailStaffRequirement: false,
        phoneNumberRequirement: false,
        customizedItemsRequirement: [],
      },
      requiredItemShow: {
        startWorkingDateRequirement: false,
        staffidRequirement: false,
        nameStaffRegisterRequirement: false,
        emailStaffRequirement: false,
        phoneNumberRequirement: false,
        passportRequirement: false,
        studentCardRequirement: false,
        graduationDateRequirement: false,
        studentCardExpiredDateRequirement: false,
        customizedItemsRequirement: [],
      },
    };
  },

  computed: {
    cardId: function () {
      return this.$route.query.cardId;
    },

    residenceCardNumberValidated() {
      if (!this.residenceCardNumber) {
        return false;
      }

      if (this.errorResidenceCardNumber !== "") {
        return false;
      }

      return true;
    },

    activeBtnNext() {
      if (
        this.errorStartWorkingDate !== "" ||
        this.errorName !== "" ||
        this.errorEmailStaff !== ""
      ) {
        return false;
      }

      if (
        this.requiredItems.startWorkingDateRequirement &&
        (this.startWorkingDate === "" || this.startWorkingDate === null)
      ) {
        return false;
      }
      if (
        this.requiredItems.staffidRequirement &&
        (this.staffID === "" || this.staffID === null)
      ) {
        return false;
      }
      if (
        this.requiredItems.nameStaffRegisterRequirement &&
        (this.nameStaffRegister === "" || this.nameStaffRegister === null)
      ) {
        return false;
      }
      if (
        this.requiredItems.emailStaffRequirement &&
        (this.emailStaff === "" || this.emailStaff === null)
      ) {
        return false;
      }
      if (
        this.requiredItems.phoneNumberRequirement &&
        (this.phoneNumber === "" || this.phoneNumber === null)
      ) {
        return false;
      }

      let isValueCustomizedItems = true;
      this.customizedString.forEach((el) => {
        if (el.validation === REQUIRED_ITEM.REQUIRED && !el.valueItem) {
          isValueCustomizedItems = false;
          return;
        }
      });

      if (!isValueCustomizedItems) {
        return false;
      }

      return true;
    },
  },

  created() {
    this.onGetShopStaffAddInfo();
  },

  methods: {
    onGetShopStaffAddInfo() {
      const params = {
        idShopHash: this.idShop,
      };
      ShopService.getShopStaffAddInfo(params)
        .then((res) => {
          this.shopStaffAddInfo = res.data;

          this.requiredItems.startWorkingDateRequirement =
            this.shopStaffAddInfo.startWorkingDateRequirement ===
            REQUIRED_ITEM.REQUIRED;
          this.requiredItems.staffidRequirement =
            this.shopStaffAddInfo.staffidRequirement === REQUIRED_ITEM.REQUIRED;
          this.requiredItems.nameStaffRegisterRequirement =
            this.shopStaffAddInfo.nameStaffRegisterRequirement ===
            REQUIRED_ITEM.REQUIRED;
          this.requiredItems.emailStaffRequirement =
            this.shopStaffAddInfo.emailStaffRequirement ===
            REQUIRED_ITEM.REQUIRED;
          this.requiredItems.phoneNumberRequirement =
            this.shopStaffAddInfo.phoneNumberRequirement ===
            REQUIRED_ITEM.REQUIRED;

          this.requiredItemShow.startWorkingDateRequirement =
            this.shopStaffAddInfo.startWorkingDateRequirement !==
            REQUIRED_ITEM.HIDDEN;
          this.requiredItemShow.staffidRequirement =
            this.shopStaffAddInfo.staffidRequirement !== REQUIRED_ITEM.HIDDEN;
          this.requiredItemShow.nameStaffRegisterRequirement =
            this.shopStaffAddInfo.nameStaffRegisterRequirement !==
            REQUIRED_ITEM.HIDDEN;
          this.requiredItemShow.emailStaffRequirement =
            this.shopStaffAddInfo.emailStaffRequirement !==
            REQUIRED_ITEM.HIDDEN;
          this.requiredItemShow.phoneNumberRequirement =
            this.shopStaffAddInfo.phoneNumberRequirement !==
            REQUIRED_ITEM.HIDDEN;

          this.requiredItemShow.passportRequirement =
            this.shopStaffAddInfo.passportRequirement !== REQUIRED_ITEM.HIDDEN;
          this.requiredItemShow.studentCardRequirement =
            this.shopStaffAddInfo.studentCardRequirement !==
            REQUIRED_ITEM.HIDDEN;
          this.requiredItemShow.graduationDateRequirement =
            this.shopStaffAddInfo.graduationDateRequirement !==
            REQUIRED_ITEM.HIDDEN;
          this.requiredItemShow.studentCardExpiredDateRequirement =
            this.shopStaffAddInfo.studentCardExpiredDateRequirement !==
            REQUIRED_ITEM.HIDDEN;

          this.customizedItems = this.shopStaffAddInfo.companySettings
            .filter((o) => o.validation !== REQUIRED_ITEM.HIDDEN)
            .map((item) => ({
              idCompanySetting: item.id_company_setting,
              nameItem: item.name,
              valueItem: null,
              typeItem: item.type,
              validation: item.validation,
            }));

          this.customizedString = this.customizedItems.filter(
            (o) => o.typeItem === "string"
          );

          this.customizedString.forEach((el) => {
            if (el.validation === REQUIRED_ITEM.REQUIRED) {
              this.requiredItems.customizedItemsRequirement.push(
                el.idCompanySetting
              );
            }
            if (el.validation !== REQUIRED_ITEM.HIDDEN) {
              this.requiredItemShow.customizedItemsRequirement.push(
                el.idCompanySetting
              );
            }
          });

          this.$emit("changeRequiredItemShow", this.requiredItemShow);
          this.$emit("changeShopStaffAddInfo", this.shopStaffAddInfo);

          this.$emit("changeResidenceCard", {
            idShop: Number(this.shopStaffAddInfo.idShop),
            idCompany: this.shopStaffAddInfo.idCompany,
            customizedItems: this.customizedItems,
            residenceCardNumber: this.residenceCardNumber,
            residenceCardNumberHash: this.cardId || null,
          });

          if (this.shopStaffAddInfo && !this.shopStaffAddInfo.isAddable) {
            this.hideLanguageAndFooter();
          }
        })
        .catch((err) => {
          this.modalData = {
            result: err.result,
            message: {
              header: this.$t(err.message.header),
              title: this.$t(err.message.title),
              content: this.$t(err.message.content),
            },
          };
          this.$refs.modalComponent.open();
        })
        .finally(() => {
          this.isGetShopStaffAddInfoDone = true;
        });
    },

    onChangeResidenceCardNumber(residenceCardNumber) {
      this.residenceCardNumber = residenceCardNumber;

      if (
        residenceCardNumber &&
        !residenceCardNumber.match(/^[a-zA-Z]{2}\d{8}[a-zA-Z]{2}$/)
      ) {
        this.errorResidenceCardNumber = "invalid residence card number";
        return;
      }

      this.errorResidenceCardNumber = "";
    },

    onCheckResidenceCardNumber() {
      let payload = {
        idShopHash: this.idShop,
        residenceCardNumber: this.residenceCardNumber.toUpperCase(),
      };

      StaffService.checkResidenceCardNumber(payload)
        .then(() => {
          this.checkResidenceCardNumberDone = true;
          this.$emit("changeResidenceCard", {
            residenceCardNumber: this.residenceCardNumber.toUpperCase(),
          });

          if (
            !this.requiredItemShow.startWorkingDateRequirement &&
            !this.requiredItemShow.staffidRequirement &&
            !this.requiredItemShow.nameStaffRegisterRequirement &&
            !this.requiredItemShow.emailStaffRequirement &&
            !this.requiredItemShow.phoneNumberRequirement &&
            this.requiredItemShow.customizedItemsRequirement.length === 0
          ) {
            this.next();
          }
        })
        .catch((err) => {
          this.modalData = {
            result: err.result,
            message: {
              header: this.$t(err.message.header),
              title: this.$t(err.message.title),
              content: this.$t(err.message.content),
            },
          };
          this.$refs.modalComponent.open();
        });
    },

    handleGoBack() {
      this.checkResidenceCardNumberDone = false;
      this.$emit("changeResidenceCard", {
        residenceCardNumber: null,
      });
    },

    onChangeStartWorkingDate(date) {
      this.startWorkingDate = date;

      if (date && !constFunc.validateDate(date)) {
        this.errorStartWorkingDate = "invalid date format";
        return;
      }

      this.errorStartWorkingDate = "";
    },

    onChangeName(nameStaffRegister) {
      this.nameStaffRegister = nameStaffRegister;

      if (
        nameStaffRegister &&
        !nameStaffRegister.match(REGEX_KATAKANA_FULLSIZE)
      ) {
        this.errorName = "need to be katakana";
        return;
      }

      this.errorName = "";
    },

    onChangeEmailStaff(emailStaff) {
      this.emailStaff = emailStaff;

      if (emailStaff && !utilFunc.validateEmail(emailStaff)) {
        this.errorEmailStaff = "invalid email format";
        return;
      }

      this.errorEmailStaff = "";
    },

    next() {
      this.$emit("changeComponent", "VisaStatus");

      this.$emit("changeResidenceCard", {
        startWorkingDate: this.startWorkingDate,
        staffID: this.staffID,
        nameStaffRegister: this.nameStaffRegister,
        emailStaff: this.emailStaff,
        phoneNumber: this.phoneNumber,
      });

      this.customizedString.forEach((item) =>
        this.$emit("changeCustomizedResidenceCard", item)
      );
    },

    hideLanguageAndFooter() {
      document.querySelector("#language").className = "d-none";
      document.querySelector("#footer").className = "d-none";
    },
  },
};
</script>
