<template>
  <div class="signature-staff mt-3">
    <div class="text-danger fs-12">
      <a class="btn-back" @click="$emit('changeComponent', 'UploadPhoto')">
        <v-icon class="mb-1 mr-n2 mt-1">mdi-menu-left</v-icon>
        <span class="ml-n2" v-html="$t('previous screen')" />
      </a>
    </div>
    <div
      class="bg-grey-light-3 fs-16 text-white-smoke text-center pa-4 border-8"
    >
      <p v-html="$t('agreement')" />
    </div>

    <div class="privacy-pledge mt-3 fs-16 text-danger text-center">
      <div class="px-1 fs-14 text-white-smoke text-left">
        <p v-html="$t('contract3')" />
        <p v-html="$t('contract4')" />
        <p v-html="$t('contract5')" />

        <br />
        <b v-html="$t('contract13')" /><br />
        <p v-html="$t('contract6')" />

        <br />
        <b v-html="$t('contract14')" /><br />
        <p v-html="$t('contract8')" />
        <p v-html="$t('contract7')" />
      </div>
    </div>

    <div class="mt-2 fs-12 text-center text-white-smoke">
      <SignatureComponent
        @signture="signatureURL = $event"
        @signture-file="signatureFile = $event"
      />
    </div>

    <div class="py-7 text-center">
      <button
        :class="[
          'btn w-12 fs-16  border-8',
          signatureURL ? ' btn-danger' : 'button-disabled',
          { 'h-4': $i18n.locale == 'ja' },
        ]"
        :disabled="!signatureURL"
        @click="nextScreen"
      >
        <p v-html="$t('next')" />
      </button>
    </div>

    <ModalComponent ref="modalHandleInfo" :max-width="500">
      <template #header> 個人情報の取り扱いについて </template>

      <template #body>
        <div class="modal-body-data pl-7 pr-4 fs-14 text-white-smoke">
          <div class="text-left">
            <ol>
              <li>
                <p>当社では以下の目的で個人情報を利用します。</p>
                <ol type="i" class="pl-4">
                  <li>
                    当社及び当社グループ会社のサービスに関する情報及びアンケートの配信のため
                  </li>
                  <li>各種お問い合わせへの対応のため</li>
                  <li>統計データの作成のため</li>
                </ol>
              </li>

              <li class="mt-3">
                当社は、個人情報を以下の場合を除くほか、本人の同意なしに第三者へ提供することはありません。
                <ol type="i" class="pl-4">
                  <li>
                    あらかじめ、ご本人に必要事項を明示又は通知し、同意を得ている場合
                  </li>
                  <li>法令に基づく場合</li>
                  <li>
                    本人の生命、身体又は財産の保護のために必要がある場合であって、本人の同意を得ることが困難な場合
                  </li>
                  <li>
                    公衆衛生の向上又は児童の健全な育成推進のために必要である場合で、本人の同意を得ることが困難な場合
                  </li>
                  <li>
                    国の機関若しくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがある場合
                  </li>
                </ol>
              </li>

              <li class="mt-3">
                当社が定める個人情報保護の観点から一定水準以上であると認めた委託先にデータ処理業務の一部を委託する場合があります。また、当社がデータ処理業務を外部へ委託するにあたり、個人情報を日本国外のサーバに移転・保存し、データ処理を行うことがあります。
              </li>

              <li class="mt-3">
                当社ホームページではより便利に利用していただく等のため、クッキー（Cookie）を使用する場合がありますが、クッキーによって個人を特定できる情報を得ることはありません。
              </li>

              <li class="mt-3">
                当社では、付加価値の高いサービスを展開するために、以下の範囲内で必要な場合に限り、厳格な管理・監督のもとに個人情報を当社グループ会社において共同利用する場合があります。
                <ol type="i" class="pl-4">
                  <li>共同利用の範囲 当社グループ会社</li>
                  <li>共同利用の目的 各種お問い合わせへの対応のため</li>
                  <li>
                    共同利用の責任者 株式会社ウィルグループ 人事本部本部長 土屋
                    恵美子
                  </li>
                </ol>
              </li>

              <li class="mt-3">
                当社への個人情報の利用目的の通知、開示、内容の訂正、追加または削除、利用の停止、消去及び第三者への提供の停止、個人情報の取扱いに関する苦情は、以下の連絡先までご連絡ください。
              </li>
            </ol>
          </div>
        </div>
      </template>
    </ModalComponent>

    <ModalComponent ref="modalRestrictions" :max-width="500">
      <template #header> 誓約事項 </template>

      <template #body>
        <div class="modal-body-data px-7 fs-14 text-white-smoke text-left">
          <p v-html="$t('contract3')" />
          <p v-html="$t('contract4')" />
          <p v-html="$t('contract5')" />

          <br />
          <b v-html="$t('contract13')" /><br />
          <p v-html="$t('contract6')" />

          <br />
          <b v-html="$t('contract14')" /><br />
          <p v-html="$t('contract8')" />
          <p v-html="$t('contract7')" />
        </div>
      </template>
    </ModalComponent>

    <modal-component ref="modalLoading">
      <template #header> <span v-html="$t('upload image')" /> </template>
      <template #body>
        <div class="modal-body-data fs-14 mx-4 text-white-smoke">
          <div class="modal-body__img">
            <loading-component :loading="true" />
          </div>
          <h1 class="modal-body__title" v-html="$t('image uploading')" />
          <p v-html="$t('wait a moment')" />
        </div>
      </template>
    </modal-component>

    <modal-component :modalData="modalDefault" ref="modalDefault" />
  </div>
</template>

<script>
import EtcService from "@/services/etc.service";

import ModalComponent from "@/components/ModalComponent.vue";
import SignatureComponent from "@/components/SignatureComponent.vue";
import LoadingComponent from "@/components/LoadingComponent.vue";

export default {
  name: "Signature",

  components: {
    ModalComponent,
    LoadingComponent,
    SignatureComponent,
  },

  props: ["staffUploadDocumentInfo", "idAdmin"],

  data() {
    return {
      signatureURL: null,
      signatureFile: null,

      modalDefault: null,
    };
  },

  methods: {
    nextScreen() {
      this.$refs.modalLoading.open();

      let formData = new FormData();
      formData.append("file", this.signatureFile);
      formData.append("type", "signatureURL");
      formData.append("idStaff", this.staffUploadDocumentInfo.idStaff);
      formData.append("idCompany", this.staffUploadDocumentInfo.idCompany);
      formData.append("path", this.$route.path);
      if (this.idAdmin) {
        formData.append("idAdmin", this.idAdmin);
      }

      EtcService.postPictureUploadAny(formData)
        .then((res) => {
          this.$refs.modalLoading.close();
          this.$emit("changeRequestData", {
            signature: res.data,
          });

          this.$emit("saveImageLocal", {
            signatureURL: this.signatureURL,
          });

          this.$emit("changeComponent", "ConfirmInfo");
        })
        .catch((err) => {
          this.$refs.modalLoading.close();
          this.modalDefault = err;
          this.$refs.modalDefault.open();
        });
    },
  },
};
</script>
