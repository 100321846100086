<template>
  <div class="confirm-info mt-5" v-if="residenceCard">
    <div
      class="
        bg-grey-light-3
        fs-16
        text-white-smoke text-center
        py-5
        px-4
        border-8
      "
    >
      <p v-html="$t('confirm')" />
    </div>

    <div class="mt-4" v-if="requiredItemShow.startWorkingDateRequirement">
      <input-component
        :value="
          residenceCard.startWorkingDate
            ? $i18n.locale == 'ja'
              ? utilFunc.formatDate(
                  new Date(residenceCard.startWorkingDate),
                  'yyyy年mm月dd日'
                )
              : residenceCard.startWorkingDate
            : ''
        "
        :placeholder="$t('start working date')"
        :readonly="true"
      />
    </div>

    <div class="mt-5" v-if="requiredItemShow.staffidRequirement">
      <input-component
        :value="residenceCard.staffID"
        :placeholder="$t('employee ID')"
        :readonly="true"
      />
    </div>

    <div class="mt-5" v-if="requiredItemShow.nameStaffRegisterRequirement">
      <input-component
        :value="residenceCard.nameStaffRegister"
        :placeholder="$t('katakana name')"
        :readonly="true"
      />
    </div>

    <div class="mt-5" v-if="requiredItemShow.emailStaffRequirement">
      <input-component
        :value="residenceCard.emailStaff"
        :placeholder="$t('email address')"
        :readonly="true"
      />
    </div>

    <div class="mt-5" v-if="requiredItemShow.phoneNumberRequirement">
      <input-component
        :value="residenceCard.phoneNumber"
        :placeholder="$t('phone number')"
        :readonly="true"
      />
    </div>

    <div
      class="mt-5"
      v-for="(item, index) in residenceCard.customizedItems.filter(
        (o) => o.typeItem == 'string'
      )"
      :key="index"
    >
      <div
        v-if="
          requiredItemShow.customizedItemsRequirement.includes(
            item.idCompanySetting
          )
        "
      >
        <input-component
          :placeholder="item.nameItem"
          :value="item.valueItem"
          :readonly="true"
        />
      </div>
    </div>

    <div
      class="mt-5"
      v-if="
        residenceCard.visaTypeRegister === 'Student' &&
        requiredItemShow.graduationDateRequirement
      "
    >
      <input-component
        :value="
          residenceCard.graduationDate
            ? $i18n.locale == 'ja'
              ? utilFunc.formatDate(
                  new Date(residenceCard.graduationDate),
                  'yyyy年mm月dd日'
                )
              : residenceCard.graduationDate
            : ''
        "
        :placeholder="$t('grad date')"
        :readonly="true"
      />
    </div>

    <div
      class="mt-5"
      v-if="
        residenceCard.visaTypeRegister === 'Student' &&
        requiredItemShow.studentCardExpiredDateRequirement
      "
    >
      <input-component
        :value="
          residenceCard.studentCardExpiredDate
            ? $i18n.locale == 'ja'
              ? utilFunc.formatDate(
                  new Date(residenceCard.studentCardExpiredDate),
                  'yyyy年mm月dd日'
                )
              : residenceCard.studentCardExpiredDate
            : ''
        "
        :placeholder="$t('expiration date of student ID')"
        :readonly="true"
      />
    </div>

    <div class="mt-5" v-for="(value, i) in imageUrlLocal" :key="i">
      <p v-html="$t(getImageName(i))" class="pb-4 text-white-smoke" />
      <image-preview-component :url="value" :height="213" :isMobile="true" />
    </div>

    <div class="mt-6 d-flex justify-center">
      <button
        class="btn button-danger w-12 fs-16 mr-3 border-8"
        @click="$emit('changeComponent', 'Signature')"
      >
        <p v-html="$t('back')" />
      </button>
      <button
        class="btn btn-danger w-12 fs-16 border-8"
        @click="submitStaffResidenceCard"
      >
        <p v-html="$t('submit')" />
      </button>
    </div>

    <modal-component
      :modalData="modalData"
      ref="resultModal"
      @close="
        isSubmitSuccess &&
          (delete_query_param(),
          $emit('changeComponent', 'BasicInfo'),
          $emit('resetDataResidenceCard'))
      "
    />

    <modal-component
      :modalData="modalData"
      ref="resultModalDeepLink"
      :iconClose="false"
      :isClickOutside="false"
      :isDeepLink="true"
    />

    <ModalLoadingComponent ref="loadingModal" />
  </div>
</template>

<script>
import StaffService from "@/services/staff.service";
import InputComponent from "@/components/InputComponent.vue";
import ImagePreviewComponent from "@/components/ImagePreviewComponent.vue";
import ModalComponent from "@/components/ModalComponent.vue";
import utilFunc from "@/common/utilFunc.js";
import ModalLoadingComponent from "@/components/ModalLoadingComponent.vue";

export default {
  name: "ConfirmInfo",

  components: {
    InputComponent,
    ImagePreviewComponent,
    ModalComponent,
    ModalLoadingComponent,
  },

  props: ["residenceCard", "imageUrlLocal", "requiredItemShow"],

  data() {
    return {
      utilFunc: utilFunc,
      isSubmitSuccess: false,
      modalData: null,
      cardId: this.$route.query.cardId,
    };
  },

  methods: {
    delete_query_param() {
      const query = Object.assign({}, this.$route.query);
      delete query.cardId;
      this.$router.replace({ query });
    },

    submitStaffResidenceCard() {
      this.$refs.loadingModal.open();
      let payload = {
        idShop: this.residenceCard.idShop,
        staffID: this.residenceCard.staffID,
        startWorkingDate: this.residenceCard.startWorkingDate,
        nameStaffRegister: this.residenceCard.nameStaffRegister,
        emailStaff: this.residenceCard.emailStaff,
        phoneNumber: this.residenceCard.phoneNumber,
        visaTypeRegister: this.residenceCard.visaTypeRegister,
        graduationDate:
          new Date(this.residenceCard.graduationDate) == "Invalid Date"
            ? null
            : this.residenceCard.graduationDate,
        studentCardExpiredDate:
          new Date(this.residenceCard.studentCardExpiredDate) == "Invalid Date"
            ? null
            : this.residenceCard.studentCardExpiredDate,
        residenceCardFrontURL: this.residenceCard.residenceCardFrontURL,
        residenceCardBackURL: this.residenceCard.residenceCardBackURL,
        residenceCardNumber: this.residenceCard.residenceCardNumber,
        residenceCardNumberHash: this.residenceCard.residenceCardNumberHash,
        workDesignation: this.residenceCard.designatedURL,
        passportURL: this.residenceCard.passportURL,
        studentCardURL: this.residenceCard.studentCardURL,
        signatureURL: this.residenceCard.signatureURL,
        customizedItems: this.residenceCard.customizedItems,
      };

      StaffService.postStaffResidenceCard(payload)
        .then((res) => {
          this.isSubmitSuccess = true;
          this.modalData = {
            result: res.result,
            message: {
              header: this.$t(res.message.header),
              title: this.$t(res.message.title),
              content: this.$t(res.message.content),
            },
          };
        })
        .catch((err) => {
          this.isSubmitSuccess = false;
          this.modalData = {
            result: err.result,
            message: {
              header: this.$t(err.message.header),
              title: this.$t(err.message.title),
              content: this.$t(err.message.content),
            },
          };
        })
        .finally(() => {
          this.$refs.loadingModal.close();
          if (this.cardId && this.isSubmitSuccess) {
            this.$refs.resultModalDeepLink.open();
          } else {
            this.$refs.resultModal.open();
          }
        });
    },

    getImageName(url) {
      switch (url) {
        case "residenceCardFrontURL":
          return "residence card frontside";
        case "residenceCardBackURL":
          return "residence card backside";
        case "designatedURL":
          return "designation";
        case "passportURL":
          return "passport";
        case "studentCardURL":
          return "student ID";
        case "signatureURL":
          return "signature";
        default:
          return url.substr(0, url.length - 3);
      }
    },
  },
};
</script>
