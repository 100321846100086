const state = () => ({
  pageBack: "officeList",
});

const mutations = {};

export default {
  namespaced: true,
  state,
  mutations,
};
