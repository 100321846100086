<template>
  <div class="register-company mx-auto">
    <div
      class="tab d-flex mx-auto py-10 fs-14"
      v-if="currentComponent != 'RegisterSuccess'"
    >
      <div
        :class="
          currentComponent == 'CorporateInformation' ? 'active' : 'unactive'
        "
      >
        ①企業情報
      </div>
      <div :class="currentComponent == 'Confirmation' ? 'active' : 'unactive'">
        ②確認
      </div>
    </div>
    <h1 v-if="currentComponent != 'RegisterSuccess'" class="title text-center">
      ビザマネに登録する
    </h1>
    <keep-alive v-if="currentComponent != 'RegisterSuccess'">
      <component
        v-bind:is="currentComponent"
        :corporateInformation="corporateInformation"
        @changeComponent="currentComponent = $event"
        @change-corporate-information="corporateInformation = $event"
      />
    </keep-alive>

    <register-success v-if="currentComponent == 'RegisterSuccess'" />

    <div class="link mt-auto pt-10">
      <router-link :to="{ name: 'login' }">
        {{
          currentComponent != "RegisterSuccess"
            ? "すでにアカウントをお持ちの方はこちら"
            : "ログインページに戻る"
        }}
      </router-link>
    </div>
  </div>
</template>

<script>
import CorporateInformation from "./corporate-information.vue";
import Confirmation from "./confirmation.vue";
import RegisterSuccess from "./register-success.vue";

export default {
  components: {
    CorporateInformation,
    Confirmation,
    RegisterSuccess,
  },

  data() {
    return {
      currentComponent: "CorporateInformation",
      corporateInformation: null,
    };
  },
};
</script>
