import ApiService from "@/common/api.service";
import { APIS } from "@/common/api.constants";
import SessionStorage from "@/common/sessionStorage";

// ログイン、ログアウト、ユーザー周りの管理
export default {
  // ログイン
  login(id_admin, password) {
    return ApiService.post(APIS.LOGIN, {
      email: id_admin,
      password: password,
    })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        // throw error and go to catch block
        throw error;
      });
  },
  logout() {
    return ApiService.post(APIS.LOGOUT)
      .then((response) => {
        SessionStorage.destroyToken();
        return response.data;
      })
      .catch((error) => {
        SessionStorage.destroyToken();
        // throw error and go to catch block
        throw error.response.data;
      });
  },
  // 接続したadmin情報
  connectedAdmin() {
    return ApiService.get(APIS.CONNECTED_ADMIN).then((response) => {
      return response.data;
    });
  },

  // reset passeword
  resetPassword(password, token) {
    let formData = new FormData();
    formData.append("password", password);
    formData.append("token", token);
    return ApiService.post(APIS.ADMIN_RESET_PASSWORD, formData)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  },
};
