<template>
  <div v-if="showLoading" class="loading"></div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    delay: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      showLoading: false,
    };
  },

  watch: {
    loading: {
      handler() {
        if (this.loading) {
          setTimeout(() => (this.showLoading = this.loading), this.delay);
        } else {
          this.showLoading = this.loading;
        }
      },
      immediate: true,
    },
  },
};
</script>
