import { createStore } from "vuex";
import router from "./../router";
import AuthService from "@/services/auth.service";
import CompanyService from "@/services/company.service";
import SessionStorage from "@/common/sessionStorage";
import NoticeService from "@/services/notice.service";

import {
  HTTP_RESPONSE_STATUS_CODES,
  MESSAGE,
  MESSAGE_ENG_SUB,
} from "@/common/constants";

import listFilterPullDown from "./listFilterPulldown";
import shop from "./shop";
import admin from "./admin";
import staff from "./staff";
import hellowork from "./hellowork";
import office from "./office";
import payment from "./payment";
import contact from "./contact";

export default createStore({
  state: {
    adminInfo: null,
    companyInfo: null,
    idCompany: null,
    isLogin: false,
    isGoLogin: false,
    authority: null,
    isloginError: false, //TODO: ログインエラー時のポップアップを表示する機能追加
    isSessionError: false, //TODO: セッションエラー時のポップアップを表示する機能追加
    errorStatus: null,
    messageErrorLogin: null,
    noticeNewCount: 0,
    selectedStaff: null,
    isDisagreeTerm: false,
    lang: null,
  },

  mutations: {
    // ログイン成功
    loginSuccess(state) {
      state.isLogin = true;
      state.isloginError = false;
      state.messageErrorLogin = null;
    },

    // ログイン失敗
    loginError(state, error) {
      state.isLogin = false;
      state.isloginError = true;

      const translateError = {
        [MESSAGE_ENG_SUB.MESSAGE_IP_ADDRESS_IS_NOT_ALLOWED]:
          MESSAGE.MESSAGE_IP_ADDRESS_IS_NOT_ALLOWED,
        [MESSAGE_ENG_SUB.MESSAGE_ACCOUNT_LOCKED]:
          MESSAGE.MESSAGE_ACCOUNT_LOCKED,
      };

      state.messageErrorLogin = translateError[error.message]
        ? translateError[error.message]
        : MESSAGE.MESSAGE_INVALID_ACCOUNT;
    },

    // ログインアウト
    logout(state) {
      state.isLogin = false;
      state.isloginError = false;
      state.authority = null;
      SessionStorage.destroyData("idCompany");
      SessionStorage.destroyData("adminInfo");
      SessionStorage.destroyData("companyInfo");
      SessionStorage.destroyData("endTrialFiveDayNotify");
      SessionStorage.destroyData("firstLogin");
      SessionStorage.destroyData("firstLoginEndTrial");
      SessionStorage.destroyData("firstLoginUnlimited");
      SessionStorage.destroyData("selectedStaff");
      SessionStorage.destroyData("selectedHelloWork");
      SessionStorage.destroyData("dashBoardStaffSelectShop");
      SessionStorage.destroyData("recentlyFilterPulldownList");
      state.isDisagreeTerm = false;
    },

    // Set state company info
    updateIsAccessableApp(state, data) {
      let companyInfoParse = JSON.parse(state.companyInfo);
      let setCompanyInfo = {
        ...companyInfoParse,
        data: {
          ...companyInfoParse.data,
          isAccessableReadHistory:
            data.isAccessableReadHistory !== null
              ? data.isAccessableReadHistory
              : companyInfoParse.data.isAccessableReadHistory,
          isAccessableRegistList:
            data.isAccessableRegistList !== null
              ? data.isAccessableRegistList
              : companyInfoParse.data.isAccessableRegistList,
          isApplicationGuestUse:
            data.isApplicationGuestUse !== null
              ? data.isApplicationGuestUse
              : companyInfoParse.data.isApplicationGuestUse,
        },
      };
      let companyInfoResult = JSON.stringify(setCompanyInfo);
      state.companyInfo = companyInfoResult;
      SessionStorage.saveData("companyInfo", companyInfoResult);
    },

    saveAdminInfo(state, adminInfoJson) {
      state.adminInfo = adminInfoJson;
      SessionStorage.saveData("adminInfo", adminInfoJson);
    },

    saveSelectedStaff(state, selectedStaff) {
      state.selectedStaff = selectedStaff;
    },
  },

  actions: {
    // ログイン処理
    login({ state, dispatch, commit }, logingObj) {
      AuthService.login(logingObj.email, logingObj.password)
        .then((res) => {
          SessionStorage.saveToken(res["token"]);
          SessionStorage.saveData("idCompany", res["idCompany"]);
          state.idCompany = res["idCompany"];
          state.isSessionError = false;
          state.isGoLogin = false;

          dispatch("connectedAdmin").then(() => {
            commit("loginSuccess");
          });
        })
        .catch((err) => {
          SessionStorage.destroyToken();
          commit("loginError", err);
          dispatch("resetData");
        });
    },

    connectedAdmin({ state, commit, dispatch }) {
      let idCompany = SessionStorage.getData("idCompany");
      let adminInfo = SessionStorage.getData("adminInfo");
      let companyInfo = SessionStorage.getData("companyInfo");

      if (state.isLogin || (adminInfo !== "undefined" && adminInfo)) {
        commit("loginSuccess");
        state.idCompany = idCompany;
        state.adminInfo = adminInfo;
        state.authority = adminInfo ? JSON.parse(adminInfo).authority : null;
        state.companyInfo = companyInfo;
        //TODO: dashboardで店舗データを取得するため不要だが、後で処理削除を検討。
        dispatch("getShops");
        return;
      }

      AuthService.connectedAdmin()
        .then((res) => {
          state.authority = res.authority;
          let authJsonRes = JSON.stringify(res);
          state.adminInfo = authJsonRes;
          SessionStorage.saveData("adminInfo", authJsonRes);

          CompanyService.getCompanyInfo().then((resCompany) => {
            let companyJsonRes = JSON.stringify(resCompany);
            state.companyInfo = companyJsonRes;
            SessionStorage.saveData("companyInfo", companyJsonRes);

            if (resCompany) {
              router.push({ name: "dashboard" });
            }
          });

          commit("loginSuccess");
          dispatch("getShops");
        })
        .catch((err) => {
          console.log("loginError : " + err);
          commit("logout");
        });
    },

    logout({ commit }) {
      commit("logout");
      AuthService.logout()
        .then(() => {
          router.push({ name: "login" });
        })
        .catch((err) => {
          console.log("logout : " + err);
          router.push({ name: "login" });
        });
    },

    tokenExpired({ state, commit }, errStatus) {
      console.log("tokenExpired : " + errStatus);
      if (errStatus === HTTP_RESPONSE_STATUS_CODES.STATUS_401_UNAUTHORIZED) {
        commit("logout");
        state.isSessionError = true;
        SessionStorage.destroyToken();
      }
    },

    resetData({ state }) {
      state.adminInfo = null;
      state.idCompany = null;

      SessionStorage.destroyData("idCompany");
      SessionStorage.destroyData("adminInfo");
    },

    // Update accessable app (company info)
    handleUpdateIsAccessableApp({ commit }, data) {
      commit("updateIsAccessableApp", data);
    },

    getNoticeNewCount({ state }) {
      NoticeService.getNoticeNewCount().then((res) => {
        state.noticeNewCount = res.data;
      });
    },
  },

  modules: { listFilterPullDown, shop, admin, staff, hellowork, office, payment, contact},
});
