import axios from "axios";
import ApiService from "@/common/api.service";
import { APIS } from "@/common/api.constants";

export default {
  postContact(formData) {
    return ApiService.post(`${APIS.CONTACT_US}`, formData)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  // post upload picture
  postUploadPicture(payload) {
    return ApiService.post(APIS.PICTURE_UPLOAD, payload)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  // post upload picture any
  postPictureUploadAny(payload) {
    return ApiService.post(APIS.PICTURE_UPLOAD_ANY, payload)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  zipCloudApiSearch(params) {
    return axios
      .get(APIS.ZIPCLOUD_API_SEARCH, { params: params })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  //get terms
  getTerms() {
    return ApiService.get(APIS.TERMS)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  //get privacyPolicy
  getPrivacyPolicy() {
    return ApiService.get(APIS.PRIVACYPHOLICY)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  //post term agreement
  postPlanSelect() {
    return ApiService.post(APIS.PLAN_SELECT)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },
};
