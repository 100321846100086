<template>
  <div class="upload-photo mt-3">
    <div class="text-danger fs-12" v-if="!getImageUrl(step)">
      <a class="btn-back" @click="backStep(step)">
        <v-icon class="mb-1 mr-n2 mt-1">mdi-menu-left</v-icon>
        <span class="ml-n2" v-html="$t('previous screen')" />
      </a>
    </div>

    <div
      class="text-white-smoke text-center"
      v-if="!initCamera && !getImageUrl(step)"
    >
      <div class="portrait-mobile image mx-auto">
        <img
          v-if="step == 'residenceCardFront'"
          class="w-100"
          :src="require(`@/assets/img/residenceCardFront.png`)"
        />
        <img
          v-else-if="step == 'residenceCardBack'"
          class="w-100"
          :src="require(`@/assets/img/residenceCardBack.png`)"
        />
        <img
          v-else-if="step == 'workDesignation'"
          class="w-100"
          :src="require(`@/assets/img/photo_example_designation.svg`)"
        />
      </div>

      <p class="portrait-mobile box mx-auto pa-4">
        <span
          v-if="step == 'residenceCardFront'"
          v-html="$t('take the photo of your residence card frontside')"
        />
        <span
          v-else-if="step == 'residenceCardBack'"
          v-html="$t('take the photo of your residence card backside')"
        />
        <span
          v-else-if="step == 'workDesignation'"
          v-html="$t('take the photo of the designation')"
        />
      </p>

      <div
        v-if="step == 'residenceCardFront' || step == 'residenceCardBack'"
        class="portrait-mobile d-flex justify-center mx-auto px-2 py-7"
      >
        <label for="file">
          <p
            class="button-danger"
            :class="{ 'py-1': $i18n.locale !== 'ja' }"
            v-html="$t('select a file')"
          ></p>
          <input
            ref="file"
            class="d-none"
            id="file"
            type="file"
            @change="uploadImage('file')"
          />
        </label>

        <div @click="initCamera = true">
          <p
            class="ml-3"
            :class="{
              'py-1': $i18n.locale !== 'ja',
              'button-bg-danger':
                $i18n.locale !== 'pr' && $i18n.locale !== 'my',
              'button-bg-danger-flex':
                $i18n.locale === 'pr' || $i18n.locale === 'my',
            }"
            v-html="$t('take photo')"
          ></p>
        </div>
      </div>
      <div v-else class="d-flex justify-center px-2 py-7">
        <label for="file">
          <p
            v-html="$t('select a file') + '/' + $t('take photo')"
            class="button-bg-danger select-take-photo"
            :class="{ 'py-1': $i18n.locale !== 'ja' }"
          ></p>
          <input
            ref="file"
            class="d-none"
            id="file"
            type="file"
            @change="uploadImage('file')"
          />
        </label>
      </div>
    </div>

    <div :class="isMobile ? 'upload-photo-section' : 'mt-7'">
      <take-photo-component
        v-if="!getImageUrl(step)"
        :init-camera="initCamera"
        @stop-camera="initCamera = false"
        @image-upload="getImageUpload($event, step)"
        @file-upload="getFileUpload($event, step)"
      >
        <div
          class="portrait-mobile box text-white-smoke text-center mx-auto"
          :class="isMobile ? 'guide-take-photo' : 'mt-5'"
        >
          <p class="pa-4" v-html="$t('within the frame')"></p>
        </div>
      </take-photo-component>
    </div>

    <div
      v-if="getImageUrl(step)"
      :class="{ 'mt-7': getImageUrl(step) }"
      class="text-center"
    >
      <image-preview-component
        :key="getImageUrl(step)"
        :url="getImageUrl(step)"
        :is-mobile="true"
        :max-width="343"
        :height="213"
      />
      <div class="portrait-mobile box text-white-smoke mx-auto mt-5">
        <p class="pa-4" v-html="$t('clear and readable')"></p>
      </div>
      <div class="portrait-mobile d-flex justify-center mx-auto px-2 py-7">
        <p
          class="w-12 button-danger"
          :class="{ 'py-1': $i18n.locale !== 'ja' }"
          v-html="$t('retake')"
          @click="
            getImageUpload(null, step);
            getFileUpload(null, step);
          "
        ></p>
        <p
          class="w-12 ml-3"
          :class="{
            'py-1': $i18n.locale !== 'ja',
            'button-bg-danger': $i18n.locale !== 'ep' && $i18n.locale !== 'my',
            'button-bg-danger-flex':
              $i18n.locale === 'ep' || $i18n.locale === 'my',
          }"
          @click="nextStep(step)"
          v-html="$t('next')"
        ></p>
      </div>
    </div>

    <modal-component ref="modalLoading">
      <template #header> <span v-html="$t('upload image')" /> </template>
      <template #body>
        <div class="modal-body-data fs-14 mx-4 text-white-smoke">
          <div class="modal-body__img">
            <loading-component :loading="true" />
          </div>
          <h1 class="modal-body__title" v-html="$t('image uploading')" />
          <p v-html="$t('wait a moment')" />
        </div>
      </template>
    </modal-component>

    <modal-component :modalData="modalDefault" ref="modalDefault" />
  </div>
</template>

<script>
import { MOBILE_LIST } from "@/common/constants.js";
import EtcService from "@/services/etc.service";

import TakePhotoComponent from "@/components/TakePhotoComponent.vue";
import ImagePreviewComponent from "@/components/ImagePreviewComponent.vue";
import LoadingComponent from "@/components/LoadingComponent.vue";
import ModalComponent from "@/components/ModalComponent.vue";

export default {
  name: "UploadPhoto",

  props: ["residenceCard", "idAdmin"],

  components: {
    TakePhotoComponent,
    ImagePreviewComponent,
    LoadingComponent,
    ModalComponent,
  },

  data() {
    return {
      initCamera: false,
      step: "residenceCardFront",
      stepList: ["residenceCardFront", "residenceCardBack", "workDesignation"],
      residenceCardFront: true,
      residenceCardBack: true,

      residenceCardFrontURL: null,
      residenceCardBackURL: null,
      workDesignationURL: null,

      residenceCardFrontFile: null,
      residenceCardBackFile: null,
      workDesignationFile: null,

      modalDefault: null,
    };
  },

  computed: {
    workDesignation() {
      return (
        this.residenceCard.visaTypeRegister &&
        (this.residenceCard.visaTypeRegister.includes(
          "Designated Activities"
        ) ||
          this.residenceCard.visaTypeRegister.includes(
            "Specified Skilled Worker"
          ))
      );
    },

    isMobile() {
      return MOBILE_LIST.test(navigator.userAgent);
    },
  },

  methods: {
    getImageUpload(imageUrl, currentStep) {
      switch (currentStep) {
        case "residenceCardFront":
          this.residenceCardFrontURL = imageUrl;
          break;
        case "residenceCardBack":
          this.residenceCardBackURL = imageUrl;
          break;
        case "workDesignation":
          this.workDesignationURL = imageUrl;
          break;
      }
    },

    getFileUpload(file, currentStep) {
      switch (currentStep) {
        case "residenceCardFront":
          this.residenceCardFrontFile = file;
          break;
        case "residenceCardBack":
          this.residenceCardBackFile = file;
          break;
        case "workDesignation":
          this.workDesignationFile = file;
          break;
      }
    },

    getImageUrl(currentStep) {
      switch (currentStep) {
        case "residenceCardFront":
          return this.residenceCardFrontURL;
        case "residenceCardBack":
          return this.residenceCardBackURL;
        case "workDesignation":
          return this.workDesignationURL;
      }
    },

    getImageFile(currentStep) {
      switch (currentStep) {
        case "residenceCardFront":
          return this.residenceCardFrontFile;
        case "residenceCardBack":
          return this.residenceCardBackFile;
        case "workDesignation":
          return this.workDesignationFile;
      }
    },

    backStep(currentStep) {
      if (this.initCamera) {
        this.initCamera = false;
        return;
      }

      if (this.step == "residenceCardFront") {
        this.$emit("changeComponent", "VisaStatus");
        return;
      }

      let index = this.stepList.indexOf(currentStep) - 1;
      for (let i = index; i >= 0; i--) {
        if (this[this.stepList[i]]) {
          this.step = this.stepList[i];
          return;
        }
      }
    },

    nextStep(currentStep) {
      this.$refs.modalLoading.open();

      let formData = new FormData();
      formData.append("file", this.getImageFile(currentStep));
      formData.append("type", `${currentStep}URL`);
      formData.append("idStaff", this.residenceCard.idStaff);
      formData.append("idCompany", this.residenceCard.idCompany);
      formData.append("path", this.$route.path);
      if (this.idAdmin) {
        formData.append("idAdmin", this.idAdmin);
      }

      EtcService.postPictureUploadAny(formData)
        .then((res) => {
          this.$refs.modalLoading.close();
          this.$emit("changeResidenceCard", {
            [currentStep + "URL"]: res.data,
          });

          this.$emit("saveImageLocal", {
            [currentStep + "URL"]: this[currentStep + "URL"],
          });

          this.nextScreen(currentStep);
        })
        .catch((err) => {
          this.$refs.modalLoading.close();
          this.modalDefault = err;
          this.$refs.modalDefault.open();
        });
    },

    uploadImage(refs) {
      const file = this.$refs[refs].files[0];
      this.getFileUpload(file, this.step);

      const reader = new FileReader();
      reader.addEventListener("load", () => {
        this.getImageUpload(reader.result.toString(), this.step);
      });
      if (file) {
        reader.readAsDataURL(file);
      }
    },

    nextScreen(currentStep) {
      let index = this.stepList.indexOf(currentStep);
      for (let i = index + 1; i < this.stepList.length; i++) {
        if (this[this.stepList[i]]) {
          this.step = this.stepList[i];
          return;
        }
        if (
          !this[this.stepList[this.stepList.length - 1]] &&
          i == this.stepList.length - 1
        ) {
          this.$emit("changeComponent", "ChangeInfo");
          return;
        }
      }
      this.$emit("changeComponent", "ChangeInfo");
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/abstracts/mixin.scss";
@import "@/assets/scss/abstracts/variable.scss";

.upload-photo {
  .upload-photo-section {
    @include respond(portrait-mobile) {
      margin-top: 2.7rem;
    }
    margin-top: -1.6rem;

    .guide-take-photo {
      @include respond(portrait-mobile) {
        margin: 2rem;
      }
    }
  }

  .button-danger,
  .button-bg-danger {
    padding: 0.9rem 0.4rem 0.1rem 0.4rem;
    font-size: 1.6rem;
    width: 15rem;
    cursor: pointer;
  }

  .select-take-photo {
    width: 25rem !important;
  }

  .box {
    background: $color-grey-light-v3;
    border-radius: 0.8rem;
  }
}
</style>
