const state = () => ({
  payment: {
    idPayment: null,
    reference: null,
    namePlan: null,
  },
});

const mutations = {
  setStatePayment(state, data) {
    state.payment = { ...state.payment, ...data };
  },
};

export default {
  namespaced:true,
  state,
  mutations,
};

